import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Stack, Typography } from '@mui/material'
import { type FC, useMemo } from 'react'

import { Schemas } from '~/apis/types'
import { mediaUrl } from '~/utils/common'

export type CSponsorDisplayProps = {
    isOpen: boolean
    onClose: () => void
    entity?: Schemas.SponsorEntities
}

const useDialog = (entity?: Schemas.SponsorEntities) => {
    const sponsorsDisplayList = [
        { title: '事業者名', content: entity?.name },
        { title: '事業者名（ふりがな）', content: entity?.nameKana },
        { title: 'メールアドレス', content: entity?.email },
        { title: '代表者住所', content: entity?.address },
        { title: '代表者電話番号', content: entity?.phoneNumber },
        { title: 'カテゴリ', content: entity?.categories.map((t) => t.title).join(', ') },
        { title: '都道府県', content: entity?.coveredPrefectures.map((t) => t.title).join(', ') },
    ]
    const sponsorListContents = useMemo(() => {
        return sponsorsDisplayList?.map((sponsor, idx) => {
            return (
                <Stack padding={'10px'} key={idx}>
                    {sponsor.content && (
                        <>
                            <Typography fontSize={'12px'} fontWeight={'500'} lineHeight={'110%'}>
                                {sponsor.title}
                            </Typography>
                            <Typography py={'5px'} pl={'10px'} fontSize={'16px'}>
                                {sponsor.content}
                            </Typography>
                            <Divider />
                        </>
                    )}
                </Stack>
            )
        })
    }, [sponsorsDisplayList])

    return { sponsorsDisplayList, sponsorListContents }
}

export const CSponsorDisplay: FC<CSponsorDisplayProps> = ({ isOpen, onClose, entity }) => {
    const { sponsorListContents } = useDialog(entity)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>事業者情報</DialogTitle>
                <DialogContent>
                    {entity?.bannerImage && (
                        <Stack>
                            <Typography fontSize={'12px'} fontWeight={'500'} lineHeight={'110%'}>
                                バナー画像
                            </Typography>
                            <Link href={mediaUrl(entity?.bannerImage)} target="_blank" py={'5px'} pl={'10px'} fontSize={'16px'}>
                                {entity?.bannerImage && (
                                    <img
                                        src={mediaUrl(entity?.bannerImage)}
                                        style={{ width: '100%', maxWidth: '20%', maxHeight: '30%' }}
                                        alt={entity?.bannerImage.filename}
                                    />
                                )}
                            </Link>
                        </Stack>
                    )}
                    {sponsorListContents}
                    {entity?.siteUrl && (
                        <>
                            <Stack padding={'10px'}>
                                <Typography fontSize={'12px'} fontWeight={'500'} lineHeight={'110%'}>
                                    サイトURL
                                </Typography>
                                <Link py={'5px'} pl={'10px'} fontSize={'16px'} target="_blank" href={entity?.siteUrl ?? ''}>
                                    {entity?.siteUrl}
                                </Link>
                            </Stack>
                            <Divider />
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button onClick={onClose} sx={{ fontSize: '1rem' }}>
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
