import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminMailTemplateUpdateDtoSchema, adminMailTemplateUpdateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity?: Schemas.NotificationTemplateEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminMailTemplateUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminMailTemplateUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            title: entity?.title || '',
            mailSubject: entity?.mailSubject || '',
            mailBody: entity?.mailBody || '',
            smsBody: entity?.smsBody || '',
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

type CMailTemplateUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminNotificationTemplateUpdateDto, uuid?: string) => void
    entity?: Schemas.NotificationTemplateEntities
}

export const CMailTemplateUpdateDialog: FC<CMailTemplateUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>メールテンプレートを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>{entity?.name}</Typography>

                        <TextField
                            id={'title'}
                            required={true}
                            {...register('title')}
                            label={'タイトル'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                        />
                        <TextField
                            id={'mailSubject'}
                            required={true}
                            {...register('mailSubject')}
                            label={'メール件名'}
                            error={!!errors.mailSubject}
                            helperText={errors.mailSubject?.message}
                        />
                        <TextField
                            id={'mailBody'}
                            required={true}
                            {...register('mailBody')}
                            label={'メール本文'}
                            error={!!errors.mailBody}
                            helperText={errors.mailBody?.message}
                            multiline={true}
                            rows={6}
                        />
                        <TextField
                            id={'smsBody'}
                            required={true}
                            {...register('smsBody')}
                            label={'SMS本文'}
                            error={!!errors.smsBody}
                            helperText={errors.smsBody?.message}
                            multiline={true}
                            rows={6}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
