import { atom } from 'jotai'

export const atomWithLocalStorage = <T>(key: string, initialValue: T) => {
    const getInitialValue = (): T => {
        try {
            const item = localStorage.getItem(key)
            if (typeof item !== 'undefined' && item !== null) return JSON.parse(item)
            else return initialValue
        } catch (e) {
            return initialValue
        }
    }

    const baseAtom = atom<T>(getInitialValue())
    return atom<T, T, void>(
        (get) => get(baseAtom),
        (get, set, update) => {
            const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update
            set(baseAtom, nextValue)
            localStorage.setItem(key, JSON.stringify(nextValue))
        },
    )
}
