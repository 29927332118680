import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { datetimeFormat, DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    connectName?: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    connectName?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)
    const [userUuid, setUserUuid] = useState<string | undefined>(query.get('userUuid') || undefined)
    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await setUserUuid(userUuid)
        await handlePageChange(1, { userUuid })
    }
    const [connectName, setConnectName] = useState<string | undefined>(query.get('connectName') || undefined)

    const params: Parameters = { page: page, limit: limit, userUuid: userUuid, connectName: connectName }

    // initial fetch
    const { data: listResponse, refetch: refetchUserList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminConnectIndex({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (
        page: number,
        options?: { userUuid?: string | undefined; connectName?: string | undefined },
    ) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid
        if (options && 'connectName' in options) {
            if (options.connectName) navigateSearchParams.connectName = options.connectName
        } else if (params.connectName) navigateSearchParams.connectName = params.connectName

        navigate({
            pathname: '/connect',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
        await refetchUserList()
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchUserList,
        userUuid,
        handleUserUuidChange,
        connectName,
        setConnectName,
    }
}

export const ConnectIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchUserList,
        userUuid,
        handleUserUuidChange,
        connectName,
        setConnectName,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `つながりリスト一覧`, link: `/connect` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchUserList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <CUserAutocomplete userUuid={userUuid} onChangeAutocomplete={handleUserUuidChange} />
                        <TextField
                            id={'connectName'}
                            label={'名前'}
                            value={connectName}
                            onChange={(e) => setConnectName(e.target.value)}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録者</TableCell>
                                <TableCell>名前</TableCell>
                                <TableCell>住所(都道府県市区郡)</TableCell>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>更新日</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((connect: Schemas.ConnectEntities) => (
                                <TableRow key={connect.uuid}>
                                    <TableCell>
                                        {connect.user && (
                                            <Link href={`/user/${connect.user.uuid}`} color={'primary'}>
                                                {connect.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/connect/${connect.uuid}`} color={'primary'}>
                                            {connect.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {connect.pref} {connect.city}
                                    </TableCell>
                                    <TableCell>{connect.email}</TableCell>
                                    <TableCell>{datetimeFormat(connect.updatedAt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>
        </>
    )
}
