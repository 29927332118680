import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Container, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { AdminReminderResetDtoSchema, adminReminderResetDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const [submitted, setSubmitted] = useState(false)
    const token = params.token

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminReminderResetDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminReminderResetDtoSchema),
    })

    useEffect(() => {
        reset({
            password: '',
            token: token,
        })
    }, [])

    const handleReminder = async (dto: Schemas.AdminReminderResetDto) => {
        try {
            await apiClient.adminAuthStoreReminderResetPassword({ requestBody: dto })
            await setSubmitted(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        register,
        errors,
        handleSubmit,
        handleReminder,
        isSubmitting,
        submitted,
        isValid,
    }
}

export const ReminderDetailPage: FC = () => {
    const { register, errors, handleSubmit, handleReminder, isSubmitting, isValid, submitted } = usePage()

    return (
        <>
            <Container maxWidth={'sm'} sx={{ my: 8 }}>
                <Typography variant="h6">パスワード再設定</Typography>
                <Stack spacing={3}>
                    {submitted ? (
                        <>
                            <Typography>
                                パスワードを設定しました。
                                <br />
                                ログイン画面に戻りログインしてください。
                            </Typography>

                            <Link href="/login" sx={{ mt: 4, textAlign: 'center' }}>
                                ログイン
                            </Link>
                        </>
                    ) : (
                        <>
                            <TextField
                                {...register('password')}
                                label={'パスワード'}
                                type={'password'}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                            />

                            <LoadingButton
                                onClick={handleSubmit(handleReminder)}
                                variant={'contained'}
                                disabled={!isValid}
                                loading={isSubmitting}>
                                送信
                            </LoadingButton>
                        </>
                    )}
                </Stack>
            </Container>
        </>
    )
}
