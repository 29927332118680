import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { AdminAdminCreateDtoSchema, adminAdminCreateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
    } = useForm<AdminAdminCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminAdminCreateDtoSchema),
    })

    const entity: Schemas.AdminEntities | undefined = undefined

    // 初期化
    useEffect(() => {
        reset({
            name: '',
            email: '',
            password: undefined,
            systemAdmin: false,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        entity,
        control,
    }
}

export type CUserCreateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminAdminCreateDto) => void
}

export const CAdminCreateDialog: FC<CUserCreateDialogProps> = ({ isOpen, onClose, onSubmit }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, control } = useDialog(isOpen)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>ユーザを追加</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <TextField
                            id={'email'}
                            required={true}
                            {...register('email')}
                            label={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            type={'email'}
                        />
                        <TextField
                            id={'password'}
                            required={false}
                            {...register('password')}
                            label={'パスワード'}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            type={'password'}
                        />
                        <CSwitch name={'systemAdmin'} control={control} label="システム管理者" />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
