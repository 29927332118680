import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminGiftMasterDescriptionUpdateDtoSchema, adminGiftMasterDescriptionUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

export const useCGiftMasterDescriptionUpdateDialog = (isOpen: boolean, entity?: Schemas.GiftMasterDescription) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminGiftMasterDescriptionUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminGiftMasterDescriptionUpdateDtoSchema),
    })

    const [file, setFile] = useState<Schemas.FileEntities | null>(entity?.file || null)

    const [displayType, setDisplayType] = useState<string>('title')
    const displayTypes = [
        { value: 'title', label: '見出し' },
        { value: 'body', label: '本文' },
        { value: 'image', label: '画像' },
    ]
    const onChangeDisplayType = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDisplayType(val.target.value)
        setValue('type', val.target.value)
        setValue('value', '')
    }

    // 初期化
    useEffect(() => {
        reset({
            type: entity?.type || 'title',
            value: entity?.value || '',
        })
        setFile(entity?.file || null)
        setDisplayType(entity?.type || 'title')
    }, [isOpen, entity])

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('value', value.uuid)
            setFile(value)
        }
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        displayType,
        displayTypes,
        onChangeDisplayType,
        file,
        handleUploadFile,
    }
}

export type CGiftMasterUpdateDescriptionDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminGiftMasterDescriptionUpdateDto, uuid?: string) => void
    entity?: Schemas.GiftMasterDescription
}

export const CAdminGiftMasterDescriptionUpdateDialog: FC<CGiftMasterUpdateDescriptionDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        displayType,
        displayTypes,
        onChangeDisplayType,
        file,
        handleUploadFile,
    } = useCGiftMasterDescriptionUpdateDialog(isOpen, entity)

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>ギフトマスタを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        id={'afterPermit'}
                        required={true}
                        {...register!('type')}
                        placeholder={'没後管理権限'}
                        variant={'outlined'}
                        select={true}
                        onChange={(val) => onChangeDisplayType(val)}
                        sx={{ backgroundColor: 'white' }}
                        defaultValue={entity?.type ?? 'title'}>
                        {displayTypes?.map((nt) => (
                            <MenuItem key={nt.value} value={nt.value}>
                                {nt.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {displayType === 'title' && (
                        <TextField
                            id={'title'}
                            required={true}
                            {...register('value')}
                            label={'タイトル'}
                            error={!!errors.value}
                            helperText={errors.value?.message}
                        />
                    )}
                    {displayType === 'body' && (
                        <TextField
                            id={'body'}
                            required={true}
                            {...register('value')}
                            label={'本文'}
                            error={!!errors.value}
                            helperText={errors.value?.message}
                            multiline={true}
                            rows={6}
                        />
                    )}

                    {displayType === 'image' && (
                        <Box>
                            <CFileUpload
                                completionHandler={handleUploadFile}
                                label={'画像アップロード'}
                                error={!!errors.value}
                                helperText={errors.value?.message}
                            />

                            {file?.path && (
                                <Box sx={{ textAlign: 'center' }}>
                                    <img
                                        src={mediaUrl(file)}
                                        style={{ width: '100%', maxWidth: '200px', maxHeight: '200px' }}
                                        alt={file.filename}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                {displayType === 'image' ? (
                    <>
                        <LoadingButton
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!file?.path}
                            onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                            保存
                        </LoadingButton>
                    </>
                ) : (
                    <>
                        <LoadingButton
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!isValid}
                            onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                            保存
                        </LoadingButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}
