import { QueryFunction, QueryKey } from '@tanstack/query-core'
import { useQuery } from '@tanstack/react-query'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Timezone from 'dayjs/plugin/timezone'
import Utc from 'dayjs/plugin/utc'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { Schemas } from '~/apis/types'

dayjs.extend(Timezone)
dayjs.extend(Utc)
dayjs.extend(isSameOrAfter)

export const sleep = (ms: number): Promise<void> =>
    new Promise<void>((resolve) =>
        setTimeout(() => {
            resolve()
        }, ms),
    )

const checkArgument = (value: unknown, name: string) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!value) throw new Error(`The argument "${name}" cannot be empty`)
}

export const filterAsync = async <T>(
    array: readonly T[],
    callback: (value: T, index: number) => Promise<boolean>,
): Promise<T[]> => {
    checkArgument(array, 'array')
    checkArgument(callback, 'callback')

    const results: boolean[] = await Promise.all(array.map((value, index) => callback(value, index)))
    return array.filter((_, i) => results[i])
}

export const mediaUrl = (media?: Schemas.FileEntities | null): string => {
    if (!media) return ''

    return `${process.env.BASE_URL}/api/client/media/${media.path}`
}

export const calcPageLength = (count: number, limit: number) => {
    return Math.floor(count / limit) + 1
}

export const useQueryString = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useQuerySuspense = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    // TODO ESLint vs Prettier
    // eslint-disable-next-line indent
    queryKey: TQueryKey,
    // eslint-disable-next-line indent
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    // eslint-disable-next-line indent
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
        initialData?: () => undefined
    },
    // eslint-disable-next-line indent
) => {
    const query = useQuery(queryKey, queryFn, { suspense: true, refetchOnWindowFocus: false, retry: false, ...options })
    if (query.data) return { ...query, data: query.data }
    else if (query.error) throw query.error
    else if (options && typeof options.enabled !== 'undefined' && !options.enabled) return { ...query, data: undefined }
    else throw new Error()
}

export const dateFormat = (val?: string | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('YYYY-MM-DD')
    } catch (e) {
        return ''
    }
}

export const dayjsWithTimeZone = (
    date?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    locale?: string,
    strict?: boolean,
    timezone = 'Asia/Tokyo',
): dayjs.Dayjs => {
    return dayjs(date, format, locale, strict).tz(timezone)
}

export const datetimeFormat = (val?: string | null): string => {
    if (!val) return ''
    try {
        return dayjs(val).format('YYYY-MM-DD HH:mm')
    } catch (e) {
        return ''
    }
}

// 別タブで開く
export const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

// リスト取得時の Limit の初期値
export const DefaultMaxListLimit = 10

// 思い出 カテゴリ
export const MemoryCategoryArray = ['memory', 'history', 'todo'] as const
export type MemoryCategoryType = typeof MemoryCategoryArray[number]
export const memoryCategoryList: { label: string; value: MemoryCategoryType }[] = [
    { label: '思い出', value: 'memory' },
    { label: '自分史', value: 'history' },
    { label: 'やりたいこと', value: 'todo' },
]
export const memoryCategoryFormat = (value: string): string => {
    const category = memoryCategoryList.find((l) => l.value === value)
    if (category) return category.label
    return 'Error'
}

// 問い合わせ 状態
export const InquiryStatusArray = [0, 1, 2, 3, 4] as const
export type InquiryStatusAlias = typeof InquiryStatusArray[number]
type InquiryStatusType = {
    value: InquiryStatusAlias
    label: string
}
export const InquiryStatusList: InquiryStatusType[] = [
    { label: '未確認', value: 0 },
    { label: '返信済み', value: 1 },
    { label: '完了', value: 2 },
    { label: '保留', value: 3 },
    { label: 'ユーザからの削除', value: 4 },
]

export const InquiryStatusFormat = (value: number): string => {
    const inquiryStatus = InquiryStatusList.find((l) => l.value === value)
    if (inquiryStatus) return inquiryStatus.label
    return 'Error'
}

// ニュース カテゴリ
export const NewsCategoryTypeArray = ['column', 'news'] as const
export type NewsCategoryType = typeof NewsCategoryTypeArray[number]
export const NewsCategoryTypeList: { value: NewsCategoryType; label: string }[] = [
    { value: 'news', label: 'ニュース' },
    { value: 'column', label: 'コラム' },
]

export const NewsTypeFormat = (value: string): string => {
    const tempType = NewsCategoryTypeList.find((l) => l.value === value)
    if (tempType) return tempType.label
    return 'Error'
}

// ギフト 決済状況
export const PaymentStatusArray = [0, 1, 2, 9] as const
export type PaymentStatusType = typeof PaymentStatusArray[number]
export const paymentStatusList: { value: PaymentStatusType; label: string }[] = [
    { value: 0, label: '未決済' },
    { value: 1, label: '仮売上' },
    { value: 2, label: '本売上' },
    { value: 9, label: 'キャンセル' },
]

export const paymentStatusFormat = (value: number): string => {
    const paymentStatus = paymentStatusList.find((p) => p.value === value)
    return paymentStatus ? paymentStatus.label : ''
}

export const PaymentTypeArray = [0, 3] as const
export type PaymentTypeType = typeof PaymentTypeArray[number]
export const paymentTypeList: { value: PaymentTypeType; label: string }[] = [
    { value: 0, label: 'クレジットカード' },
    { value: 3, label: '銀行振込' },
]
export const paymentTypeFormat = (value: number): string => {
    const item = paymentTypeList.find((p) => p.value === value)
    return item ? item.label : ''
}

export const displayTimeFormat = (val: number): string => {
    if (!val) return ''
    const days = Math.floor(val / (60 * 24))
    const valDiffDay = val - days * (60 * 24)
    const hours = Math.floor(valDiffDay / 60)
    const minutes = valDiffDay - hours * 60
    const dayMessage = days === 0 ? '' : `${days}日`
    const hourMessage = hours === 0 ? '' : `${hours}時間`
    const minutesMessage = minutes === 0 ? '' : `${minutes}分`
    return dayMessage + hourMessage + minutesMessage
}

export const getYoutubeThumbnailUrl = (youtubeUrl?: string) => {
    if (!youtubeUrl) return
    try {
        const url = new URL(youtubeUrl)
        const urlParams = new URLSearchParams(url.search)
        const videoId = urlParams.get('v')
        return videoId ? `https://img.youtube.com/vi/${videoId}/sddefault.jpg` : null
    } catch (error) {
        return null
    }
}
