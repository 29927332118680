import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { uniq } from 'lodash-es'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminGiftPaymentStatusUpdateDialog } from '~/components/functional/gift/CAdminGiftPaymentStatusUpdateDialog'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import {
    datetimeFormat,
    DefaultMaxListLimit,
    PaymentStatusArray,
    paymentStatusFormat,
    paymentStatusList,
    PaymentStatusType,
    PaymentTypeArray,
    paymentTypeFormat,
    paymentTypeList,
    PaymentTypeType,
    useQueryString,
    useQuerySuspense,
} from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    startAt: Date | null
    endAt: Date | null
    paymentStatus: PaymentStatusType[]
    paymentType: PaymentTypeType[]
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    startAt?: string
    endAt?: string
    paymentStatus?: string[]
    paymentType?: string[]
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()

    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await setParams((prevState) => ({
            ...prevState,
            userUuid: userUuid,
        }))
        await handlePageChange(1, { userUuid })
    }

    const handleStartAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                startAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { startAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                startAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { startAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }
    const handleEndAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                endAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { endAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                endAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { endAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }

    // query から 決済状況を取得する
    let queryPaymentStatusList: PaymentStatusType[] = []
    query.getAll('paymentStatus').forEach((tempStatus) => {
        const status = Number(tempStatus) as PaymentStatusType
        if (PaymentStatusArray.includes(status)) queryPaymentStatusList.push(status as PaymentStatusType)
        queryPaymentStatusList = uniq(queryPaymentStatusList)
    })
    let queryPaymentTypeList: PaymentTypeType[] = []
    query.getAll('paymentType').forEach((tempType) => {
        const status = Number(tempType) as PaymentTypeType
        if (PaymentTypeArray.includes(status)) queryPaymentTypeList.push(status as PaymentTypeType)
        queryPaymentTypeList = uniq(queryPaymentTypeList)
    })

    const handlePaymentStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value) as PaymentStatusType
        let list = params.paymentStatus
        if (PaymentStatusArray.includes(value)) {
            if (list.includes(value)) list = list.filter((v) => v !== value)
            else list = [...list, value]
        }
        await setParams((prevState) => ({
            ...prevState,
            paymentStatus: list,
        }))
        await handlePageChange(1, { paymentStatus: list })
    }

    const handlePaymentTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value) as PaymentTypeType
        let list = params.paymentType
        if (PaymentTypeArray.includes(value)) {
            if (list.includes(value)) list = list.filter((v) => v !== value)
            else list = [...list, value]
        }
        await setParams((prevState) => ({
            ...prevState,
            paymentType: list,
        }))
        await handlePageChange(1, { paymentType: list })
    }

    const [params, setParams] = useState<Parameters>({
        page: Number(query.get('page') || 1),
        limit: Number(query.get('limit') || DefaultMaxListLimit),
        userUuid: query.get('userUuid') || undefined,
        startAt: query.get('startAt') ? dayjs(query.get('startAt'), 'YYYY-MM-DD').toDate() : null,
        endAt: query.get('endAt') ? dayjs(query.get('endAt'), 'YYYY-MM-DD').toDate() : null,
        paymentStatus: queryPaymentStatusList,
        paymentType: queryPaymentTypeList,
    })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminGiftIndex({
                parameter: {
                    ...params,
                    startAt: params.startAt ? dayjs(params.startAt).format('YYYY-MM-DD') : undefined,
                    endAt: params.endAt ? dayjs(params.endAt).format('YYYY-MM-DD') : undefined,
                },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (
        page: number,
        options?: {
            userUuid?: string | undefined
            startAt?: string | null
            endAt?: string | null
            paymentStatus?: PaymentStatusType[]
            paymentType?: PaymentTypeType[]
        },
    ) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = {
            page: params.page.toString(),
            limit: params.limit.toString(),
            paymentStatus: options?.paymentStatus?.map((p) => String(p)) ?? params.paymentStatus.map((p) => String(p)),
            paymentType: options?.paymentType?.map((p) => String(p)) ?? params.paymentType.map((p) => String(p)),
        }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid
        if (options && 'startAt' in options) {
            if (options.startAt) navigateSearchParams.startAt = options.startAt
        } else if (params.startAt) navigateSearchParams.startAt = dayjs(params.startAt).format('YYYY-MM-DD')
        if (options && 'endAt' in options) {
            if (options.endAt) navigateSearchParams.endAt = options.endAt
        } else if (params.endAt) navigateSearchParams.endAt = dayjs(params.endAt).format('YYYY-MM-DD')

        navigate({
            pathname: '/gift',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })

        await refetchList()
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.GiftEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.GiftEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminGiftUpdateDto, uuid?: string) => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminGiftUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminGiftPaymentStatusUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        EditDialog,
        handlePaymentStatusChange,
        handlePaymentTypeChange,
        handleStartAtChange,
        handleEndAtChange,
    }
}

export const GiftIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        EditDialog,
        handlePaymentStatusChange,
        handlePaymentTypeChange,
        handleStartAtChange,
        handleEndAtChange,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `ギフト注文一覧`, link: `/gift` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction={'row'} spacing={2}>
                        <FormControl component={'fieldset'} sx={{ flexDirection: 'row' }}>
                            <FormLabel component={'legend'}>決済種別</FormLabel>
                            {paymentTypeList.map((category) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={category.value}
                                            onChange={handlePaymentTypeChange}
                                            checked={params.paymentType.includes(category.value)}
                                        />
                                    }
                                    label={category.label}
                                    key={category.value}
                                />
                            ))}
                        </FormControl>

                        <FormControl component={'fieldset'} sx={{ flexDirection: 'row' }}>
                            <FormLabel component={'legend'}>決済状態</FormLabel>
                            {paymentStatusList.map((category) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={category.value}
                                            onChange={handlePaymentStatusChange}
                                            checked={params.paymentStatus.includes(category.value)}
                                        />
                                    }
                                    label={category.label}
                                    key={category.value}
                                />
                            ))}
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <CUserAutocomplete
                            label="購入者"
                            userUuid={params.userUuid}
                            onChangeAutocomplete={handleUserUuidChange}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <DatePicker
                                    label={'購入日開始'}
                                    value={params.startAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleStartAtChange(keyboardInputValue, true)
                                        } else await handleStartAtChange(val, false)
                                    }}
                                />

                                <Typography>〜</Typography>

                                <DatePicker
                                    label={'購入日終了'}
                                    value={params.endAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleEndAtChange(keyboardInputValue, true)
                                        } else await handleEndAtChange(val, false)
                                    }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>購入者</TableCell>
                                <TableCell>購入日時</TableCell>
                                <TableCell>配送日</TableCell>
                                <TableCell>合計金額</TableCell>
                                <TableCell>決済種別</TableCell>
                                <TableCell>決済状況</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((gift: Schemas.GiftEntities) => (
                                <TableRow key={gift.uuid}>
                                    <TableCell>
                                        {gift.user && (
                                            <Link href={`/gift/${gift.uuid}`} color={'primary'}>
                                                {gift.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>{datetimeFormat(gift.createdAt)}</TableCell>
                                    <TableCell>
                                        {!gift.isLastGift && gift.deliveredAt && dayjs(gift.deliveredAt).format('YYYY-MM-DD')}
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{gift.totalPrice.toLocaleString()}円</TableCell>
                                    <TableCell>{paymentTypeFormat(gift.paymentType)}</TableCell>
                                    <TableCell>{paymentStatusFormat(gift.paymentStatus)}</TableCell>
                                    <TableCell>
                                        <Button
                                            disabled={gift.paymentStatus === 9}
                                            onClick={() => editButtonHandler(gift)}
                                            color={'primary'}
                                            size={'small'}>
                                            決済状態変更
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {count === 0 ? (
                        <Typography sx={{ mt: 2 }}>メディアはありません</Typography>
                    ) : (
                        <Pagination
                            count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                            page={params.page}
                            onChange={(_, value) => handlePageChange(value)}
                            showFirstButton={true}
                            showLastButton={true}
                            sx={{ mt: 2 }}
                        />
                    )}
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
