import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Link,
    Pagination,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, getYoutubeThumbnailUrl, mediaUrl, openInNewTab, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    startAt: Date | null
    endAt: Date | null
}

type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    startAt?: string
    endAt?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || 12)

    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await setParams((prevState) => ({
            ...prevState,
            userUuid: userUuid,
        }))
        await handlePageChange(1, { userUuid })
    }

    const handleStartAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                startAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { startAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                startAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { startAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }
    const handleEndAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                endAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { endAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                endAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { endAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }

    const [params, setParams] = useState<Parameters>({
        page: page,
        limit: limit,
        userUuid: query.get('userUuid') || undefined,
        startAt: query.get('startAt') ? dayjs(query.get('startAt'), 'YYYY-MM-DD').toDate() : null,
        endAt: query.get('endAt') ? dayjs(query.get('endAt'), 'YYYY-MM-DD').toDate() : null,
    })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminMemoryAssetIndex({
                parameter: {
                    ...params,
                    startAt: params.startAt ? dayjs(params.startAt).format('YYYY-MM-DD') : undefined,
                    endAt: params.endAt ? dayjs(params.endAt).format('YYYY-MM-DD') : undefined,
                },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (
        page: number,
        options?: {
            userUuid?: string | undefined
            startAt?: string | null
            endAt?: string | null
        },
    ) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = {
            page: params.page.toString(),
            limit: params.limit.toString(),
        }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid
        if (options && 'startAt' in options) {
            if (options.startAt) navigateSearchParams.startAt = options.startAt
        } else if (params.startAt) navigateSearchParams.startAt = dayjs(params.startAt).format('YYYY-MM-DD')
        if (options && 'endAt' in options) {
            if (options.endAt) navigateSearchParams.endAt = options.endAt
        } else if (params.endAt) navigateSearchParams.endAt = dayjs(params.endAt).format('YYYY-MM-DD')

        navigate({
            pathname: '/memoryAsset',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })

        await refetchList()
    }

    const deleteButtonHandler = async (memory: Schemas.MemoryAssetEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminMemoryAssetRemove({
                parameter: {
                    uuid: memory.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
        deleteButtonHandler,
        handleUserUuidChange,
        handleStartAtChange,
        handleEndAtChange,
    }
}

export const MemoryAssetIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchList,
        deleteButtonHandler,
        handleUserUuidChange,
        handleStartAtChange,
        handleEndAtChange,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `思い出メディア一覧`, link: `/memoryAsset` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction={'row'} spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <CUserAutocomplete
                            label={'投稿ユーザ'}
                            userUuid={params.userUuid}
                            onChangeAutocomplete={handleUserUuidChange}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <DatePicker
                                    label={'登録日開始'}
                                    value={params.startAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleStartAtChange(keyboardInputValue, true)
                                        } else await handleStartAtChange(val, false)
                                    }}
                                />

                                <Typography>〜</Typography>

                                <DatePicker
                                    label={'登録日終了'}
                                    value={params.endAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleEndAtChange(keyboardInputValue, true)
                                        } else await handleEndAtChange(val, false)
                                    }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Grid container spacing={2}>
                        {list.map((asset: Schemas.MemoryAssetEntities) => (
                            <Grid item xs={4} sm={4} md={4} key={asset.uuid}>
                                <Card>
                                    <CardActionArea onClick={() => openInNewTab(mediaUrl(asset.file))}>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            sx={{ objectFit: 'contain' }}
                                            image={asset.url ? getYoutubeThumbnailUrl(asset.url) ?? '' : mediaUrl(asset.file)}
                                            alt={asset.url ? asset.title ?? '' : asset.file?.filename}
                                        />
                                    </CardActionArea>
                                    <CardContent>
                                        <Box
                                            color={'gray'}
                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography>{datetimeFormat(asset.createdAt)}</Typography>
                                            <IconButton color={'error'} onClick={() => deleteButtonHandler(asset)} size={'small'}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link href={`/user/${asset.user.uuid}`} color={'primary'}>
                                                {asset.user.name}
                                            </Link>
                                        </Box>

                                        <Typography sx={{ mt: 1 }}>
                                            <Link href={`/memory/${asset.memory.uuid}`} color={'primary'}>
                                                {asset.memory.name}
                                            </Link>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {count === 0 ? (
                        <Typography sx={{ mt: 2 }}>メディアはありません</Typography>
                    ) : (
                        <Pagination
                            count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                            page={params.page}
                            onChange={(_, value) => handlePageChange(value)}
                            showFirstButton={true}
                            showLastButton={true}
                            sx={{ mt: 2 }}
                        />
                    )}
                </Box>
            </DefaultLayout>
        </>
    )
}
