import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import CancelIcon from '@mui/icons-material/Cancel'
import { LoadingButton } from '@mui/lab'
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CRequiredText } from '~/components/common/cRequiredText/CRequiredText'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { AdminSponsorCreateUpdateDtoSchema, adminSponsorCreateUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useDialog = (isOpen: boolean, entity?: Schemas.SponsorEntities) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
        setValue,
    } = useForm<AdminSponsorCreateUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminSponsorCreateUpdateDtoSchema),
    })

    const selectMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '15rem',
            },
        },
    }

    const [selectFile, setSelectFile] = useState<Schemas.FileEntities | null>(entity?.bannerImage || null)
    const [selectedPrefectures, setSelectedPrefectures] = useState<string[]>([])
    const [selectedCategory, setSelectedCategory] = useState<string[]>([])

    useEffect(() => {
        reset({
            name: entity?.name || '',
            nameKana: entity?.nameKana || '',
            email: entity?.email || '',
            address: entity?.address || '',
            phoneNumber: entity?.phoneNumber || '',
            siteUrl: entity?.siteUrl || '',
            publish: entity?.publish || false,
            bannerImageUuid: entity?.bannerImage?.uuid || null,
        })
        setSelectFile(entity?.bannerImage || null)
        setSelectedCategory(entity?.categories.map((t) => t.uuid) || [])
        setSelectedPrefectures(entity?.coveredPrefectures.map((t) => t.uuid) || [])
    }, [isOpen, entity])

    const { data: sponsorCategoryListResponse } = useQuerySuspense(
        ['sponsorCategoryListResponse'],
        async () => {
            const r = await apiClient.adminSponsorGetAllSponsorCategories({ parameter: {} })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { data: sponsorPrefectureListResponse } = useQuerySuspense(
        ['sponsorPrefectureListResponse'],
        async () => {
            const r = await apiClient.adminPrefectureGetAllPrefectures()
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const prefecturesTitle = useMemo(() => {
        return sponsorPrefectureListResponse?.list.map((prefecture) => {
            return (
                <MenuItem key={prefecture.uuid} value={prefecture.uuid}>
                    {prefecture.title}
                </MenuItem>
            )
        })
    }, [sponsorPrefectureListResponse])

    const categoriesTitle = useMemo(() => {
        return sponsorCategoryListResponse?.list.map((category) => {
            return (
                <MenuItem key={category.uuid} value={category.uuid}>
                    {category.title}
                </MenuItem>
            )
        })
    }, [sponsorCategoryListResponse])

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('bannerImageUuid', value.uuid)
            setSelectFile(value)
        }
    }
    const handleRemoveFile = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '削除確認',
            text: `この画像を削除します`,
        })
        if (!res) return
        setValue('bannerImageUuid', null)
        setSelectFile(null)
    }

    const handleSelectCategory = (event: SelectChangeEvent<string[]>) => {
        if (Array.isArray(event.target.value)) setSelectedCategory(event.target.value)
    }

    const handleSelectPrefecture = (event: SelectChangeEvent<string[]>) => {
        if (Array.isArray(event.target.value)) setSelectedPrefectures(event.target.value)
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        handleUploadFile,
        handleRemoveFile,
        selectFile,
        selectedPrefectures,
        setSelectedPrefectures,
        selectedCategory,
        setSelectedCategory,
        handleSelectCategory,
        handleSelectPrefecture,
        selectMenuProps,
        prefecturesTitle,
        categoriesTitle,
        sponsorCategoryListResponse,
        sponsorPrefectureListResponse,
    }
}

export type CAdminSponsorCreateUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminCreateSponsorDto, uuid?: string) => void
    entity?: Schemas.SponsorEntities
}

export const CAdminSponsorCreateUpdateDialog: FC<CAdminSponsorCreateUpdateDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        handleUploadFile,
        handleRemoveFile,
        selectFile,
        selectedPrefectures,
        setSelectedPrefectures,
        selectedCategory,
        setSelectedCategory,
        handleSelectCategory,
        handleSelectPrefecture,
        selectMenuProps,
        prefecturesTitle,
        categoriesTitle,
        sponsorCategoryListResponse,
        sponsorPrefectureListResponse,
    } = useDialog(isOpen, entity)
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>事業者を{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack gap={'1rem'}>
                        <Stack color={'#606060'}>
                            <CFileUpload
                                completionHandler={handleUploadFile}
                                label={'画像アップロード'}
                                error={!!errors.bannerImageUuid}
                                helperText={errors.bannerImageUuid?.message}
                            />

                            <Grid container spacing={2} sx={{ mt: 0 }}>
                                {selectFile && (
                                    <Grid item xs={3} sm={3} md={3} key={selectFile.uuid}>
                                        <Badge color="error" badgeContent={'x'} onClick={() => handleRemoveFile()}>
                                            <img
                                                src={mediaUrl(selectFile)}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '200px',
                                                    maxHeight: '150px',
                                                    objectFit: 'contain',
                                                }}
                                                alt={selectFile.filename}
                                            />
                                        </Badge>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>

                        <Stack gap={'0.5rem'}>
                            <InputLabel sx={{ display: 'flex' }}>
                                事業者名（ふりがな）
                                <CRequiredText />
                            </InputLabel>
                            <TextField
                                id={'name'}
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                            <TextField
                                id={'nameKana'}
                                {...register('nameKana')}
                                error={!!errors.nameKana}
                                helperText={
                                    errors.nameKana?.message ||
                                    '※ふりがなは、団体種別(株式会社：かぶしきがいしゃ 等)を省略して入力してください。'
                                }
                            />
                        </Stack>

                        <Stack gap={'0.5rem'}>
                            <InputLabel sx={{ display: 'flex' }}>
                                メールアドレス <CRequiredText />
                            </InputLabel>
                            <TextField
                                id={'email'}
                                {...register('email')}
                                error={!!errors.email}
                                helperText={errors.email?.message || '※メールアドレスの形式で入力してください。'}
                            />
                        </Stack>
                        <Stack gap={'0.5rem'}>
                            <InputLabel sx={{ display: 'flex' }}>
                                代表者住所 <CRequiredText />
                            </InputLabel>
                            <TextField
                                id={'address'}
                                {...register('address')}
                                error={!!errors.address}
                                helperText={errors.address?.message || '※住所形式で入力してください。'}
                            />
                        </Stack>

                        <Stack gap={'0.5rem'}>
                            <InputLabel sx={{ display: 'flex' }}>
                                代表者電話番号 <CRequiredText />
                            </InputLabel>

                            <TextField
                                id={'phoneNumber'}
                                {...register('phoneNumber')}
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber?.message || '※電話番号形式で入力してください。'}
                            />
                        </Stack>

                        <Stack gap={'0.5rem'}>
                            <InputLabel>WebサイトURL</InputLabel>
                            <TextField
                                id={'siteUrl'}
                                {...register('siteUrl')}
                                error={!!errors.siteUrl}
                                helperText={errors.siteUrl?.message}
                            />
                        </Stack>

                        <Stack>
                            <Stack gap={'0.5rem'}>
                                <InputLabel sx={{ display: 'flex' }}>
                                    カテゴリ <CRequiredText />
                                </InputLabel>
                                <Select
                                    multiple
                                    value={selectedCategory}
                                    onChange={handleSelectCategory}
                                    MenuProps={selectMenuProps}
                                    renderValue={(selected: string[]) => (
                                        <Stack direction={'row'} spacing={1}>
                                            {(selected as string[]).map((value) => {
                                                const category = sponsorCategoryListResponse?.list?.find((t) => t.uuid === value)
                                                return (
                                                    <Chip
                                                        key={value}
                                                        label={category?.title}
                                                        onDelete={() =>
                                                            setSelectedCategory(selectedCategory.filter((item) => item !== value))
                                                        }
                                                        deleteIcon={
                                                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                                        }
                                                    />
                                                )
                                            })}
                                        </Stack>
                                    )}>
                                    {categoriesTitle}
                                </Select>
                            </Stack>
                            <FormHelperText sx={{ ml: '14px', mt: '4px' }}>
                                ※少なくとも1つのカテゴリーを選択してください。
                            </FormHelperText>
                        </Stack>

                        <Stack>
                            <Stack gap={'0.5rem'}>
                                <InputLabel sx={{ display: 'flex' }}>
                                    都道府県 <CRequiredText />
                                </InputLabel>
                                <Select
                                    multiple
                                    value={selectedPrefectures}
                                    onChange={handleSelectPrefecture}
                                    MenuProps={selectMenuProps}
                                    renderValue={(selected: string[]) => (
                                        <Stack direction={'row'} spacing={1}>
                                            {(selected as string[]).map((value) => {
                                                const prefecture = sponsorPrefectureListResponse?.list?.find(
                                                    (t) => t.uuid === value,
                                                )
                                                return (
                                                    <Chip
                                                        key={value}
                                                        label={prefecture?.title}
                                                        onDelete={() =>
                                                            setSelectedPrefectures(
                                                                selectedPrefectures.filter((item) => item !== value),
                                                            )
                                                        }
                                                        deleteIcon={
                                                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                                        }
                                                    />
                                                )
                                            })}
                                        </Stack>
                                    )}>
                                    {prefecturesTitle}
                                </Select>
                            </Stack>
                            <FormHelperText sx={{ ml: '14px', mt: '4px' }}>
                                ※少なくとも1つの都道府県を選択してください。
                            </FormHelperText>
                        </Stack>

                        <CSwitch name={'publish'} control={control} label={'公開する'} />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button onClick={onClose} sx={{ fontSize: '1rem' }}>
                        キャンセル
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid || !selectedCategory.length || !selectedPrefectures.length}
                        sx={{ fontSize: '1rem' }}
                        onClick={handleSubmit((dto) =>
                            onSubmit(
                                {
                                    ...dto,
                                    categoriesUuids: selectedCategory,
                                    coveredPrefectureUuids: selectedPrefectures,
                                },
                                entity?.uuid,
                            ),
                        )}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
