import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminInquiryUpdateDtoSchema, adminInquiryUpdateDtoSchema } from '~/types/zodScheme'

export const useCInquiryUpdateDialog = (isOpen: boolean, entity: Schemas.InquiryEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminInquiryUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminInquiryUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            category: entity?.category || null,
            title: entity?.title || '',
            content: entity?.content || '',
            name: entity?.name || '',
            email: entity?.email || null,
            tel: entity?.tel || null,
            memo: entity?.memo || null,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

export type CInquiryUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminInquiryUpdateDto, uuid: string) => void
    entity: Schemas.InquiryEntities
}

export const CInquiryUpdateDialog: FC<CInquiryUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useCInquiryUpdateDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>問い合わせを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'category'}
                            required={false}
                            {...register('category')}
                            label={'カテゴリ'}
                            error={!!errors.category}
                            helperText={errors.category?.message}
                        />

                        <TextField
                            id={'title'}
                            required={true}
                            {...register('title')}
                            label={'タイトル'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                        />
                        <TextField
                            id={'content'}
                            required={true}
                            {...register('content')}
                            label={'内容'}
                            error={!!errors.content}
                            helperText={errors.content?.message}
                            multiline={true}
                            rows={6}
                        />

                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'投稿者'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />

                        <TextField
                            id={'email'}
                            required={true}
                            {...register('email')}
                            label={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />

                        <TextField
                            id={'tel'}
                            required={false}
                            {...register('tel')}
                            label={'電話番号'}
                            error={!!errors.tel}
                            helperText={errors.tel?.message}
                        />

                        <TextField
                            id={'memo'}
                            required={false}
                            {...register('memo')}
                            label={'管理メモ'}
                            error={!!errors.memo}
                            helperText={errors.memo?.message}
                            multiline={true}
                            rows={6}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
