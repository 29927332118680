import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminConnectUpdateDialog } from '~/components/functional/connectTag/CAdminConnectTagUpdateDialog'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)
    const [userUuid, setUserUuid] = useState<string | undefined>(query.get('userUuid') || undefined)
    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await setUserUuid(userUuid)
        await handlePageChange(1, { userUuid })
    }
    const params: Parameters = { page: page, limit: limit, userUuid: userUuid }

    const [editTarget, setEditTarget] = useState<Schemas.ConnectTagEntities>()

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params],
        async () => {
            const r = await apiClient.adminConnectTagIndex({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number, options?: { userUuid?: string | undefined }) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid

        navigate({
            pathname: '/connectTag',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.ConnectTagEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminConnectTagUpdateDto, uuid?: string) => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminConnectTagUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminConnectUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (tag: Schemas.ConnectTagEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminConnectTagRemove({
                parameter: {
                    uuid: tag.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
        userUuid,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const ConnectTagIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchList,
        userUuid,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `つながりタグ一覧`, link: `/connect` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} color="primary" variant={'contained'}>
                                追加
                            </Button>
                        </Box>
                    </Box>

                    <CUserAutocomplete userUuid={userUuid} onChangeAutocomplete={handleUserUuidChange} />

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録者</TableCell>
                                <TableCell>タグ名</TableCell>
                                <TableCell>表示順</TableCell>
                                <TableCell>更新日</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((tag: Schemas.ConnectTagEntities) => (
                                <TableRow key={tag.uuid}>
                                    <TableCell>
                                        {tag.user && (
                                            <Link href={`/user/${tag.user.uuid}`} color={'primary'}>
                                                {tag.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>{tag.name}</TableCell>
                                    <TableCell>{tag.sort}</TableCell>
                                    <TableCell>{datetimeFormat(tag.updatedAt)}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(tag)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(tag)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
