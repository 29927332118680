import { useDisclosure } from '@chakra-ui/hooks'
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminUpdateDialog } from '~/components/functional/admin/CAdminUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const adminId = params.adminId

    // initial fetch
    const { data: admin, refetch } = useQuerySuspense(
        [adminId],
        async () => {
            if (!adminId) throw new Error()

            return await apiClient.adminAdminShow({
                parameter: {
                    uuid: adminId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminAdminUpdateDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminAdminUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!adminId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminAdminRemove({
                    parameter: {
                        uuid: adminId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/admin`)
            } catch (e) {
                let message = '更新に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `管理者一覧`, link: `/admin` },
        { title: `${admin?.name || '管理者詳細'}`, link: `` },
    ]

    const EditDialog = (
        <CAdminUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={admin!}
        />
    )

    return {
        breadcrumbList,
        admin,
        editButtonHandler,
        editDialogSubmitHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const AdminDetailPage: FC = () => {
    const { breadcrumbList, admin, editButtonHandler, deleteButtonHandler, EditDialog } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'管理者詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>

                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>{admin!.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>{admin!.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>システム管理者</TableCell>
                                <TableCell>{admin!.systemAdmin && '○'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>登録日</TableCell>
                                <TableCell>{datetimeFormat(admin!.createdAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>更新日</TableCell>
                                <TableCell>{datetimeFormat(admin!.updatedAt)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
