import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminUserAddressUpdateDtoSchema, adminUserAddressUpdateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity?: Schemas.UserAddressEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminUserAddressUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminUserAddressUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            sei: entity?.sei || '',
            mei: entity?.mei || '',
            postalCode: entity?.postalCode || null,
            pref: entity?.pref || null,
            city: entity?.city || null,
            address: entity?.address || null,
            building: entity?.building || null,
            tel: entity?.tel || null,
            mobilePhone: entity?.mobilePhone || null,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

export type CUserAddressUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminUserAddressUpdateDto) => void
    entity?: Schemas.UserAddressEntities
}

export const CUserAddressUpdateDialog: FC<CUserAddressUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>住所を編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'sei'}
                                {...register('sei')}
                                label={'姓'}
                                error={!!errors.sei}
                                helperText={errors.sei?.message}
                                required={true}
                            />
                            <TextField
                                id={'mei'}
                                {...register('mei')}
                                label={'名'}
                                error={!!errors.mei}
                                helperText={errors.mei?.message}
                                required={true}
                            />
                        </Stack>

                        <TextField
                            id={'postalCode'}
                            {...register('postalCode')}
                            label={'郵便番号'}
                            error={!!errors.postalCode}
                            helperText={errors.postalCode?.message}
                        />
                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'pref'}
                                {...register('pref')}
                                label={'都道府県'}
                                error={!!errors.pref}
                                helperText={errors.pref?.message}
                            />
                            <TextField
                                id={'city'}
                                {...register('city')}
                                label={'市区町村'}
                                error={!!errors.city}
                                helperText={errors.city?.message}
                            />
                        </Stack>
                        <TextField
                            id={'address'}
                            {...register('address')}
                            label={'丁目番地'}
                            error={!!errors.address}
                            helperText={errors.address?.message}
                        />
                        <TextField
                            id={'building'}
                            {...register('building')}
                            label={'ビル名・肩書等'}
                            error={!!errors.building}
                            helperText={errors.building?.message}
                        />

                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'tel'}
                                {...register('tel')}
                                label={'電話番号'}
                                error={!!errors.tel}
                                helperText={errors.tel?.message}
                            />
                            <TextField
                                id={'mobilePhone'}
                                {...register('mobilePhone')}
                                label={'携帯電話番号'}
                                error={!!errors.mobilePhone}
                                helperText={errors.mobilePhone?.message}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
