import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import type { ChangeEvent, FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminInquiryUpdateStatusDtoSchema, adminInquiryUpdateStatusDtoSchema } from '~/types/zodScheme'
import { InquiryStatusList } from '~/utils/common'

const useDialog = (isOpen: boolean, entity?: Schemas.InquiryEntities) => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminInquiryUpdateStatusDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminInquiryUpdateStatusDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            status: entity?.status || 0,
        })
    }, [isOpen, entity])

    const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value)
        switch (value) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                setValue('status', value)
        }
    }

    return {
        register,
        handleSubmit,
        isSubmitting,
        isValid,
        handleChangeStatus,
    }
}

type Props = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminInquiryUpdateStatusDto, uuid: string) => void
    entity?: Schemas.InquiryEntities
}

export const CInquiryUpdateStatusDialog: FC<Props> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, isSubmitting, isValid, handleChangeStatus } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
                <DialogTitle>問い合わせの状態を編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'status'}
                            required={true}
                            {...register('status')}
                            label={'ステータス'}
                            select={true}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                handleChangeStatus(event)
                            }}
                            defaultValue={entity?.status ?? 0}>
                            {InquiryStatusList.map((st) => (
                                <MenuItem key={st.value} value={st.value}>
                                    {st.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity!.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
