import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
        },
    },
})
