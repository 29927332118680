import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams, useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CUserAddressDetail } from '~/components/functional/user/CUserAddressDetail'
import { CUserUpdateDialog } from '~/components/functional/user/CUserUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { dateFormat, datetimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useUserDetailPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const userId = params.userId

    // initial fetch
    const { data: user, refetch } = useQuerySuspense(
        [userId],
        async () => {
            if (!userId) throw new Error()

            return await apiClient.adminUserShow({
                parameter: {
                    uuid: userId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminUserUpdateDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminUserUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!userId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminUserRemove({
                    parameter: {
                        uuid: userId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/user`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `ユーザ一覧`, link: `/user` },
        { title: `${user!.name}`, link: `/user/${user!.uuid}` },
    ]

    const EditDialog = (
        <CUserUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={user}
        />
    )

    const moveWidthUser = async (user: Schemas.UserEntities, pathname: string) => {
        await navigate({
            pathname: pathname,
            search: `?${createSearchParams({
                userUuid: user!.uuid,
            })}`,
        })
    }

    const getUserSnsAccountTypes = () => {
        if (user?.snsAccount?.length === 0) return undefined
        const accounts: string[] = []
        const snsAccountTypes = user?.snsAccount?.map((snsAccount) => snsAccount.type)
        snsAccountTypes?.forEach((type) => {
            if (type === 0) accounts.push('LINE')
            else if (type === 1) accounts.push('Facebook')
            else accounts.push('Error')
        })
        return accounts.join(', ')
    }
    const snsAccounts = getUserSnsAccountTypes()

    const UserAddressDetail = <CUserAddressDetail userUuid={userId!} />

    return {
        breadcrumbList,
        user,
        editButtonHandler,
        editDialogSubmitHandler,
        deleteButtonHandler,
        EditDialog,
        moveWidthUser,
        UserAddressDetail,
        snsAccounts,
    }
}

export const UserDetailPage: FC = () => {
    const {
        breadcrumbList,
        user,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
        moveWidthUser,
        UserAddressDetail,
        snsAccounts,
    } = useUserDetailPage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'ユーザ詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>

                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>{user!.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>画像</TableCell>
                                <TableCell>
                                    <img src={mediaUrl(user!.file)} width={'128px'} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>携帯電話番号</TableCell>
                                <TableCell>{user!.mobilePhone}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>{user!.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>SNSアカウント</TableCell>
                                <TableCell>{snsAccounts ?? 'なし'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>登録日</TableCell>
                                <TableCell>{datetimeFormat(user!.createdAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>更新日</TableCell>
                                <TableCell>{datetimeFormat(user!.updatedAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>誕生日</TableCell>
                                <TableCell>{dateFormat(user!.birthday)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>命日</TableCell>
                                <TableCell>{dateFormat(user!.deathDay)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {user && (
                    <Stack sx={{ mt: 2 }} direction={'row'} spacing={2}>
                        <Button onClick={() => moveWidthUser(user, '/connect')} color={'primary'} size={'small'}>
                            つながりリスト
                        </Button>
                        <Button onClick={() => moveWidthUser(user, '/connectTag')} color={'primary'} size={'small'}>
                            タグリスト
                        </Button>
                        <Button onClick={() => moveWidthUser(user, '/grave')} color={'primary'} size={'small'}>
                            お墓
                        </Button>
                        <Button onClick={() => moveWidthUser(user, '/memory')} color={'primary'} size={'small'}>
                            思い出
                        </Button>
                    </Stack>
                )}

                <Box mt={2}>{UserAddressDetail}</Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
