import { Link, Typography } from '@mui/material'
import { FC } from 'react'

import { DefaultLayout } from '~/components/layout/Default'
import { useAuthState } from '~/store/auth'

const useTopPage = () => {
    const { me } = useAuthState()

    return {
        me,
    }
}

export const TopPage: FC = () => {
    const { me } = useTopPage()

    return (
        <>
            <DefaultLayout breadcrumbList={[]}>
                {!me ? <Link href="/login">ログイン画面へ</Link> : <Typography>ダッシュボード</Typography>}
            </DefaultLayout>
        </>
    )
}
