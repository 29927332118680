import axios, { AxiosRequestConfig, Method } from 'axios'
import { mapValues } from 'lodash-es'

import { Client, HttpMethod, ObjectLike, QueryParameters, SuccessResponses } from '~/apis/apiClient'
import { AdminToken } from '~/store/auth'

export const createApiClient = () => {
    const baseUrl = process.env.BASE_URL || ''

    return ApiClient(baseUrl)
}

export const ApiClient = (baseUrl: string) => {
    return new Client(
        {
            async request<T = SuccessResponses>(
                httpMethod: HttpMethod,
                url: string,
                headers: ObjectLike,
                requestBody: ObjectLike,
                queryParameters: QueryParameters | undefined,
                options?: AxiosRequestConfig,
            ): Promise<T> {
                // add token
                const _adminToken = localStorage.getItem('adminToken')
                const adminToken = (_adminToken ? JSON.parse(_adminToken) : {}) as AdminToken
                const _headers = adminToken.accessToken
                    ? {
                          'x-authorization': 'Bearer ' + adminToken.accessToken,
                          ...headers,
                      }
                    : { ...headers }
                return (
                    await axios.request({
                        method: httpMethod as Method,
                        url,
                        headers: _headers,
                        data: requestBody,
                        params: queryParameters ? mapValues(queryParameters, (it) => it.value) : undefined,
                        ...options,
                    })
                ).data as T
            },
        },
        baseUrl,
    )
}
