import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import type { FC } from 'react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { AdminOfferingMasterUpdateDtoSchema, adminOfferingMasterUpdateDtoSchema } from '~/types/zodScheme'
import { displayTimeFormat, mediaUrl } from '~/utils/common'

const useCOfferingMasterUpdateDialog = (isOpen: boolean, entity?: Schemas.OfferingMasterEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
        setValue,
    } = useForm<AdminOfferingMasterUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminOfferingMasterUpdateDtoSchema),
    })
    const { queueDialog } = useConfirmationDialog()

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            content: entity?.content || '',
            code: entity?.code || null,
            price: entity?.price || 0,
            displayTime: entity?.displayTime || 0,
            startAt: entity?.startAt ? dayjs(entity.startAt).format('YYYY-MM-DD') : null,
            endAt: entity?.endAt ? dayjs(entity.endAt).format('YYYY-MM-DD') : null,
            publish: entity?.publish || false,
            fileUuid: entity?.file?.uuid || null,
        })
        setSelectFile(entity?.file || null)
        setDisplayTime(entity?.displayTime || 0)
    }, [isOpen, entity])

    const [selectFile, setSelectFile] = useState<Schemas.FileEntities | null>(entity?.file || null)

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setSelectFile(value)
        }
    }

    const handleRemoveFile = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '削除確認',
            text: `この画像を削除します`,
        })
        if (!res) return
        setValue('fileUuid', null)
        setSelectFile(null)
    }

    const [displayTime, setDisplayTime] = useState(entity?.displayTime || 0)
    const handleChangeDisplayTime = (event: ChangeEvent<HTMLInputElement>) => {
        setDisplayTime(parseInt(event.target.value, 10))
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        selectFile,
        handleUploadFile,
        handleRemoveFile,
        displayTime,
        handleChangeDisplayTime,
    }
}

type COfferingMasterUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminOfferingMasterUpdateDto, uuid?: string) => void
    entity?: Schemas.OfferingMasterEntities
}

export const CAdminOfferingMasterUpdateDialog: FC<COfferingMasterUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        handleUploadFile,
        selectFile,
        handleRemoveFile,
        displayTime,
        handleChangeDisplayTime,
    } = useCOfferingMasterUpdateDialog(isOpen, entity)

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>お供え物マスタを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        id={'code'}
                        {...register('code')}
                        label={'商品コード'}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                    />
                    <TextField
                        id={'name'}
                        required={true}
                        {...register('name')}
                        label={'商品名'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <TextField
                        id={'price'}
                        required={true}
                        {...register('price', { valueAsNumber: true })}
                        label={'価格'}
                        type="number"
                        error={!!errors.price}
                        helperText={errors.price?.message}
                    />
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'baseline' }}>
                        <TextField
                            id={'displayTime'}
                            required={true}
                            {...register('displayTime', { valueAsNumber: true })}
                            label={'表示分数'}
                            type="number"
                            error={!!errors.displayTime}
                            helperText={errors.displayTime?.message || '1時間:60, 1日:1440, 3日:4320'}
                            onChange={handleChangeDisplayTime}
                        />
                        <Typography>{displayTimeFormat(displayTime)}</Typography>
                    </Stack>
                    <TextField
                        id={'content'}
                        {...register('content')}
                        label={'内容'}
                        error={!!errors.content}
                        helperText={errors.content?.message}
                        multiline={true}
                        rows={6}
                    />

                    <Box>
                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />

                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {selectFile && (
                                <Grid item xs={3} sm={3} md={3} key={selectFile.uuid}>
                                    <Badge color="error" badgeContent={'x'} onClick={() => handleRemoveFile()}>
                                        <img
                                            src={mediaUrl(selectFile)}
                                            style={{ width: '100%', maxWidth: '200px', maxHeight: '150px', objectFit: 'contain' }}
                                            alt={selectFile.filename}
                                        />
                                    </Badge>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box display={'flex'} alignItems={'center'} sx={{ mt: 2 }}>
                        <CDatePicker
                            type={'date'}
                            label={'公開開始日'}
                            control={control}
                            name={'startAt'}
                            error={!!errors.startAt}
                            helperText={errors.startAt?.message}
                        />
                        <Typography>〜</Typography>
                        <CDatePicker
                            type={'date'}
                            label={'公開終了日'}
                            control={control}
                            name={'endAt'}
                            error={!!errors.endAt}
                            helperText={errors.endAt?.message}
                        />
                    </Box>

                    <CSwitch name={'publish'} control={control} label={'公開する'} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <LoadingButton
                    variant={'contained'}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                    保存
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
