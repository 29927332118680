import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminGraveUpdateDtoSchema, adminGraveUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

const useDialog = (isOpen: boolean, entity?: Schemas.GraveEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,

        setValue,
    } = useForm<AdminGraveUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminGraveUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            fileUuid: entity?.file?.uuid || undefined,
            posthumousName: entity?.posthumousName || null,
            denomination: entity?.denomination || null,
        })
    }, [isOpen, entity])

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity?.file || undefined)

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,

        handleUploadFile,
        image,
    }
}

export type CAdminGraveUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminGraveUpdateDto, uuid?: string) => void
    entity?: Schemas.GraveEntities
}

export const CAdminGraveUpdateDialog: FC<CAdminGraveUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, image, handleUploadFile } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>お墓を編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />

                        <TextField
                            id={'posthumousName'}
                            {...register('posthumousName')}
                            label={'氏名'}
                            error={!!errors.posthumousName}
                            helperText={errors.posthumousName?.message}
                        />
                        <TextField
                            id={'denomination'}
                            {...register('denomination')}
                            label={'没年月日・享年など'}
                            error={!!errors.denomination}
                            helperText={errors.denomination?.message}
                        />

                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />

                        {image?.path && (
                            <img
                                src={mediaUrl(image)}
                                style={{ width: '100%', maxWidth: '200px', maxHeight: '200px' }}
                                alt={image.filename}
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
