import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

export type BreadcrumbListType = { title: string; link: string }[]

export type DefaultLayoutProps = {
    children: ReactNode
    title?: string
    breadcrumbList: BreadcrumbListType
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children, title, breadcrumbList }) => {
    return (
        <>
            <Box maxWidth="1200px" sx={{ mx: 'auto', mt: 4, px: 3, pb: 10 }}>
                <Box>
                    <Breadcrumbs sx={{ mt: 0 }}>
                        {breadcrumbList.map((bc, idx) =>
                            breadcrumbList.length - 1 === idx ? (
                                <Typography key={idx}>{bc.title}</Typography>
                            ) : (
                                <Link href={bc.link} color={'inherit'} underline={'hover'} key={idx}>
                                    {bc.title}
                                </Link>
                            ),
                        )}
                    </Breadcrumbs>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        {title || ''}
                    </Typography>
                </Box>
                <Stack sx={{ my: 2 }}>{children}</Stack>
            </Box>
        </>
    )
}
