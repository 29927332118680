import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    familyTreeName: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    familyTreeName?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)

    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await handleParamsChange({ userUuid })
        await handlePageChange(1, { userUuid })
    }

    const [params, setParams] = useState<Parameters>({
        page: page,
        limit: limit,
        userUuid: query.get('userUuid') || undefined,
        familyTreeName: query.get('familyTreeName') || '',
    })

    const handleParamsChange = async (options: { userUuid?: string; familyTreeName?: string }) => {
        await setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
    }

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminFamilyTreeIndex({
                parameter: { ...params, name: params.familyTreeName ? params.familyTreeName : undefined },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number, options?: { userUuid?: string | undefined }) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid
        if (params.familyTreeName) navigateSearchParams.familyTreeName = params.familyTreeName

        navigate({
            pathname: '/familyTree',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })

        await refetchList()
    }

    const deleteButtonHandler = async (familyTree: Schemas.FamilyTreeEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminFamilyTreeRemove({
                parameter: {
                    uuid: familyTree.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handleParamsChange,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        deleteButtonHandler,
    }
}

export const FamilyTreeIndexPage: FC = () => {
    const { list, count, params, handleParamsChange, handlePageChange, refetchList, handleUserUuidChange, deleteButtonHandler } =
        usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `家系図一覧`, link: `/familyTree` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <TextField
                            id={'familyTreeName'}
                            label={'家系図名'}
                            value={params.familyTreeName}
                            onChange={(e) => handleParamsChange({ familyTreeName: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                        <CUserAutocomplete
                            label="登録ユーザ"
                            userUuid={params.userUuid}
                            onChangeAutocomplete={handleUserUuidChange}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録者</TableCell>
                                <TableCell>家系図名</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((familyTree: Schemas.FamilyTreeEntities) => (
                                <TableRow key={familyTree.uuid}>
                                    <TableCell>
                                        {familyTree.user && (
                                            <Link href={`/user/${familyTree.user.uuid}`} color={'primary'}>
                                                {familyTree.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/familyTree/${familyTree.uuid}`} color={'primary'}>
                                            {familyTree.name ? familyTree.name : '家系図名は登録されていません'}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => deleteButtonHandler(familyTree)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>
        </>
    )
}
