import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CUserUpdateDialog } from '~/components/functional/user/CUserUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type UserParameters = {
    page: number
    limit: number
    userName: string
    email: string
}

type NavigateParameters = {
    page: string
    limit: string
    userName?: string
    email?: string
}

export type UserPageProps = {}
const useUserPage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)

    const [params, setParams] = useState<UserParameters>({
        page: page,
        limit: limit,
        userName: query.get('userName') || '',
        email: query.get('email') || '',
    })
    const handleParamsChange = (options: { userName?: string; email?: string }) => {
        setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
    }

    const [editTarget, setEditTarget] = useState<Schemas.UserEntities>()

    // initial fetch
    const { data: listResponse, refetch: refetchUserList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminUserIndex({
                parameter: {
                    ...params,
                    userName: params.userName || undefined,
                    email: params.email || undefined,
                    withSnsAccount: true,
                },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = {
            page: page.toString(),
            limit: params.limit.toString(),
        }
        if (params.userName) navigateSearchParams.userName = params.userName
        if (params.email) navigateSearchParams.email = params.email
        navigate({
            pathname: '/user',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
        await refetchUserList()
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.UserEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminUserUpdateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.adminUserStore({ requestBody: dto })
            else await apiClient.adminUserUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchUserList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CUserUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        addButtonHandler,
        editButtonHandler,
        EditDialog,
        handlePageChange,
        refetchUserList,
        handleParamsChange,
    }
}

type LinkMenuListProps = {
    targetUser: Schemas.UserEntities
}

const LinkMenuList: FC<LinkMenuListProps> = ({ targetUser }) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const linkMenuOpen = Boolean(anchorEl)
    const handleClickLinks = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseLinks = () => {
        setAnchorEl(null)
    }
    const moveWidthUser = async (user: Schemas.UserEntities, pathname: string) => {
        await navigate({
            pathname: pathname,
            search: `?${createSearchParams({
                userUuid: user.uuid,
            })}`,
        })
    }

    return (
        <>
            <Button onClick={handleClickLinks}>リンク</Button>
            <Menu
                anchorEl={anchorEl}
                open={linkMenuOpen}
                onClose={handleCloseLinks}
                PaperProps={{ sx: { boxShadow: 'none', border: '1px solid #e0e0e0' } }}>
                <MenuItem onClick={() => moveWidthUser(targetUser, '/connect')}>つながりリスト</MenuItem>
                <MenuItem onClick={() => moveWidthUser(targetUser, '/connectTag')}>タグリスト</MenuItem>
                <MenuItem onClick={() => moveWidthUser(targetUser, '/grave')}>お墓</MenuItem>
                <MenuItem onClick={() => moveWidthUser(targetUser, '/memory')}>思い出</MenuItem>
            </Menu>
        </>
    )
}

export const UserPage: FC<UserPageProps> = () => {
    const {
        list,
        count,
        params,
        addButtonHandler,
        editButtonHandler,
        EditDialog,
        handlePageChange,
        refetchUserList,
        handleParamsChange,
    } = useUserPage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `ユーザ一覧`, link: `/user` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetchUserList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                                ユーザを追加
                            </Button>
                        </Box>
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <TextField
                            id={'userName'}
                            label={'名前'}
                            value={params.userName}
                            onChange={(e) => handleParamsChange({ userName: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                        <TextField
                            id={'email'}
                            label={'メールアドレス'}
                            value={params.email}
                            onChange={(e) => handleParamsChange({ email: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>携帯電話番号</TableCell>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>SNSアカウント接続</TableCell>
                                <TableCell>登録日</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((user: Schemas.UserEntities) => (
                                <TableRow key={user.uuid}>
                                    <TableCell>
                                        <Link href={`/user/${user.uuid}`} color={'primary'}>
                                            {user.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{user.mobilePhone}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.snsAccount.length > 0 ? 'あり' : 'なし'}</TableCell>
                                    <TableCell>{datetimeFormat(user.createdAt)}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(user)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <LinkMenuList targetUser={user} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
