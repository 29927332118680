import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminConnectUpdateDtoSchema, adminConnectUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

const useDialog = (isOpen: boolean, entity: Schemas.ConnectEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
        setValue,
    } = useForm<AdminConnectUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminConnectUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            email: entity?.email || '',
            ruby: entity?.ruby || '',
            sort: entity?.sort || 0,
            uploadPermit: entity?.uploadPermit || 0,
            afterPermit: entity?.afterPermit || 0,
            message: entity?.message || null,
            birthday: entity?.birthday || null,
            deathDay: entity?.deathDay || null,
            sei: entity?.sei || null,
            mei: entity?.mei || null,
            postalCode: entity?.postalCode || null,
            pref: entity?.pref || null,
            city: entity?.city || null,
            address: entity?.address || null,
            building: entity?.building || null,
            tel: entity?.tel || null,
            mobilePhone: entity?.mobilePhone || null,
            memo: entity?.memo || null,
            tags: entity?.tags.map((t: Schemas.ConnectTagEntities) => t.uuid) || undefined,
            fileUuid: entity?.file?.uuid || null,
        })
    }, [isOpen, entity])

    const [selectTags, setSelectTags] = useState<string[]>(entity?.tags.map((t) => t.uuid) || [])

    const handleChangeTags = (event: SelectChangeEvent<string[]>) => {
        if (Array.isArray(event.target.value)) {
            setValue('tags', event.target.value)
            setSelectTags(event.target.value)
        }
    }

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity?.file || undefined)

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }

    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(undefined)
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        selectTags,
        handleChangeTags,
        image,
        handleUploadFile,
        handleDeleteFile,
    }
}

export type CAdminConnectUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminConnectUpdateDto, uuid: string) => void
    entity: Schemas.ConnectEntities
    tags?: Schemas.ConnectTagEntities[]
}

export const CAdminConnectUpdateDialog: FC<CAdminConnectUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity, tags }) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        selectTags,
        handleChangeTags,
        image,
        handleUploadFile,
        handleDeleteFile,
    } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>つながりを編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Select
                            id={'tags'}
                            multiple={true}
                            value={selectTags}
                            onChange={handleChangeTags}
                            renderValue={(selected: string[]) => (
                                <Stack direction={'row'} spacing={1}>
                                    {(selected as string[]).map((value) => {
                                        const tag = tags?.find((t) => t.uuid === value)
                                        return <Chip label={tag?.name} />
                                    })}
                                </Stack>
                            )}>
                            {tags &&
                                tags.map((tag) => (
                                    <MenuItem key={tag.uuid} value={tag.uuid}>
                                        <ListItemText primary={tag.name} />
                                    </MenuItem>
                                ))}
                        </Select>

                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <TextField
                            id={'ruby'}
                            {...register('ruby')}
                            label={'フリガナ'}
                            error={!!errors.ruby}
                            helperText={errors.ruby?.message}
                        />
                        <TextField
                            id={'email'}
                            {...register('email')}
                            label={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            type={'email'}
                        />

                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'sort'}
                                required={true}
                                {...register('sort', {
                                    valueAsNumber: true,
                                })}
                                label={'表示順'}
                                error={!!errors.sort}
                                helperText={errors.sort?.message}
                                type={'number'}
                            />

                            <TextField
                                id={'uploadPermit'}
                                required={true}
                                {...register('uploadPermit', {
                                    valueAsNumber: true,
                                })}
                                label={'思い出/コメント書込権限'}
                                error={!!errors.uploadPermit}
                                helperText={errors.uploadPermit?.message}
                                type={'number'}
                            />
                            <TextField
                                id={'afterPermit'}
                                required={true}
                                {...register('afterPermit', {
                                    valueAsNumber: true,
                                })}
                                label={'没後管理権限'}
                                error={!!errors.afterPermit}
                                helperText={errors.afterPermit?.message}
                                type={'number'}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <CDatePicker
                                type={'date'}
                                label={'誕生日'}
                                control={control}
                                name={'birthday'}
                                error={!!errors.birthday}
                                helperText={errors.birthday?.message}
                            />

                            <CDatePicker
                                type={'date'}
                                label={'没日'}
                                control={control}
                                name={'deathDay'}
                                error={!!errors.deathDay}
                                helperText={errors.deathDay?.message}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'sei'}
                                {...register('sei')}
                                label={'姓'}
                                error={!!errors.sei}
                                helperText={errors.sei?.message}
                            />
                            <TextField
                                id={'mei'}
                                {...register('mei')}
                                label={'名'}
                                error={!!errors.mei}
                                helperText={errors.mei?.message}
                            />
                        </Stack>

                        <TextField
                            id={'postalCode'}
                            {...register('postalCode')}
                            label={'郵便番号'}
                            error={!!errors.postalCode}
                            helperText={errors.postalCode?.message}
                        />
                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'pref'}
                                {...register('pref')}
                                label={'都道府県'}
                                error={!!errors.pref}
                                helperText={errors.pref?.message}
                            />
                            <TextField
                                id={'city'}
                                {...register('city')}
                                label={'市区町村'}
                                error={!!errors.city}
                                helperText={errors.city?.message}
                            />
                        </Stack>
                        <TextField
                            id={'address'}
                            {...register('address')}
                            label={'丁目番地'}
                            error={!!errors.address}
                            helperText={errors.address?.message}
                        />
                        <TextField
                            id={'building'}
                            {...register('building')}
                            label={'ビル名・肩書等'}
                            error={!!errors.building}
                            helperText={errors.building?.message}
                        />

                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                id={'tel'}
                                {...register('tel')}
                                label={'電話番号'}
                                error={!!errors.tel}
                                helperText={errors.tel?.message}
                            />
                            <TextField
                                id={'mobilePhone'}
                                {...register('mobilePhone')}
                                label={'携帯電話番号'}
                                error={!!errors.mobilePhone}
                                helperText={errors.mobilePhone?.message}
                            />
                        </Stack>

                        <TextField
                            id={'memo'}
                            required={false}
                            {...register('memo')}
                            label={'メモ'}
                            error={!!errors.memo}
                            helperText={errors.memo?.message}
                            multiline={true}
                            rows={6}
                        />

                        <TextField
                            id={'message'}
                            required={false}
                            {...register('message')}
                            label={'遺言/メッセージ'}
                            error={!!errors.message}
                            helperText={errors.message?.message}
                            multiline={true}
                            rows={6}
                        />

                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />
                        {image?.path && (
                            <>
                                <Stack direction={'column'}>
                                    <img
                                        src={mediaUrl(image)}
                                        style={{ width: '100%', maxWidth: '200px', maxHeight: '200px' }}
                                        alt={image.filename}
                                    />
                                    <Button onClick={() => handleDeleteFile()}>削除</Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
