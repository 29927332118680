import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { AdminGiftDetailShippingStatusUpdateDtoSchema, adminGiftDetailShippingStatusUpdateDtoSchema } from '~/types/zodScheme'

export const useCAdminGiftPaymentStatusUpdateDialog = (isOpen: boolean, entity?: Schemas.GiftDetailEntities) => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid, errors },
        setValue,
        reset,
        control,
    } = useForm<AdminGiftDetailShippingStatusUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminGiftDetailShippingStatusUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            shippingStatus: entity?.shippingStatus || 0,
            deliveredAt: entity?.deliveredAt || null,
        })
    }, [isOpen, entity])

    const shippingStatusList = [
        { value: 0, label: '未出荷' },
        { value: 1, label: '配送準備中' },
        { value: 2, label: '配送済み' },
        { value: 3, label: '配送キャンセル' },
        { value: 4, label: '配送出戻り' },
        { value: 9, label: '注文キャンセル' },
    ]

    const onChangeShippingStatus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const shippingStatus = shippingStatusList.find((p) => p.value === Number(event.target.value))
        if (shippingStatus) setValue('shippingStatus', shippingStatus.value)
    }

    const shippingStatusFilterList = (shippingStatus: number | undefined) => {
        // 基本的に配送ステータスはいつでも何にでも変更できる
        // 例外として注文キャンセルの場合は変更できない。
        if (shippingStatus === 9) return shippingStatusList.filter((l) => l.value === 9)

        return shippingStatusList
    }

    return {
        register,
        handleSubmit,
        isSubmitting,
        isValid,
        errors,
        control,
        shippingStatusFilterList,
        onChangeShippingStatus,
    }
}

export type CAdminGiftDetailShippingStatusUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminGiftDetailShippingStatusUpdateDto, uuid: string) => void
    entity?: Schemas.GiftDetailEntities
}

export const CAdminGiftDetailShippingStatusUpdateDialog: FC<CAdminGiftDetailShippingStatusUpdateDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const { register, handleSubmit, isSubmitting, isValid, errors, control, shippingStatusFilterList, onChangeShippingStatus } =
        useCAdminGiftPaymentStatusUpdateDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>配送状況を変更する</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'shippingStatus'}
                            required={true}
                            {...register('shippingStatus')}
                            label={'配送状況'}
                            select={true}
                            onChange={(val) => {
                                onChangeShippingStatus(val)
                            }}
                            defaultValue={entity?.shippingStatus || 0}
                            helperText="キャンセルで保存すると変更出来なくなります。ご注意ください">
                            {shippingStatusFilterList(entity?.shippingStatus).map((nt) => (
                                <MenuItem key={nt.value} value={nt.value}>
                                    {nt.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <CDatePicker
                            type={'date'}
                            label={'配送日時'}
                            control={control}
                            name={'deliveredAt'}
                            error={!!errors.deliveredAt}
                            helperText={errors.deliveredAt?.message}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity!.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
