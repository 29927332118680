import { useDisclosure } from '@chakra-ui/hooks'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminGiftDetailList } from '~/components/functional/gift/CAdminGiftDetailList'
import { CAdminGiftPaymentStatusUpdateDialog } from '~/components/functional/gift/CAdminGiftPaymentStatusUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, paymentStatusFormat, paymentTypeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const giftId = params.giftId

    // initial fetch
    const { data: gift, refetch } = useQuerySuspense(
        [giftId],
        async () => {
            if (!giftId) throw new Error()

            return await apiClient.adminGiftShow({
                parameter: {
                    uuid: giftId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const editButtonHandler = async () => {
        editDialogOnOpen()
    }
    const editDialogSubmitHandler = async (dto: Schemas.AdminGiftUpdateDto, uuid?: string): Promise<void> => {
        try {
            await apiClient.adminGiftUpdate({
                requestBody: dto,
                parameter: { uuid: uuid! },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminGiftPaymentStatusUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={gift}
        />
    )

    const breadcrumbList: BreadcrumbListType = [
        { title: `ギフト注文一覧`, link: `/gift` },
        { title: `ギフト注文詳細`, link: `` },
    ]

    const sendMailButtonHandler = async (): Promise<void> => {
        const res = await queueDialog({
            type: 'confirm',
            title: 'メール送信確認',
            text: `配送完了メールを送信しますか？メール配信は１度のみ実行可能です。`,
        })
        if (res) {
            try {
                await apiClient.adminGiftSendShippedMail({
                    parameter: { uuid: giftId! },
                })
                enqueueSnackbar(`送信しました`, { variant: 'success' })
                await refetch()
            } catch (e) {
                let message = '送信に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    return {
        breadcrumbList,
        gift,
        refetch,
        editButtonHandler,
        EditDialog,
        giftId,
        sendMailButtonHandler,
    }
}

export const GiftDetailPage: FC = () => {
    const { breadcrumbList, gift, refetch, editButtonHandler, EditDialog, giftId, sendMailButtonHandler } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'ギフト注文詳細'}>
                    <IconButton color="primary" onClick={() => refetch()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                    <Button sx={{ ml: 2 }} onClick={editButtonHandler} variant={'contained'}>
                        決済状態変更
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>登録日</TableCell>
                                <TableCell>{datetimeFormat(gift!.createdAt)}</TableCell>
                                <TableCell>更新日</TableCell>
                                <TableCell>{datetimeFormat(gift!.updatedAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>贈与元ユーザ</TableCell>
                                <TableCell>
                                    <Link href={`/user/${gift!.user?.uuid}`} color="primary">
                                        {gift!.user?.name}
                                    </Link>
                                </TableCell>
                                <TableCell>合計金額</TableCell>
                                <TableCell>{gift!.totalPrice.toLocaleString()}円</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>オーダー番号</TableCell>
                                <TableCell>{gift!.orderNo}</TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell>決済種別</TableCell>
                                <TableCell>{paymentTypeFormat(gift!.paymentType)}</TableCell>
                                <TableCell>決済状態</TableCell>
                                <TableCell>{paymentStatusFormat(gift!.paymentStatus)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>没後に届くギフトか</TableCell>
                                <TableCell>{gift!.isLastGift ? '没後に届くギフト' : '通常ギフト'}</TableCell>
                                <TableCell>配送予定日</TableCell>
                                <TableCell>
                                    {!gift!.isLastGift && gift!.deliveredAt ? dayjs(gift!.deliveredAt).format('YYYY-MM-DD') : ''}
                                </TableCell>
                            </TableRow>
                            {!gift!.isLastGift && (
                                <TableRow>
                                    <TableCell />
                                    <TableCell />

                                    <TableCell>配送完了メール</TableCell>
                                    <TableCell>
                                        {gift!.sendShippedMail ? (
                                            <>配送完了メール送信済みです</>
                                        ) : (
                                            <>
                                                まだ配送完了メールが送信されていません <br />
                                                <Button size="small" variant="contained" onClick={sendMailButtonHandler}>
                                                    配送完了メールを送信する
                                                </Button>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {giftId && <CAdminGiftDetailList giftUuid={giftId} />}
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
