import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminUserUpdateDtoSchema, adminUserUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

export const useCUserUpdateDialog = (isOpen: boolean, entity?: Schemas.UserEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminUserUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminUserUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            email: entity?.email || '',
            password: undefined,
            mobilePhone: entity?.mobilePhone || '',
            fileUuid: entity?.file?.uuid || null,
        })
    }, [isOpen, entity])

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity?.file || undefined)

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    const handleDeleteFile = () => {
        setValue('fileUuid', null)
        setImage(undefined)
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        image,
        handleUploadFile,
        handleDeleteFile,
    }
}

export type CUserUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminUserUpdateDto, uuid?: string) => void
    entity?: Schemas.UserEntities
}

export const CUserUpdateDialog: FC<CUserUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, image, handleUploadFile, handleDeleteFile } =
        useCUserUpdateDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>ユーザを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <TextField
                            id={'mobilePhone'}
                            required={true}
                            {...register('mobilePhone')}
                            label={'携帯電話番号'}
                            error={!!errors.mobilePhone}
                            helperText={errors.mobilePhone?.message}
                        />
                        <TextField
                            id={'email'}
                            required={true}
                            {...register('email')}
                            label={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            type={'email'}
                        />
                        <TextField
                            id={'password'}
                            required={false}
                            {...register('password')}
                            label={'パスワード'}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            type={'password'}
                        />
                    </Stack>

                    <CFileUpload
                        completionHandler={handleUploadFile}
                        label={'画像アップロード'}
                        error={!!errors.fileUuid}
                        helperText={errors.fileUuid?.message}
                    />
                    {image?.path && (
                        <>
                            <Stack direction={'column'}>
                                <img
                                    src={mediaUrl(image)}
                                    style={{ width: '100%', maxWidth: '200px', maxHeight: '200px' }}
                                    alt={image.filename}
                                />
                                <Button onClick={() => handleDeleteFile()}>削除</Button>
                            </Stack>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
