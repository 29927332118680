import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminImageMasterUpdateDtoSchema, adminImageMasterUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

const useCImageMasterUpdateDialog = (isOpen: boolean, entity?: Schemas.ImageMasterEntities) => {
    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminImageMasterUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminImageMasterUpdateDtoSchema),
    })

    const [selectFile, setSelectFile] = useState<Schemas.FileEntities | null>(entity?.file || null)

    // 初期化
    useEffect(() => {
        reset({
            fileUuid: entity?.file?.uuid || null,
        })
        setSelectFile(entity?.file || null)
    }, [isOpen, entity])

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setSelectFile(value)
        }
    }

    return {
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        handleUploadFile,
        selectFile,
    }
}

type CImageMasterUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminImageMasterUpdateDto, uuid?: string) => void
    entity?: Schemas.ImageMasterEntities
}

export const CAdminImageMasterUpdateDialog: FC<CImageMasterUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { handleSubmit, errors, isSubmitting, isValid, handleUploadFile, selectFile } = useCImageMasterUpdateDialog(
        isOpen,
        entity,
    )

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                画像マスタ({entity?.name}: {entity?.code})を編集
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <CFileUpload
                        completionHandler={handleUploadFile}
                        label={'画像アップロード'}
                        error={!!errors.fileUuid}
                        helperText={errors.fileUuid?.message}
                    />

                    <Grid container spacing={2} sx={{ mt: 0 }}>
                        {selectFile && (
                            <Grid item xs={3} sm={3} md={3} key={selectFile.uuid}>
                                <img
                                    src={mediaUrl(selectFile)}
                                    style={{ width: '100%', maxWidth: '200px', maxHeight: '150px', objectFit: 'contain' }}
                                    alt={selectFile.filename}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <LoadingButton
                    variant={'contained'}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={handleSubmit((dto) => onSubmit(dto, entity?.code))}>
                    保存
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
