import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { AdminInquiryCreateDtoSchema, adminInquiryCreateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

const useDialog = (isOpen: boolean) => {
    const apiClient = createApiClient()
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminInquiryCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminInquiryCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            userUuid: null,
            category: null,
            title: '',
            content: '',
            name: '',
            email: '',
            tel: null,
            memo: null,
        })
    }, [isOpen])

    const handleChangeUserUuid = async (uuid?: string) => {
        let user = undefined
        if (uuid) user = await apiClient.adminUserShow({ parameter: { uuid: uuid } })
        reset({
            name: user?.name,
            email: user?.email,
            userUuid: user?.uuid,
        })
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        handleChangeUserUuid,
        setValue,
    }
}

type dialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminInquiryCreateDto) => void
}

export const CInquiryCreateDialog: FC<dialogProps> = ({ isOpen, onClose, onSubmit }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, handleChangeUserUuid } = useDialog(isOpen)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>管理画面側発行の問い合わせを追加</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <CUserAutocomplete label="送信するユーザ" onChangeAutocomplete={(uuid) => handleChangeUserUuid(uuid)} />

                        <Stack direction="row" spacing={2}>
                            <TextField
                                id={'name'}
                                sx={{ width: '50%' }}
                                required={true}
                                {...register('name')}
                                label={'送信するユーザ名'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                id={'email'}
                                sx={{ width: '50%' }}
                                required={true}
                                {...register('email')}
                                label={'メールアドレス'}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>

                        {/*<TextField*/}
                        {/*    id={'tel'}*/}
                        {/*    required={false}*/}
                        {/*    {...register('tel')}*/}
                        {/*    label={'電話番号'}*/}
                        {/*    error={!!errors.tel}*/}
                        {/*    helperText={errors.tel?.message}*/}
                        {/*/>*/}

                        {/*<TextField*/}
                        {/*    id={'category'}*/}
                        {/*    required={false}*/}
                        {/*    {...register('category')}*/}
                        {/*    label={'カテゴリ'}*/}
                        {/*    error={!!errors.category}*/}
                        {/*    helperText={errors.category?.message}*/}
                        {/*/>*/}

                        <TextField
                            id={'title'}
                            required={true}
                            {...register('title')}
                            label={'件名'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                        />
                        <TextField
                            id={'content'}
                            required={true}
                            {...register('content')}
                            label={'内容'}
                            error={!!errors.content}
                            helperText={errors.content?.message}
                            multiline={true}
                            rows={6}
                        />

                        <TextField
                            id={'memo'}
                            required={false}
                            {...register('memo')}
                            label={'管理メモ'}
                            error={!!errors.memo}
                            helperText={errors.memo?.message}
                            multiline={true}
                            rows={3}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
