import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { AdminNewsCreateDtoSchema, adminNewsCreateDtoSchema } from '~/types/zodScheme'
import { NewsCategoryTypeList } from '~/utils/common'

export const useCNewsCreateDialog = (isOpen: boolean, entity?: Schemas.NewsEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
        reset,
        control,
    } = useForm<AdminNewsCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminNewsCreateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            newsType: entity?.newsType || 'news',
            title: entity?.title || '',
            detail: entity?.detail || '',
            startAt: entity?.startAt ? dayjs(entity.startAt).format('YYYY-MM-DD') : null,
            endAt: entity?.endAt ? dayjs(entity.endAt).format('YYYY-MM-DD') : null,
            publish: entity?.publish || false,
        })
    }, [isOpen, entity])

    const onChangeNewsType = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue('newsType', val.target.value)
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        onChangeNewsType,
    }
}

export type CNewsCreateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminNewsCreateDto, uuid?: string) => void
    entity?: Schemas.NewsEntities
}

export const CNewsCreateDialog: FC<CNewsCreateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, control, onChangeNewsType } = useCNewsCreateDialog(
        isOpen,
        entity,
    )

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>ニュースを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'newsType'}
                            required={true}
                            {...register('newsType')}
                            label={'ニュースタイプ'}
                            select={true}
                            onChange={(val) => {
                                onChangeNewsType(val)
                            }}
                            defaultValue={entity?.newsType ?? 'news'}>
                            {NewsCategoryTypeList.map((nt) => (
                                <MenuItem key={nt.value} value={nt.value}>
                                    {nt.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id={'title'}
                            required={true}
                            {...register('title')}
                            label={'タイトル'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                        />
                        <TextField
                            id={'detail'}
                            required={true}
                            {...register('detail')}
                            label={'内容'}
                            error={!!errors.detail}
                            helperText={errors.detail?.message}
                            multiline={true}
                            rows={6}
                        />

                        <Box display={'flex'} alignItems={'center'} sx={{ mt: 2 }}>
                            <CDatePicker
                                type={'date'}
                                label={'公開開始日'}
                                control={control}
                                name={'startAt'}
                                error={!!errors.startAt}
                                helperText={errors.startAt?.message}
                            />
                            <Typography>〜</Typography>
                            <CDatePicker
                                type={'date'}
                                label={'公開終了日'}
                                control={control}
                                name={'endAt'}
                                error={!!errors.endAt}
                                helperText={errors.endAt?.message}
                            />
                        </Box>

                        <CSwitch name={'publish'} control={control} label={'公開する'} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
