import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Card, CardActions, CardContent, Stack, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { AdminAdminUpdateDtoSchema, adminAdminUpdateDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const { me, setMe } = useAuthState()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        setValue,
    } = useForm<AdminAdminUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminAdminUpdateDtoSchema),
    })

    useEffect(() => {
        reset({
            name: me?.name || '',
            email: me?.email || '',
            password: undefined,
        })
    }, [me])

    const onSubmit = async (dto: Schemas.AdminAdminUpdateDto) => {
        try {
            const _me = await apiClient.adminAuthUpdateMe({ requestBody: dto })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            setMe(_me)
            setValue('password', undefined)
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        me,
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        onSubmit,
    }
}

export const ProfilePage: FC = () => {
    const { register, handleSubmit, errors, isSubmitting, isValid, onSubmit } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={[]}>
                <CSectionHeader title={'プロフィール'} />

                <Card sx={{ mt: 2, width: '500px' }} variant={'outlined'}>
                    <CardContent>
                        <Stack spacing={2}>
                            <TextField
                                id={'name'}
                                required={true}
                                {...register('name')}
                                label={'名前'}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                            <TextField
                                id={'email'}
                                required={true}
                                {...register('email')}
                                label={'メールアドレス'}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                type={'email'}
                            />
                            <TextField
                                id={'password'}
                                required={false}
                                {...register('password')}
                                label={'パスワード'}
                                error={!!errors.password}
                                helperText={
                                    errors.password?.message
                                        ? errors.password?.message
                                        : 'パスワードを変更する場合のみパスワードを入力してください'
                                }
                                type={'password'}
                            />
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            variant={'contained'}
                            loading={isSubmitting}
                            disabled={!isValid}
                            onClick={handleSubmit((dto) => onSubmit(dto))}>
                            保存
                        </LoadingButton>
                    </CardActions>
                </Card>
            </DefaultLayout>
        </>
    )
}
