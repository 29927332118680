import { useDisclosure } from '@chakra-ui/hooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Grid, Link, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { cloneDeep } from 'lodash-es'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminGiftMasterDescriptionUpdateDialog } from '~/components/functional/gift/CAdminGiftMasterDescriptionUpdateDialog'
import { CAdminGiftMasterUpdateDialog } from '~/components/functional/gift/CAdminGiftMasterUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const masterId: string = params.masterId!

    // initial fetch
    const { data: master, refetch } = useQuerySuspense(
        [masterId],
        async () => {
            if (!masterId) throw new Error()

            return await apiClient.adminGiftMasterShow({
                parameter: {
                    uuid: masterId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminGiftMasterUpdateDto, uuid?: string): Promise<void> => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminGiftMasterUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!masterId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminGiftMasterRemove({
                    parameter: {
                        uuid: masterId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/master/gift`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `ギフトマスタ一覧`, link: `/master/gift` },
        { title: `${master!.name}`, link: `/master/gift/${master!.uuid}` },
    ]

    const EditDialog = (
        <CAdminGiftMasterUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={master}
        />
    )

    const [description, setDescription] = useState<Schemas.GiftMasterDescription | undefined>(undefined)
    const {
        isOpen: editDescriptionDialogIsOpen,
        onOpen: editDescriptionDialogOnOpen,
        onClose: editDescriptionDialogOnClose,
    } = useDisclosure()
    const editDescriptionButtonHandler = async (value?: Schemas.GiftMasterDescription) => {
        setDescription(value)
        editDescriptionDialogOnOpen()
    }
    const editDescriptionDialogSubmitHandler = async (
        dto: Schemas.AdminGiftMasterDescriptionUpdateDto,
        uuid?: string,
    ): Promise<void> => {
        try {
            if (uuid) {
                await apiClient.adminGiftMasterUpdateDescription({
                    requestBody: dto,
                    parameter: { uuid: masterId, descriptionUuid: uuid },
                })
            } else {
                await apiClient.adminGiftMasterStoreDescription({
                    requestBody: dto,
                    parameter: { uuid: masterId },
                })
            }
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDescriptionDialogOnClose()
            setDescription(undefined)
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const deleteDescriptionButtonHandler = async (value: Schemas.GiftMasterDescription) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!masterId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminGiftMasterRemoveDescription({
                    parameter: {
                        uuid: masterId,
                        descriptionUuid: value.uuid,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                await refetch()
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }
    const moveDown = async (index: number) => {
        if (!master) return
        if (!master.description) return

        try {
            const list = cloneDeep(master.description)
            const position = list.splice(index, 1)
            const movePosition = list.splice(index, 1)

            if (!position.length) return
            if (!movePosition.length) return

            master.description.splice(index, 1, movePosition[0])
            master.description.splice(index + 1, 1, position[0])

            await apiClient.adminGiftMasterUpdateDescriptionSort({
                parameter: { uuid: masterId },
                requestBody: { description: master.description },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const moveUp = async (index: number) => {
        if (!master) return
        if (!master.description) return

        try {
            const list = cloneDeep(master.description)
            const position = list.splice(index, 1)
            const movePosition = list.splice(index - 1, 1)

            if (!position.length) return
            if (!movePosition.length) return

            master.description.splice(index - 1, 1, position[0])
            master.description.splice(index, 1, movePosition[0])

            await apiClient.adminGiftMasterUpdateDescriptionSort({
                parameter: { uuid: masterId },
                requestBody: { description: master.description },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDescriptionDialog = (
        <CAdminGiftMasterDescriptionUpdateDialog
            isOpen={editDescriptionDialogIsOpen}
            onClose={editDescriptionDialogOnClose}
            onSubmit={editDescriptionDialogSubmitHandler}
            entity={description}
        />
    )

    return {
        breadcrumbList,
        masterId,
        master,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,

        editDescriptionButtonHandler,
        deleteDescriptionButtonHandler,
        moveDown,
        moveUp,
        EditDescriptionDialog,
    }
}

export const MasterGiftDetailPage: FC = () => {
    const {
        breadcrumbList,
        master,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
        editDescriptionButtonHandler,
        deleteDescriptionButtonHandler,
        moveDown,
        moveUp,
        EditDescriptionDialog,
    } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'ギフトマスタ詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>

                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell width={'120px'}>商品名</TableCell>
                                <TableCell colSpan={3}>{master!.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>商品コード</TableCell>
                                <TableCell colSpan={3}>{master!.code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>価格</TableCell>
                                <TableCell colSpan={3}>{master!.price.toLocaleString()}円</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>画像</TableCell>
                                <TableCell colSpan={3}>
                                    <Grid container spacing={2} sx={{ mt: 0 }}>
                                        {master!.files.map((image) => (
                                            <Grid item xs={3} sm={3} md={3} key={image.uuid}>
                                                <Link href={mediaUrl(image)} target="_blank">
                                                    <img
                                                        src={mediaUrl(image)}
                                                        style={{
                                                            width: '100%',
                                                            maxWidth: '200px',
                                                            maxHeight: '150px',
                                                            objectFit: 'contain',
                                                        }}
                                                        alt={image.filename}
                                                    />
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>内容</TableCell>
                                <TableCell colSpan={3} sx={{ whiteSpace: 'pre-line' }}>
                                    {master!.content}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>開始</TableCell>
                                <TableCell>{datetimeFormat(master!.startAt)}</TableCell>
                                <TableCell>終了</TableCell>
                                <TableCell>{datetimeFormat(master!.endAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>公開</TableCell>
                                <TableCell colSpan={3}>{master!.publish ? '公開' : '非公開'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack sx={{ pt: 4 }}>
                    <CSectionHeader title={'ギフト説明'}>
                        <Button onClick={() => editDescriptionButtonHandler()} variant={'contained'}>
                            追加
                        </Button>
                    </CSectionHeader>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {master &&
                                    master.description &&
                                    master.description.map((description: Schemas.GiftMasterDescription, index: number) => (
                                        <TableRow key={description.uuid}>
                                            <TableCell sx={{ whiteSpace: 'pre-line' }}>
                                                {description.type === 'title' && <>{description.value}</>}
                                                {description.type === 'body' && <>{description.value}</>}
                                                {description.type === 'image' && (
                                                    <img
                                                        src={mediaUrl(description.file)}
                                                        style={{
                                                            width: '100%',
                                                            maxWidth: '200px',
                                                            maxHeight: '150px',
                                                            objectFit: 'contain',
                                                        }}
                                                        alt={description.file?.filename}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction={'row'} spacing={1}>
                                                    <Button
                                                        variant={'contained'}
                                                        color={'inherit'}
                                                        disabled={index === 0}
                                                        onClick={() => moveUp(index)}>
                                                        <KeyboardArrowUpIcon />
                                                    </Button>
                                                    <Button
                                                        variant={'contained'}
                                                        color={'inherit'}
                                                        disabled={index === master.description!.length - 1}
                                                        onClick={() => moveDown(index)}>
                                                        <KeyboardArrowDownIcon />
                                                    </Button>

                                                    <Button
                                                        variant={'text'}
                                                        onClick={() => editDescriptionButtonHandler(description)}>
                                                        編集
                                                    </Button>
                                                    <Button
                                                        variant={'text'}
                                                        color={'error'}
                                                        onClick={() => deleteDescriptionButtonHandler(description)}>
                                                        削除
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </DefaultLayout>

            {EditDialog}
            {EditDescriptionDialog}
        </>
    )
}
