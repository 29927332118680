import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminMemoryCommentUpdateDtoSchema, adminMemoryCommentUpdateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity?: Schemas.MemoryCommentEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminMemoryCommentUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminMemoryCommentUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            comment: entity?.comment || '',
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

export type CAdminMemoryCommentUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminMemoryCommentUpdateDto, uuid: string) => void
    entity?: Schemas.MemoryCommentEntities
}

export const CAdminMemoryCommentUpdateDialog: FC<CAdminMemoryCommentUpdateDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>お墓を編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'comment'}
                            required={true}
                            {...register('comment')}
                            label={'コメント'}
                            error={!!errors.comment}
                            helperText={errors.comment?.message}
                            multiline={true}
                            rows={6}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity!.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
