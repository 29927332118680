import { Autocomplete, TextField } from '@mui/material'
import React, { FC, useState } from 'react'

import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

interface UserAutocompleteDto {
    name: string
    uuid: string
}

const useComponent = (userUuid?: string) => {
    const apiClient = createApiClient()

    // for autocomplete
    const { data: listUsers } = useQuerySuspense(['listUsers'], async () => {
        return await apiClient.adminUserForAutocomplete({ parameter: {} })
    })
    let tmpAutocompleteUser: UserAutocompleteDto | null = null
    if (userUuid && listUsers && listUsers.length > 0)
        tmpAutocompleteUser = listUsers.find((u: UserAutocompleteDto) => u.uuid === userUuid) || null
    const [autocompleteUser, setAutocompleteUser] = useState<UserAutocompleteDto | null>(tmpAutocompleteUser)
    return {
        listUsers,
        autocompleteUser,
        setAutocompleteUser,
    }
}

export type CUserAutocompleteProps = {
    userUuid?: string
    label?: string
    onChangeAutocomplete: (uuid: string | undefined) => void
}

export const CUserAutocomplete: FC<CUserAutocompleteProps> = ({ userUuid, label, onChangeAutocomplete }) => {
    const { listUsers, autocompleteUser, setAutocompleteUser } = useComponent(userUuid)
    return (
        <>
            <Autocomplete
                sx={{ width: '200px' }}
                renderInput={(params) => <TextField {...params} label={label || 'ユーザ'} />}
                options={listUsers || []}
                getOptionLabel={(t: { uuid: string; name: string }) => t.name}
                onChange={(event, value: unknown) => {
                    if (value) {
                        onChangeAutocomplete((value as UserAutocompleteDto).uuid)
                        setAutocompleteUser(value as UserAutocompleteDto)
                    } else {
                        onChangeAutocomplete(undefined)
                        setAutocompleteUser(null)
                    }
                }}
                value={autocompleteUser}
            />
        </>
    )
}
