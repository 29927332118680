import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
// import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { AdminGiftMasterUpdateDtoSchema, adminGiftMasterUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useCGiftMasterUpdateDialog = (isOpen: boolean, entity?: Schemas.GiftMasterEntities) => {
    const apiClient = createApiClient()
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
        setValue,
    } = useForm<AdminGiftMasterUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminGiftMasterUpdateDtoSchema),
    })
    const { queueDialog } = useConfirmationDialog()

    const [selectFiles, setSelectFiles] = useState<Schemas.FileEntities[]>(entity?.files || [])

    const setFiles = useMemo(() => {
        return (
            <Grid container spacing={2} sx={{ mt: 0 }}>
                {selectFiles.map((image) => (
                    <Grid item xs={3} sm={3} md={3} key={image.uuid}>
                        <Badge color="error" badgeContent={'x'} onClick={() => handleRemoveFile(image)}>
                            <img
                                src={mediaUrl(image)}
                                style={{ width: '100%', maxWidth: '200px', maxHeight: '150px', objectFit: 'contain' }}
                                alt={image.filename}
                            />
                        </Badge>
                    </Grid>
                ))}
            </Grid>
        )
    }, [selectFiles])

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            content: entity?.content || '',
            code: entity?.code || null,
            price: entity?.price || 0,
            startAt: entity?.startAt ? dayjs(entity.startAt).format('YYYY-MM-DD') : null,
            endAt: entity?.endAt ? dayjs(entity.endAt).format('YYYY-MM-DD') : null,
            publish: entity?.publish || false,
            fileUuids: entity?.files.map((t) => t.uuid) || [],
        })
        setSelectFiles(entity?.files || [])
    }, [isOpen, entity])

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        console.log(value)
        if (value?.uuid) {
            // ファイルが追加されたので、現在のに追加してアレする
            const files = selectFiles
            files.push(value)
            setValue('fileUuids', selectFiles.map((f) => f.uuid) || [])
            // 知らんけど files じゃ更新されない。。
            setSelectFiles(files.map((f) => f))
        }
    }

    const handleRemoveFile = async (image: Schemas.FileEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '削除確認',
            text: `この画像を削除します`,
        })
        if (!res) return
        const files = selectFiles.filter((f) => f.uuid !== image.uuid)
        setValue('fileUuids', files.map((f) => f.uuid) || [])
        setSelectFiles(files)
    }

    const [uploading, setUploading] = useState(false)
    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        console.log(event)
        const files = event.target.files

        if (!files || files.length === 0) return

        setUploading(true)

        const formData = new FormData()
        formData.append('files', files[0] as Blob)

        try {
            console.log('@ts-expect-error')
            // @ts-expect-error
            const uploadedFiles = await apiClient.adminFileUploadUpload({ requestBody: formData })
            console.log(uploadedFiles)
            handleUploadFile(uploadedFiles.at(0))
        } catch (e) {
            // handleUploadFile(undefined, e)
        }

        setUploading(false)
    }

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        selectFiles,
        handleUploadFile,
        handleRemoveFile,

        uploading,
        onChange,
        setFiles,
    }
}

export type CGiftMasterUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminGiftMasterUpdateDto, uuid?: string) => void
    entity?: Schemas.GiftMasterEntities
}

export const CAdminGiftMasterUpdateDialog: FC<CGiftMasterUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        // selectFiles,
        // handleRemoveFile,
        uploading,
        onChange,
        setFiles,
    } = useCGiftMasterUpdateDialog(isOpen, entity)

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>ギフトマスタを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        id={'code'}
                        {...register('code')}
                        label={'商品コード'}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                    />
                    <TextField
                        id={'name'}
                        required={true}
                        {...register('name')}
                        label={'商品名'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <TextField
                        id={'price'}
                        required={true}
                        {...register('price', { valueAsNumber: true })}
                        label={'価格'}
                        type="number"
                        error={!!errors.price}
                        helperText={errors.price?.message}
                    />
                    <TextField
                        id={'content'}
                        {...register('content')}
                        label={'内容'}
                        error={!!errors.content}
                        helperText={errors.content?.message}
                        multiline={true}
                        rows={6}
                    />

                    <Box>
                        <FormControl error={!!errors.fileUuids}>
                            <Typography>画像アップロード</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {!!errors.fileUuids && <FormHelperText>画像が選択されていません</FormHelperText>}
                                <LoadingButton loading={uploading} component={'label'}>
                                    アップロード
                                    <input hidden type={'file'} onChange={onChange} />
                                </LoadingButton>
                            </Box>
                        </FormControl>
                        {setFiles}
                        {/*
                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロードssss'}
                            error={!!errors.fileUuids}
                            helperText={errors.fileUuids?.message}
                        />
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {selectFiles.map((image) => (
                                <Grid item xs={3} sm={3} md={3} key={image.uuid}>
                                    <Badge color="error" badgeContent={'x'} onClick={() => handleRemoveFile(image)}>
                                        <img
                                            src={mediaUrl(image)}
                                            style={{ width: '100%', maxWidth: '200px', maxHeight: '150px', objectFit: 'contain' }}
                                            alt={image.filename}
                                        />
                                    </Badge>
                                </Grid>
                            ))}
                        </Grid>
                        */}
                    </Box>

                    <Box display={'flex'} alignItems={'center'} sx={{ mt: 2 }}>
                        <CDatePicker
                            type={'date'}
                            label={'公開開始日'}
                            control={control}
                            name={'startAt'}
                            error={!!errors.startAt}
                            helperText={errors.startAt?.message}
                        />
                        <Typography>〜</Typography>
                        <CDatePicker
                            type={'date'}
                            label={'公開終了日'}
                            control={control}
                            name={'endAt'}
                            error={!!errors.endAt}
                            helperText={errors.endAt?.message}
                        />
                    </Box>

                    <CSwitch name={'publish'} control={control} label={'公開する'} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <LoadingButton
                    variant={'contained'}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                    保存
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
