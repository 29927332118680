import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Link, Pagination, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, getYoutubeThumbnailUrl, mediaUrl, openInNewTab, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    memoryUuid: string
}

const useList = (memoryUuid: string) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [params, setParams] = useState<Parameters>({ page: 1, limit: 12, memoryUuid })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params.limit, params.page, memoryUuid],
        async () => {
            return await apiClient.adminMemoryAssetIndex({ parameter: params })
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = (page: number) => {
        setParams((prevState) => ({
            ...prevState,
            page: page,
        }))
    }

    const deleteButtonHandler = async (asset: Schemas.MemoryAssetEntities) => {
        await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `返信を削除します`,
        }).then(async (confirmDelete) => {
            if (!confirmDelete) return

            const handleError = async (error: unknown) => {
                let message = '削除に失敗しました'
                if (error instanceof AxiosError) message = error.response?.data.message || error.message || message

                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
            return await apiClient
                .adminMemoryAssetRemove({
                    parameter: { uuid: asset.uuid ?? '' },
                })
                .then(async () => {
                    enqueueSnackbar(`削除しました`, { variant: 'success' })
                    await refetchList()
                })
                .catch(handleError)
        })
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        refetchList,
        deleteButtonHandler,
        handlePageChange,
    }
}

export type CAdminMemoryAssetListProps = {
    memoryUuid: string
}

export const CAdminMemoryAssetList: FC<CAdminMemoryAssetListProps> = ({ memoryUuid }) => {
    const { refetchList, count, params, handlePageChange, list, deleteButtonHandler } = useList(memoryUuid)
    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>メディア一覧</Typography>
                <Box>
                    <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Box>

            <Grid container spacing={2}>
                {list.map((asset: Schemas.MemoryAssetEntities) => (
                    <Grid item xs={3} sm={3} md={3} key={asset.uuid}>
                        <Card>
                            <CardActionArea onClick={() => openInNewTab(asset.url ? asset.url : mediaUrl(asset.file!))}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    sx={{ objectFit: 'contain' }}
                                    image={asset.url ? getYoutubeThumbnailUrl(asset.url) ?? '' : mediaUrl(asset.file!)}
                                    alt={asset.file?.filename}
                                />
                            </CardActionArea>
                            <CardContent>
                                <Box
                                    color={'gray'}
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography>{datetimeFormat(asset.createdAt)}</Typography>
                                    <IconButton color={'error'} onClick={() => deleteButtonHandler(asset)} size={'small'}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                <Link href={`/user/${asset.user?.uuid}`} color={'primary'}>
                                    {asset.user?.name}
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {count === 0 ? (
                <Typography sx={{ mt: 2 }}>メディアはありません</Typography>
            ) : (
                <Pagination
                    count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                    page={params.page}
                    onChange={(_, value) => handlePageChange(value)}
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{ mt: 2 }}
                />
            )}
        </>
    )
}
