import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminMemoryUpdateDialog } from '~/components/functional/memory/CAdminMemoryUpdateDialog'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { DefaultMaxListLimit, mediaUrl, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    memberUuid?: string
    memoryName: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    memberUuid?: string
    memoryName?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)

    const handleUserUuidChange = async (option: 'user' | 'member', uuid?: string) => {
        const key = option === 'user' ? 'userUuid' : 'memberUuid'
        await handleParamsChange({ [key]: uuid })
        await handlePageChange(1, { [key]: uuid })
    }

    const [params, setParams] = useState<Parameters>({
        page: page,
        limit: limit,
        userUuid: query.get('userUuid') || undefined,
        memoryName: query.get('memoryName') || '',
        memberUuid: query.get('memberUuid') || undefined,
    })

    const handleParamsChange = async (options: { userUuid?: string; memoryName?: string; memberUuid?: string }) => {
        await setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
    }

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminMemoryIndex({ parameter: { ...params, name: params.memoryName || undefined } })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    // navigation 変更と refetch
    const handlePageChange = async (page: number, options?: { userUuid?: string; memberUuid?: string }) => {
        params.page = page

        const navigateSearchParams: NavigateParameters = {
            page: params.page.toString(),
            limit: params.limit.toString(),
            userUuid: options?.userUuid || params.userUuid,
            memberUuid: options?.memberUuid || params.memberUuid,
            memoryName: params.memoryName,
        }

        const cleanedParams = Object.fromEntries(
            Object.entries(navigateSearchParams).filter(([_, value]) => value),
        ) as NavigateParameters

        navigate({
            pathname: '/memory',
            search: `?${createSearchParams(cleanedParams)}`,
        })

        await refetchList()
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.MemoryEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.MemoryEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminMemoryUpdateDto, uuid?: string) => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminMemoryUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminMemoryUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (memory: Schemas.MemoryEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminMemoryRemove({
                parameter: {
                    uuid: memory.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handleParamsChange,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const MemoryIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handleParamsChange,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `思い出一覧`, link: `/memory` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction={'row'} spacing={2}>
                        {/*<FormGroup sx={{ flexDirection: 'row' }}>*/}
                        {/*    {memoryCategoryList.map((category) => (*/}
                        {/*        <FormControlLabel*/}
                        {/*            control={*/}
                        {/*                <Checkbox*/}
                        {/*                    value={category.value}*/}
                        {/*                    onChange={handleCheckboxChange}*/}
                        {/*                    checked={params.categories.includes(category.value)}*/}
                        {/*                />*/}
                        {/*            }*/}
                        {/*            label={category.label}*/}
                        {/*            key={category.value}*/}
                        {/*        />*/}
                        {/*    ))}*/}
                        {/*</FormGroup>*/}
                        <TextField
                            id={'memoryName'}
                            label={'思い出名'}
                            value={params.memoryName}
                            onChange={(e) => handleParamsChange({ memoryName: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                        <CUserAutocomplete
                            label={'登録ユーザ'}
                            userUuid={params.userUuid}
                            onChangeAutocomplete={(uuid) => handleUserUuidChange('user', uuid)}
                        />
                        <CUserAutocomplete
                            label={'参加メンバー'}
                            userUuid={params.memberUuid}
                            onChangeAutocomplete={(uuid) => handleUserUuidChange('member', uuid)}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録者</TableCell>
                                <TableCell>画像</TableCell>
                                {/*<TableCell>カテゴリ</TableCell>*/}
                                <TableCell>思い出名</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((memory: Schemas.MemoryEntities) => (
                                <TableRow key={memory.uuid}>
                                    <TableCell>
                                        {memory.user && (
                                            <Link href={`/user/${memory.user.uuid}`} color={'primary'}>
                                                {memory.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {memory.file?.path && (
                                            <img
                                                src={mediaUrl(memory.file)}
                                                style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                                alt={memory.file.filename}
                                            />
                                        )}
                                    </TableCell>
                                    {/*<TableCell>{memoryCategoryFormat(memory.category)}</TableCell>*/}
                                    <TableCell>
                                        <Link href={`/memory/${memory.uuid}`} color={'primary'}>
                                            {memory.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(memory)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(memory)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
