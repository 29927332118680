import { useDisclosure } from '@chakra-ui/hooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminOfferingMasterDescriptionUpdateDialog } from '~/components/functional/offering/CAdminOfferingMasterDescriptionUpdateDialog'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useCAdminOfferingMasterDescriptionUpdateList = (refetch: () => void, entity?: Schemas.OfferingMasterEntities) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [descriptions, setDescriptions] = useState<Schemas.OfferingMasterDescription[]>(entity?.description || [])
    useEffect(() => {
        setDescriptions(entity?.description || [])
    }, [entity])

    const [description, setDescription] = useState<Schemas.OfferingMasterDescription | undefined>(undefined)
    const [descriptionIndex, setDescriptionIndex] = useState<number | undefined>(undefined)
    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editSubmitHandler = async (dto: Schemas.OfferingMasterDescription[]): Promise<void> => {
        // 変換
        const updateDto: Schemas.AdminOfferingMasterDescriptionUpdateDto[] = dto.map((d) => {
            return {
                type: d.type,
                value: d.value,
                file: d.file,
            }
        })

        if (!entity?.uuid) return
        try {
            await apiClient.adminOfferingMasterUpdateDescription({
                requestBody: updateDto,
                parameter: { uuid: entity?.uuid },
            })

            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
            setDescription(undefined)
            setDescriptionIndex(undefined)
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const editButtonHandler = (item?: Schemas.OfferingMasterDescription, index?: number) => {
        setDescription(item)
        setDescriptionIndex(index)
        editDialogOnOpen()
    }

    const moveUp = async (index: number) => {
        if (index === 0) return
        // あげ
        const item = descriptions[index]
        descriptions[index] = descriptions[index - 1]
        descriptions[index - 1] = item
        // 保存
        await editSubmitHandler(descriptions)
    }
    const moveDown = async (index: number) => {
        if (index === descriptions.length - 1) return
        // さげ
        const item = descriptions[index]
        descriptions[index] = descriptions[index + 1]
        descriptions[index + 1] = item
        // 保存
        await editSubmitHandler(descriptions)
    }

    const deleteButtonHandler = async (index: number) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '削除確認',
            text: `削除しますか`,
        })
        if (!res) return
        descriptions.splice(index, 1)
        // 保存
        await editSubmitHandler(descriptions)
    }

    const editDescriptionHandler = async (item: Schemas.AdminOfferingMasterDescriptionUpdateDto) => {
        // TODO: descriptions の index の箇所にアレする
        let items: Schemas.OfferingMasterDescription[]
        if (descriptionIndex) {
            // 更新
            items = descriptions.map((d, i) => {
                if (descriptionIndex === i) return item
                return d
            })
            setDescriptions(items)
        } else {
            // 追加
            items = descriptions.map((d) => d)
            items.push(item)
            setDescriptions(items)
        }
        // 保存
        await editSubmitHandler(items)
    }

    const EditDialog = (
        <CAdminOfferingMasterDescriptionUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDescriptionHandler}
            entity={description}
        />
    )

    return {
        descriptions,
        deleteButtonHandler,
        editButtonHandler,
        moveUp,
        moveDown,
        EditDialog,
    }
}

type CAdminOfferingMasterDescriptionUpdateListProps = {
    entity?: Schemas.OfferingMasterEntities
    refetch: () => void
}

export const CAdminOfferingMasterDescriptionUpdateList: FC<CAdminOfferingMasterDescriptionUpdateListProps> = ({
    entity,
    refetch,
}) => {
    const { descriptions, deleteButtonHandler, editButtonHandler, moveUp, moveDown, EditDialog } =
        useCAdminOfferingMasterDescriptionUpdateList(refetch, entity)

    return (
        <Stack sx={{ pt: 4 }}>
            <CSectionHeader title={'お供え物説明'}>
                <Button onClick={() => editButtonHandler()} variant={'contained'}>
                    追加
                </Button>
            </CSectionHeader>
            <TableContainer>
                <Table>
                    <TableBody>
                        {descriptions.map((description: Schemas.OfferingMasterDescription, index: number) => (
                            <TableRow key={description.type + index.toString()}>
                                <TableCell sx={{ whiteSpace: 'pre-line' }}>
                                    {description.type === 'title' && <>{description.value}</>}
                                    {description.type === 'body' && <>{description.value}</>}
                                    {description.type === 'image' && (
                                        <img
                                            src={mediaUrl(description.file)}
                                            style={{
                                                width: '100%',
                                                maxWidth: '200px',
                                                maxHeight: '150px',
                                                objectFit: 'contain',
                                            }}
                                            alt={description.file?.filename}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Stack direction={'row'} spacing={1}>
                                        <Button
                                            variant={'contained'}
                                            color={'inherit'}
                                            disabled={index === 0}
                                            onClick={() => moveUp(index)}>
                                            <KeyboardArrowUpIcon />
                                        </Button>
                                        <Button
                                            variant={'contained'}
                                            color={'inherit'}
                                            disabled={index === descriptions.length - 1}
                                            onClick={() => moveDown(index)}>
                                            <KeyboardArrowDownIcon />
                                        </Button>

                                        <Button variant={'text'} onClick={() => editButtonHandler(description)}>
                                            編集
                                        </Button>
                                        <Button variant={'text'} color={'error'} onClick={() => deleteButtonHandler(index)}>
                                            削除
                                        </Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {EditDialog}
        </Stack>
    )
}
