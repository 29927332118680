import { AppBar, Button, Divider, Link, ListItemText, Menu, MenuItem, MenuList, Toolbar, Typography } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useAuthState } from '~/store/auth'
import { createApiClient } from '~/utils/createApiClient'

const useGHeader = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { me, clearAuthData } = useAuthState()

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
    const menuButtonHandler = (e: HTMLElement) => {
        setMenuAnchorEl(e)
    }
    const menuCloseHandler = () => {
        setMenuAnchorEl(null)
    }

    const signOut = async () => {
        try {
            await apiClient.adminAuthAdminLogout()
        } catch (e) {
            console.error(e)
        }
        clearAuthData()
        menuCloseHandler()
        navigate('/login')
    }

    return {
        me,
        signOut,
        menuButtonHandler,
        menuAnchorEl,
        menuCloseHandler,
    }
}

export const GHeader: FC = () => {
    const { me, signOut, menuButtonHandler, menuAnchorEl, menuCloseHandler } = useGHeader()

    return (
        <>
            <AppBar position={'fixed'} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link href={'/'} underline="none" color={'white'}>
                            Online-Tomb
                        </Link>
                    </Typography>

                    {me && (
                        <>
                            {me.systemAdmin && (
                                <Link href="/admin" color={'inherit'} underline="none" fontSize="small">
                                    管理者
                                </Link>
                            )}
                            <Button
                                sx={{ ml: 2 }}
                                color={'inherit'}
                                onClick={(e) => {
                                    menuButtonHandler(e.currentTarget)
                                }}>
                                {me.name}
                            </Button>

                            <Menu open={!!menuAnchorEl} onClose={menuCloseHandler} anchorEl={menuAnchorEl}>
                                <MenuList dense>
                                    <MenuItem>
                                        <Link href={'/profile'} underline="none" color={'black'}>
                                            <ListItemText>プロフィール</ListItemText>
                                        </Link>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={signOut}>
                                        <ListItemText>ログアウト</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    )
}
