import { useDisclosure } from '@chakra-ui/hooks'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminOfferingOrderDetailList } from '~/components/functional/offering/CAdminOfferingOrderDetailList'
import { CAdminOfferingOrderPaymentStatusUpdateDialog } from '~/components/functional/offering/CAdminOfferingOrderPaymentStatusUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, paymentStatusFormat, paymentTypeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const orderId = params.orderId

    // initial fetch
    const { data: offeringOrder, refetch } = useQuerySuspense(
        [orderId],
        async () => {
            if (!orderId) throw new Error()

            return await apiClient.adminOfferingOrderShow({
                parameter: {
                    uuid: orderId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()
    const editButtonHandler = async () => {
        editDialogOnOpen()
    }
    const editDialogSubmitHandler = async (dto: Schemas.AdminOfferingOrderUpdateDto, uuid?: string): Promise<void> => {
        try {
            await apiClient.adminOfferingOrderUpdate({
                requestBody: dto,
                parameter: { uuid: uuid! },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminOfferingOrderPaymentStatusUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={offeringOrder}
        />
    )

    const breadcrumbList: BreadcrumbListType = [
        { title: `お供え物注文一覧`, link: `/offeringOrder` },
        { title: `お供え物注文詳細`, link: `` },
    ]

    return {
        breadcrumbList,
        offeringOrder,
        refetch,
        editButtonHandler,
        EditDialog,
        orderId,
    }
}

export const OfferingOrderDetailPage: FC = () => {
    const { breadcrumbList, offeringOrder, refetch, editButtonHandler, EditDialog, orderId } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'お供え物注文詳細'}>
                    <IconButton color="primary" onClick={() => refetch()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                    <Button
                        sx={{ ml: 2 }}
                        onClick={editButtonHandler}
                        variant={'contained'}
                        disabled={offeringOrder!.paymentStatus === 9 || offeringOrder!.totalPrice === 0}>
                        決済状態変更
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>登録日</TableCell>
                                <TableCell>{datetimeFormat(offeringOrder!.createdAt)}</TableCell>
                                <TableCell>更新日</TableCell>
                                <TableCell>{datetimeFormat(offeringOrder!.updatedAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>購入ユーザ</TableCell>
                                <TableCell>
                                    <Link href={`/user/${offeringOrder!.user?.uuid}`} color="primary">
                                        {offeringOrder!.user?.name}
                                    </Link>
                                </TableCell>
                                <TableCell>合計金額</TableCell>
                                <TableCell>{offeringOrder!.totalPrice.toLocaleString()}円</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>オーダー番号</TableCell>
                                <TableCell>{offeringOrder!.orderNo}</TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell>決済種別</TableCell>
                                <TableCell>{paymentTypeFormat(offeringOrder!.paymentType)}</TableCell>
                                <TableCell>決済状態</TableCell>
                                <TableCell>{paymentStatusFormat(offeringOrder!.paymentStatus)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>予約表示か</TableCell>
                                <TableCell>{offeringOrder!.reserve ? '予約表示' : '即表示'}</TableCell>
                                <TableCell>予約時間</TableCell>
                                <TableCell>
                                    {offeringOrder!.reserve && offeringOrder!.reservationAt
                                        ? datetimeFormat(offeringOrder!.reservationAt)
                                        : '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>対象お墓</TableCell>
                                <TableCell>
                                    <Link href={`/grave/${offeringOrder!.grave.uuid}`} color="primary">
                                        {offeringOrder!.grave.name}
                                    </Link>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell>対象ユーザ</TableCell>
                                <TableCell>
                                    {offeringOrder!.sendUser && (
                                        <Link href={`/user/${offeringOrder!.sendUser.uuid}`}>{offeringOrder!.sendUser.name}</Link>
                                    )}
                                </TableCell>
                                <TableCell>対象つながり</TableCell>
                                <TableCell>
                                    {offeringOrder!.connect && (
                                        <Link href={`/connect/${offeringOrder?.connect.uuid}`}>
                                            {offeringOrder!.connect.name}
                                        </Link>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {orderId && <CAdminOfferingOrderDetailList offeringOrderUuid={orderId} />}
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
