import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminGraveUpdateDialog } from '~/components/functional/grave/CAdminGraveUpdateDialog'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { DefaultMaxListLimit, mediaUrl, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    graveName: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
    graveName?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)

    const handleUserUuidChange = async (userUuid: string | undefined) => {
        await handleParamsChange({ userUuid })
        await handlePageChange(1, { userUuid })
    }

    const [params, setParams] = useState<Parameters>({
        page: page,
        limit: limit,
        userUuid: query.get('userUuid') || undefined,
        graveName: query.get('graveName') || '',
    })

    const handleParamsChange = async (options: { userUuid?: string; graveName?: string }) => {
        await setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
    }

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminGraveIndex({
                parameter: { ...params, name: params.graveName ? params.graveName : undefined },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number, options?: { userUuid?: string | undefined }) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        if (options && 'userUuid' in options) {
            if (options.userUuid) navigateSearchParams.userUuid = options.userUuid
        } else if (params.userUuid) navigateSearchParams.userUuid = params.userUuid
        if (params.graveName) navigateSearchParams.graveName = params.graveName

        navigate({
            pathname: '/grave',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })

        await refetchList()
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.GraveEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.GraveEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminGraveUpdateDto, uuid?: string) => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminGraveUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminGraveUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (grave: Schemas.GraveEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminGraveRemove({
                parameter: {
                    uuid: grave.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handleParamsChange,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const GraveIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handleParamsChange,
        handlePageChange,
        refetchList,
        handleUserUuidChange,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `お墓一覧`, link: `/grave` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <TextField
                            id={'graveName'}
                            label={'お墓名'}
                            value={params.graveName}
                            onChange={(e) => handleParamsChange({ graveName: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                        <CUserAutocomplete
                            label="登録ユーザ"
                            userUuid={params.userUuid}
                            onChangeAutocomplete={handleUserUuidChange}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録者</TableCell>
                                <TableCell>画像</TableCell>
                                <TableCell>お墓名</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((grave: Schemas.GraveEntities) => (
                                <TableRow key={grave.uuid}>
                                    <TableCell>
                                        {grave.user && (
                                            <Link href={`/user/${grave.user.uuid}`} color={'primary'}>
                                                {grave.user.name}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {grave.file?.path && (
                                            <img
                                                src={mediaUrl(grave.file)}
                                                style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                                alt={grave.file.filename}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/grave/${grave.uuid}`} color={'primary'}>
                                            {grave.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(grave)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(grave)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
