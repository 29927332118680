import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { CDatePicker } from '~/components/common/cDatePicker/CDatePicker'
import { AdminSponsorClickExportSchema, adminSponsorClickExportSchema } from '~/types/zodScheme'
import { dayjsWithTimeZone } from '~/utils/common'

export const useCSponsorClickExport = (isOpen: boolean) => {
    const {
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
    } = useForm<AdminSponsorClickExportSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminSponsorClickExportSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            startDate: dayjsWithTimeZone().startOf('month').format('YYYY-MM-DD'),
            endDate: dayjsWithTimeZone().endOf('day').format('YYYY-MM-DD'),
        })
    }, [isOpen])

    return {
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
    }
}

export type SponsorClickExportParams = {
    startDate: string
    endDate: string
}
export type CSponsorClickExportProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (data: SponsorClickExportParams) => void
}

export const CSponsorClickExport: FC<CSponsorClickExportProps> = ({ isOpen, onClose, onSubmit }) => {
    const { handleSubmit, errors, isSubmitting, isValid, control } = useCSponsorClickExport(isOpen)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>クリックカウントレポートダウンロード</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Box display={'flex'} alignItems={'center'} sx={{ mt: 2 }}>
                            <CDatePicker
                                type={'date'}
                                label={'開始日'}
                                control={control}
                                name={'startDate'}
                                error={!!errors.startDate}
                                helperText={errors.startDate?.message}
                            />
                            <Typography>〜</Typography>
                            <CDatePicker
                                type={'date'}
                                label={'終了日'}
                                control={control}
                                name={'endDate'}
                                error={!!errors.endDate}
                                helperText={errors.endDate?.message}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto))}>
                        ダウンロード
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
