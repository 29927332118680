import { useDisclosure } from '@chakra-ui/react'
import {
    Box,
    Button,
    Link,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { type FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Parameter$adminSponsorGetAllSponsors } from '~/apis/apiClient'
import { Schemas } from '~/apis/types'
import { CSponsorClickExport, SponsorClickExportParams } from '~/components/functional/sponsor/CSponsorClickExport'
import { CAdminSponsorCreateUpdateDialog } from '~/components/functional/sponsor/CSponsorCreateUpdate'
import { CSponsorDisplay } from '~/components/functional/sponsor/CSponsorDisplay'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { dateFormat, DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    searchWord?: string
    isPublish?: string
}
type NavigateParameters = {
    page: string
    limit: string
    searchWord?: string
    isPublish?: string
}

const useSponsorIndexPage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()
    const query = useQueryString()
    const { control } = useForm()
    const [searchWord, setSearchWord] = useState<string | undefined>(query.get('searchWord') || undefined)

    const navigate = useNavigate()

    const isPublish = query.get('isPublish') ?? 'all'
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)

    const params: Parameters = {
        page,
        limit,
        searchWord,
        isPublish,
    }

    const addEditButtonHandler = (value?: Schemas.SponsorEntities) => {
        value ? setEditTarget(value) : setEditTarget(undefined)
        updateModalOnOpen()
    }

    const displayButtonHandler = (value: Schemas.SponsorEntities) => {
        setDisplayTarget(value)
        displayModalOnOpen()
    }

    const [filters, setFilters] = useState<Parameter$adminSponsorGetAllSponsors>({
        page,
        limit,
        searchWord,
        isPublish,
    })

    const { data: sponsorListResponse, refetch: refetchList } = useQuerySuspense(
        ['sponsorListResponse', filters],
        async () => {
            const r = await apiClient.adminSponsorGetAllSponsors({
                parameter: filters,
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const modalSubmitHandler = async (dto: Schemas.AdminCreateSponsorDto, uuid?: string) => {
        try {
            uuid
                ? await apiClient.adminSponsorUpdateSponsor({ requestBody: dto, parameter: { uuid } })
                : await apiClient.adminSponsorCreateSponsor({ requestBody: dto })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const deleteButtonHandler = async (sponsor: Schemas.SponsorEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return
        try {
            await apiClient.adminSponsorDeleteSponsor({
                parameter: {
                    uuid: sponsor.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const filterOptions = useMemo(() => {
        return [
            { title: '全て', value: 'all' },
            { title: '公開', value: '1' },
            { title: '非公開', value: '0' },
        ].map((i) => {
            return (
                <MenuItem key={i.value} value={i.value}>
                    {i.title}
                </MenuItem>
            )
        })
    }, [])

    const prepareCategories = (sponsor: Schemas.SponsorEntities): string => {
        return sponsor.categories.map((it) => it.title).join(', ')
    }

    const preparePrefectures = (sponsor: Schemas.SponsorEntities): string => {
        return sponsor.coveredPrefectures.map((it) => it.title).join(', ')
    }

    const { isOpen: exportModalIsOpen, onOpen: exportModalOnOpen, onClose: exportModalOnClose } = useDisclosure()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()
    const [editTarget, setEditTarget] = useState<Schemas.SponsorEntities>()

    const EditDialog = (
        <CAdminSponsorCreateUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={modalSubmitHandler}
            entity={editTarget}
        />
    )

    const { isOpen: displayModalIsOpen, onOpen: displayModalOnOpen, onClose: displayModalOnClose } = useDisclosure()
    const [displayTarget, setDisplayTarget] = useState<Schemas.SponsorEntities | undefined>()
    const DisplayDialog = <CSponsorDisplay isOpen={displayModalIsOpen} onClose={displayModalOnClose} entity={displayTarget} />

    const handlePageChange = async (page: number) => {
        setFilters((prevState) => ({
            ...prevState,
            searchWord,
            page,
        }))
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        navigate({
            pathname: '/sponsor',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
        setSearchWord('')
        await refetchList()
    }
    const handleSelectChange = async (e: string) => {
        setFilters((prevState) => ({
            ...prevState,
            searchWord,
            page,
            isPublish: e,
        }))

        params.isPublish = e
        const navigateSearchParams: NavigateParameters = {
            isPublish: params.isPublish,
            page: params.page.toString(),
            limit: params.limit.toString(),
        }
        navigate({
            pathname: '/sponsor',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
        await refetchList()
    }
    const sponsorTableData = useMemo(() => {
        return sponsorListResponse?.list?.map((sponsor) => {
            return (
                <TableRow key={sponsor.uuid}>
                    <TableCell>
                        <Link onClick={() => displayButtonHandler(sponsor)}>
                            <Typography sx={{ cursor: 'pointer' }}>{sponsor.name}</Typography>
                        </Link>
                    </TableCell>
                    <TableCell>{prepareCategories(sponsor)}</TableCell>
                    <TableCell>{preparePrefectures(sponsor)}</TableCell>
                    <TableCell>{dateFormat(sponsor.createdAt)}</TableCell>
                    <TableCell>{sponsor.publish ? '公開' : '非公開'}</TableCell>
                    <TableCell>{sponsor.clickCount}</TableCell>
                    <TableCell sx={{ pl: '0px' }}>
                        <Button color={'primary'} size={'small'} onClick={() => addEditButtonHandler(sponsor)}>
                            編集
                        </Button>
                        <Button color={'error'} size={'small'} sx={{ ml: 1 }} onClick={() => deleteButtonHandler(sponsor)}>
                            削除
                        </Button>
                    </TableCell>
                </TableRow>
            )
        })
    }, [sponsorListResponse])

    const downloadButtonHandler = async (data: SponsorClickExportParams) => {
        const res = await apiClient.adminSponsorExportClickCountsExcel({ parameter: data }, { responseType: 'blob' })
        const fileName = `koreshu_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
        saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), fileName)
        exportModalOnClose()
    }

    const ExportDialog = (
        <CSponsorClickExport isOpen={exportModalIsOpen} onClose={exportModalOnClose} onSubmit={downloadButtonHandler} />
    )

    return {
        count: sponsorListResponse?.count || 0,
        params,
        addEditButtonHandler,
        EditDialog,
        control,
        handlePageChange,
        searchWord,
        setSearchWord,
        DisplayDialog,
        sponsorTableData,
        ExportDialog,
        exportModalOnOpen,
        filterOptions,
        isPublish,
        handleSelectChange,
    }
}

export const SponsorIndexPage: FC = () => {
    const {
        count,
        params,
        addEditButtonHandler,
        EditDialog,
        handlePageChange,
        searchWord,
        setSearchWord,
        DisplayDialog,
        sponsorTableData,
        ExportDialog,
        exportModalOnOpen,
        filterOptions,
        isPublish,
        handleSelectChange,
    } = useSponsorIndexPage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: '事業者', link: '/sponsor' }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <Button sx={{ ml: 2 }} variant={'contained'} onClick={exportModalOnOpen}>
                                レポートDL
                            </Button>
                            <Button sx={{ ml: 2 }} variant={'contained'} onClick={() => addEditButtonHandler()}>
                                事業者を追加
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
                        <TextField
                            sx={{ width: '20rem' }}
                            id={'searchWord'}
                            label={'事業者名、カテゴリ、都道府県…'}
                            value={searchWord}
                            onChange={(e) => setSearchWord(e.target.value)}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                        <Stack mt={'0.5rem'} direction={'row'} alignItems={'center'} spacing={1.2}>
                            <Typography variant={'body2'}>公開/非公開</Typography>
                            <Select
                                value={isPublish}
                                defaultValue={'all'}
                                sx={{ width: '6.25rem', '& .MuiSelect-select': { py: 1.5 } }}
                                onChange={(e) => handleSelectChange(e.target.value)}>
                                {filterOptions}
                            </Select>
                        </Stack>
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '15%' }}>事業者名</TableCell>
                                <TableCell sx={{ width: '20%' }}>カテゴリ</TableCell>
                                <TableCell sx={{ width: '20%' }}>都道府県</TableCell>
                                <TableCell sx={{ width: '12%' }}>追加日</TableCell>
                                <TableCell sx={{ width: '8%' }}>公開設定</TableCell>
                                <TableCell sx={{ width: '8%' }}>クリックカウント</TableCell>
                                <TableCell sx={{ width: '15%' }}>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{sponsorTableData}</TableBody>
                    </Table>
                    {count === 0 ? (
                        <Typography sx={{ mt: 2, textAlign: 'center' }}>該当するスポンサーが見つかりません</Typography>
                    ) : (
                        <Pagination
                            count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                            page={params.page}
                            onChange={(_, value) => handlePageChange(value)}
                            showFirstButton={true}
                            showLastButton={true}
                            sx={{ mt: 2 }}
                        />
                    )}
                </Box>
            </DefaultLayout>

            {EditDialog}
            {DisplayDialog}
            {ExportDialog}
        </>
    )
}

export default SponsorIndexPage
