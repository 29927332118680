import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material'
import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { DefaultLayout } from '~/components/layout/Default'

export const MasterIndexPage: FC = () => {
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `マスタ一覧`, link: `/master` }]}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={'/master/gift'}>
                                <CardContent>
                                    <Typography variant={'h6'}>ギフト商品マスタ</Typography>
                                    <Typography sx={{ mt: 2 }}>ギフトで使用する商品のマスタを追加・編集・削除します。</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={'/master/offering'}>
                                <CardContent>
                                    <Typography variant={'h6'}>お供え物マスタ</Typography>
                                    <Typography sx={{ mt: 2 }}>お供えで使用する商品のマスタを追加・編集・削除します。</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={'/master/image'}>
                                <CardContent>
                                    <Typography variant={'h6'}>画像マスタ</Typography>
                                    <Typography sx={{ mt: 2 }}>
                                        お墓の初期選択時、文字編集時の画像および思い出日常の初期画像を変更します。
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={'/mailTemplate'}>
                                <CardContent>
                                    <Typography variant={'h6'}>メールテンプレート</Typography>
                                    <Typography sx={{ mt: 2 }}>メール送信時の文言を編集します。</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </DefaultLayout>
        </>
    )
}
