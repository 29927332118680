import { Box, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

export type CSectionHeaderProps = {
    children?: ReactNode
    title: string
}

export const CSectionHeader: FC<CSectionHeaderProps> = (props) => {
    const { title, children } = props

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>{title}</Typography>
                <Box>{children}</Box>
            </Box>
        </>
    )
}
