import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CSwitch } from '~/components/common/cSwitch/CSwitch'
import { useAuthState } from '~/store/auth'
import { AdminAdminUpdateDtoSchema, adminAdminUpdateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity: Schemas.AdminEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
    } = useForm<AdminAdminUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminAdminUpdateDtoSchema),
    })

    const { me } = useAuthState()

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            email: entity?.email || '',
            password: undefined,
            systemAdmin: entity?.systemAdmin || false,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        me,
    }
}

export type CAdminUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminAdminUpdateDto, uuid: string) => void
    entity: Schemas.AdminEntities
}

export const CAdminUpdateDialog: FC<CAdminUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, control, me } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>ユーザを{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <TextField
                            id={'email'}
                            required={true}
                            {...register('email')}
                            label={'メールアドレス'}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            type={'email'}
                        />
                        <TextField
                            id={'password'}
                            required={false}
                            {...register('password')}
                            label={'パスワード'}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            type={'password'}
                        />
                        {me && me.systemAdmin && <CSwitch name={'systemAdmin'} control={control} label="システム管理者" />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
