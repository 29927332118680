import { Box, Divider, Drawer, List, ListItemButton, ListItemText, ListSubheader, Toolbar } from '@mui/material'
import type { FC } from 'react'
import { useLocation } from 'react-router'

import { useAuthState } from '~/store/auth'

const useGDrawerMenu = () => {
    const { me } = useAuthState()
    const drawerWidth = 225
    const location = useLocation()

    const handleRouteMatch = (checkRoutePath: string): boolean => {
        return checkRoutePath === location.pathname
    }

    const handleRouteMatchStart = (checkRoutePath: string): boolean => {
        const locationSplit = location.pathname.split('/')
        if (locationSplit.length < 2) return false
        return location.pathname.indexOf(checkRoutePath, 0) === 0
    }

    return {
        me,
        drawerWidth,
        handleRouteMatch,
        handleRouteMatchStart,
    }
}

export const GDrawerMenu: FC = () => {
    const { me, drawerWidth, handleRouteMatch, handleRouteMatchStart } = useGDrawerMenu()

    return (
        <>
            {me && (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}>
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List
                            component="nav"
                            aria-label="nested-list-subheader"
                            subheader={
                                <ListSubheader id="nested-list-subheader" component="div">
                                    メニュー
                                </ListSubheader>
                            }>
                            <ListItemButton href="/" selected={handleRouteMatch('/')}>
                                <ListItemText primary={'ダッシュボード'} />
                            </ListItemButton>
                            <ListItemButton href="/inquiry" selected={handleRouteMatchStart('/inquiry')}>
                                <ListItemText primary={'問い合わせ'} />
                            </ListItemButton>
                            <ListItemButton href="/news" selected={handleRouteMatchStart('/news')}>
                                <ListItemText primary={'ニュース'} />
                            </ListItemButton>
                            <ListItemButton href="/sponsor" selected={handleRouteMatch('/sponsor')}>
                                <ListItemText primary="事業者" />
                            </ListItemButton>

                            <Divider />

                            <ListItemButton href="/user" selected={handleRouteMatchStart('/user')}>
                                <ListItemText primary={'ユーザ'} />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} href="/connect" selected={handleRouteMatchStart('/connect')}>
                                <ListItemText primary="つながりリスト" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 6 }} href="/connectTag" selected={handleRouteMatchStart('/connectTag')}>
                                <ListItemText primary="タグリスト" />
                            </ListItemButton>
                            {/*<ListItemButton sx={{ pl: 4 }} href="/familyTree" selected={handleRouteMatchStart('/familyTree')}>*/}
                            {/*    <ListItemText primary="家系図" />*/}
                            {/*</ListItemButton>*/}
                            <ListItemButton sx={{ pl: 4 }} href="/memory" selected={handleRouteMatchStart('/memory')}>
                                <ListItemText primary="思い出" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 6 }}
                                href="/memoryComment"
                                selected={handleRouteMatchStart('/memoryComment')}>
                                <ListItemText primary="コメント" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 6 }} href="/memoryAsset" selected={handleRouteMatchStart('/memoryAsset')}>
                                <ListItemText primary="画像" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} href="/grave" selected={handleRouteMatchStart('/grave')}>
                                <ListItemText primary="お墓" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 6 }} href="/graveComment" selected={handleRouteMatchStart('/graveComment')}>
                                <ListItemText primary="コメント" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} href="/gift" selected={handleRouteMatchStart('/gift')}>
                                <ListItemText primary="ギフト注文" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                href="/offeringOrder"
                                selected={handleRouteMatchStart('/offeringOrder')}>
                                <ListItemText primary="お供え物注文" />
                            </ListItemButton>

                            <Divider />

                            <ListItemButton href="/master" selected={handleRouteMatch('/master')}>
                                <ListItemText primary="マスタ" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} href="/master/gift" selected={handleRouteMatchStart('/master/gift')}>
                                <ListItemText primary="ギフトマスタ" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                href="/master/offering"
                                selected={handleRouteMatchStart('/master/offering')}>
                                <ListItemText primary="お供え物マスタ" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} href="/master/image" selected={handleRouteMatch('/master/image')}>
                                <ListItemText primary="画像マスタ" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                href="/master/sponsorCategory"
                                selected={handleRouteMatch('/master/sponsorCategory')}>
                                <ListItemText primary="事業者カテゴリマスタ" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} href="/mailTemplate" selected={handleRouteMatch('/mailTemplate')}>
                                <ListItemText primary="メールテンプレート" />
                            </ListItemButton>
                        </List>
                    </Box>
                </Drawer>
            )}
        </>
    )
}
