import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { uniq } from 'lodash-es'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CInquiryCreateDialog } from '~/components/functional/inquiry/CInquirycreateDialog'
import { CInquiryUpdateDialog } from '~/components/functional/inquiry/CInquiryUpdateDialog'
import { CInquiryUpdateStatusDialog } from '~/components/functional/inquiry/CInquiryUpdateStatusDialog'
import { CUserAutocomplete } from '~/components/functional/user/CUserAutocomplete'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import {
    datetimeFormat,
    DefaultMaxListLimit,
    InquiryStatusAlias,
    InquiryStatusArray,
    InquiryStatusFormat,
    InquiryStatusList,
    useQueryString,
    useQuerySuspense,
} from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
    title?: string
    startAt?: Date | null
    endAt?: Date | null
    status: InquiryStatusAlias[]
}

type NavigateParameter = {
    page: string
    limit: string
    userUuid?: string
    title?: string
    startAt?: string
    endAt?: string
    status?: string[]
}

const useInquiryPage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()

    // query から 決済状況を取得する
    let queryStatusList: InquiryStatusAlias[] = []
    query.getAll('status').forEach((tempStatus) => {
        const status = Number(tempStatus) as InquiryStatusAlias
        if (InquiryStatusArray.includes(status)) queryStatusList.push(status as InquiryStatusAlias)
        queryStatusList = uniq(queryStatusList)
    })

    const [params, setParams] = useState<Parameters>({
        page: Number(query.get('page') || 1),
        limit: Number(query.get('limit') || DefaultMaxListLimit),
        userUuid: query.get('userUuid') || undefined,
        title: query.get('title') || undefined,
        startAt: query.get('startAt') ? dayjs(query.get('startAt'), 'YYYY-MM-DD').toDate() : null,
        endAt: query.get('endAt') ? dayjs(query.get('endAt'), 'YYYY-MM-DD').toDate() : null,
        status: queryStatusList,
    })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminInquiryIndex({
                parameter: {
                    ...params,
                    title: params.title ? params.title : undefined,
                    startAt: params.startAt ? dayjs(params.startAt).format('YYYY-MM-DD') : undefined,
                    endAt: params.endAt ? dayjs(params.endAt).format('YYYY-MM-DD') : undefined,
                },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (
        page: number,
        options?: {
            userUuid?: string | undefined
            startAt?: string | null
            endAt?: string | null
            status?: InquiryStatusAlias[]
        },
    ) => {
        params.page = page
        const queryParams: NavigateParameter = {
            page: params.page.toString(),
            limit: params.limit.toString(),
            status: options?.status?.map((s) => String(s)) ?? params.status.map((s) => String(s)),
        }
        if (params.title) queryParams.title = params.title
        if (options && 'userUuid' in options) {
            if (options.userUuid) queryParams.userUuid = options.userUuid
        } else if (params.userUuid) queryParams.userUuid = params.userUuid
        if (options && 'startAt' in options) {
            if (options.startAt) queryParams.startAt = options.startAt
        } else if (params.startAt) queryParams.startAt = dayjs(params.startAt).format('YYYY-MM-DD')
        if (options && 'endAt' in options) {
            if (options.endAt) queryParams.endAt = options.endAt
        } else if (params.endAt) queryParams.endAt = dayjs(params.endAt).format('YYYY-MM-DD')

        navigate({ pathname: '/inquiry', search: `?${createSearchParams(queryParams)}` })

        await refetchList()
    }

    const handleParamsChange = async (options: { userUuid?: string; title?: string }) => {
        await setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
        if (options.userUuid) await handlePageChange(1, { userUuid: options.userUuid })
    }

    const handleStartAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                startAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { startAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                startAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { startAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }

    const handleEndAtChange = async (value: Date | string | null, isKeyboard: boolean) => {
        // null は select (isKeyboard = false) でくる
        if (isKeyboard) {
            await setParams((prevState) => ({
                ...prevState,
                endAt: dayjs(value).toDate(),
            }))
            await handlePageChange(1, { endAt: dayjs(value).format('YYYY-MM-DD') })
        } else {
            await setParams((prevState) => ({
                ...prevState,
                endAt: value ? dayjs(value).toDate() : null,
            }))
            await handlePageChange(1, { endAt: value ? dayjs(value).format('YYYY-MM-DD') : null })
        }
    }

    const handleStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value) as InquiryStatusAlias
        let list = params.status
        if (InquiryStatusArray.includes(value)) {
            const incValue = value as InquiryStatusAlias
            if (list.includes(incValue)) list = list.filter((v) => v !== incValue)
            else list = [...list, incValue]
        }
        await setParams((prevState) => ({
            ...prevState,
            status: list,
        }))
        await handlePageChange(1, { status: list })
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.InquiryEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.InquiryEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminInquiryUpdateDto, uuid: string) => {
        try {
            await apiClient.adminInquiryUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CInquiryUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget!}
        />
    )

    // 状態変更
    const { isOpen: editStatusDialogIsOpen, onOpen: editStatusDialogOnOpen, onClose: editStatusDialogOnClose } = useDisclosure()

    const editStatusButtonHandler = async (inquiry: Schemas.InquiryEntities) => {
        setEditTarget(inquiry)
        editStatusDialogOnOpen()
    }

    const editStatusDialogSubmitHandler = async (dto: Schemas.AdminInquiryUpdateStatusDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`状態更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminInquiryUpdateStatus({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`状態更新しました`, { variant: 'success' })
            await refetchList()
            editStatusDialogOnClose()
        } catch (e) {
            let message = '状態更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: '状態更新時にエラーが発生しました',
                text: message,
            })
        }
    }

    const EditStatusDialog = (
        <CInquiryUpdateStatusDialog
            isOpen={editStatusDialogIsOpen}
            onClose={editStatusDialogOnClose}
            onSubmit={editStatusDialogSubmitHandler}
            entity={editTarget}
        />
    )

    // 追加
    const { isOpen: addDialogIsOpen, onOpen: addDialogOnOpen, onClose: addDialogOnClose } = useDisclosure()
    const addButtonHandler = () => {
        addDialogOnOpen()
    }
    const addSubmitHandler = async (dto: Schemas.AdminInquiryCreateDto) => {
        try {
            await apiClient.adminInquiryStore({ requestBody: dto })
            await refetchList()
            addDialogOnClose()
        } catch (e) {
            let message = '追加に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }
    const AddDialog = <CInquiryCreateDialog isOpen={addDialogIsOpen} onClose={addDialogOnClose} onSubmit={addSubmitHandler} />

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        editButtonHandler,
        EditDialog,
        refetchList,
        editStatusButtonHandler,
        EditStatusDialog,
        handleStatusChange,
        handleStartAtChange,
        handleEndAtChange,
        handleParamsChange,
        addButtonHandler,
        AddDialog,
    }
}

export const InquiryPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        editButtonHandler,
        EditDialog,
        refetchList,
        editStatusButtonHandler,
        EditStatusDialog,
        handleStatusChange,
        handleStartAtChange,
        handleEndAtChange,
        handleParamsChange,
        addButtonHandler,
        AddDialog,
    } = useInquiryPage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `問い合わせ一覧`, link: `/inquiry` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <Button sx={{ ml: 0 }} onClick={addButtonHandler}>
                                管理側発行の問い合わせを追加
                            </Button>
                            <IconButton sx={{ ml: 2 }} color="primary" onClick={() => refetchList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <FormGroup sx={{ flexDirection: 'row' }}>
                        {InquiryStatusList.map((inquiryStatus) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={inquiryStatus.value}
                                        onChange={handleStatusChange}
                                        checked={params.status.includes(inquiryStatus.value)}
                                    />
                                }
                                label={inquiryStatus.label}
                                key={inquiryStatus.value}
                            />
                        ))}
                    </FormGroup>

                    <Stack direction="row" spacing={2}>
                        <TextField
                            id={'title'}
                            label={'タイトル'}
                            value={params.title}
                            onChange={(e) => handleParamsChange({ title: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />

                        <CUserAutocomplete
                            label="送付ユーザ"
                            userUuid={params.userUuid}
                            onChangeAutocomplete={(uuid) => handleParamsChange({ userUuid: uuid })}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <DatePicker
                                    label={'登録日開始'}
                                    value={params.startAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val: Date | string | null, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleStartAtChange(keyboardInputValue, true)
                                        } else await handleStartAtChange(val, false)
                                    }}
                                />

                                <Typography>〜</Typography>

                                <DatePicker
                                    label={'登録日終了'}
                                    value={params.endAt}
                                    inputFormat={'YYYY/MM/DD'}
                                    disableMaskedInput={false}
                                    renderInput={(props) => <TextField sx={{ width: '150px' }} {...props} />}
                                    onChange={async (val: Date | string | null, keyboardInputValue) => {
                                        if (typeof keyboardInputValue !== 'undefined') {
                                            if (dayjs(keyboardInputValue, 'YYYY/MM/DD').isValid())
                                                await handleEndAtChange(keyboardInputValue, true)
                                        } else await handleEndAtChange(val, false)
                                    }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>登録日</TableCell>
                                <TableCell>名前</TableCell>
                                <TableCell>カテゴリ</TableCell>
                                <TableCell>タイトル</TableCell>
                                <TableCell>ステータス</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((inquiry: Schemas.InquiryEntities) => (
                                <TableRow key={inquiry.uuid}>
                                    <TableCell>{datetimeFormat(inquiry.createdAt)}</TableCell>
                                    <TableCell sx={{ minWidth: '150px' }}>
                                        {inquiry.user ? (
                                            <Link href={`/user/${inquiry.user.uuid}`}>{inquiry.name}</Link>
                                        ) : (
                                            <Typography>{inquiry.name}</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell>{inquiry.category}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'pre-line' }}>
                                        <Link href={`/inquiry/${inquiry.uuid}`} color={'primary'}>
                                            {inquiry.title}
                                        </Link>
                                    </TableCell>

                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{InquiryStatusFormat(inquiry.status)}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(inquiry)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button onClick={() => editStatusButtonHandler(inquiry)} size={'small'}>
                                            ステータス編集
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
            {EditStatusDialog}
            {AddDialog}
        </>
    )
}
