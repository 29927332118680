import { useDisclosure } from '@chakra-ui/hooks'
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CNewsCreateDialog } from '~/components/functional/news/CNewsCreateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, NewsTypeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useNewsDetailPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const newsId = params.newsId

    // initial fetch
    const { data: news, refetch } = useQuerySuspense(
        [newsId],
        async () => {
            if (!newsId) throw new Error()

            return await apiClient.adminNewsGetNews({
                parameter: {
                    uuid: newsId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminNewsCreateDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminNewsPutNews({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `「${news!.title}」を削除します`,
        })

        if (!newsId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminNewsRemoveNews({
                    parameter: {
                        uuid: newsId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/news`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `ニュース一覧`, link: `/news` },
        { title: `${news!.title}`, link: `/news/${news!.uuid}` },
    ]

    const EditDialog = (
        <CNewsCreateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={news}
        />
    )

    // 開催期間中は景品を削除させない
    const [canDelete, setCanDelete] = useState(false)

    useEffect(() => {
        if (!news!.publish) setCanDelete(true)
        else if (news!.startAt) setCanDelete(dayjs().isBefore(news!.startAt))
        else setCanDelete(false)
    }, [news])

    return {
        breadcrumbList,
        news,
        editButtonHandler,
        editDialogSubmitHandler,
        deleteButtonHandler,
        canDelete,
        EditDialog,
    }
}

export const NewsDetailPage: FC = () => {
    const { breadcrumbList, news, editButtonHandler, deleteButtonHandler, canDelete, EditDialog } = useNewsDetailPage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'ニュース詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>
                    <Tooltip title={'公開期間中は削除できません'} disableHoverListener={canDelete}>
                        <span>
                            <Button
                                onClick={deleteButtonHandler}
                                disabled={!canDelete}
                                color={'error'}
                                variant={'contained'}
                                sx={{ ml: 1 }}>
                                削除
                            </Button>
                        </span>
                    </Tooltip>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>カテゴリ</TableCell>
                                <TableCell colSpan={3}>{NewsTypeFormat(news!.newsType)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>タイトル</TableCell>
                                <TableCell colSpan={3}>{news!.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>内容</TableCell>
                                <TableCell colSpan={3} sx={{ whiteSpace: 'pre-line' }}>
                                    {news!.detail}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>公開期間開始</TableCell>
                                <TableCell>{datetimeFormat(news!.startAt)}</TableCell>
                                <TableCell>公開期間終了</TableCell>
                                <TableCell>{datetimeFormat(news!.endAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>公開</TableCell>
                                <TableCell colSpan={3}>
                                    {news!.publish && <Typography component={'span'}>公開する</Typography>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
