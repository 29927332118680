import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router'

import { Schemas } from '~/apis/types'
import { atomWithLocalStorage } from '~/utils/atomWithLocalStorage'

export type AdminToken = {
    accessToken?: string
    expire?: string
    isMFA?: boolean
}

const tokenInitialValue: AdminToken = {
    accessToken: '',
    expire: '',
    isMFA: undefined,
}

const adminTokenAtom = atomWithLocalStorage<AdminToken>('adminToken', tokenInitialValue)

const meAtom = atomWithLocalStorage<Schemas.AdminEntities | undefined>('admin', undefined)

export const useAuthState = () => {
    const [token, setToken] = useAtom(adminTokenAtom)
    const [me, setMe] = useAtom(meAtom)
    const navigate = useNavigate()

    const clearAuthData = () => {
        setToken(tokenInitialValue)
        setMe(undefined)
    }

    const isAuth = (isRedirect = true): boolean => {
        if (!me || !token || !token.accessToken || !token.expire) {
            if (isRedirect) navigate('/login')
            return false
        } else if (dayjs().isAfter(token.expire)) {
            if (isRedirect) navigate('/login')
            return false
        }
        return true
    }

    return {
        me,
        setMe,
        token,
        setToken,
        clearAuthData,
        isAuth,
    }
}
