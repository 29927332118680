import { useDisclosure } from '@chakra-ui/react'
import { Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CUserAddressUpdateDialog } from '~/components/functional/user/CUserAddressUpdateDialog'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { createApiClient } from '~/utils/createApiClient'

const useDetail = (userUuid: string) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [editTarget, setEditTarget] = useState<Schemas.UserAddressEntities>()

    // initial fetch
    const { data: address, refetch } = useQuery(
        ['/user/detail/address'],
        async () => {
            return await apiClient.adminUserShowAddress({ parameter: { uuid: userUuid } })
        },
        {
            onError: () => {
                enqueueSnackbar('住所の取得に失敗しました', { variant: 'error' })
            },
            suspense: true,
        },
    )

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = () => {
        setEditTarget(address)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminUserAddressUpdateDto) => {
        try {
            await apiClient.adminUserUpdateAddress({
                requestBody: dto,
                parameter: { uuid: userUuid },
            })
            await refetch()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CUserAddressUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        address,
        editButtonHandler,
        EditDialog,
    }
}

export type CUserAddressDetailProps = {
    userUuid: string
}

export const CUserAddressDetail: FC<CUserAddressDetailProps> = ({ userUuid }) => {
    const { address, editButtonHandler, EditDialog } = useDetail(userUuid)

    return (
        <>
            <CSectionHeader title="ユーザ住所">
                <Button onClick={editButtonHandler} variant={'contained'}>
                    住所を編集
                </Button>
            </CSectionHeader>

            {address ? (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>姓名</TableCell>
                            <TableCell colSpan={3}>
                                {address.sei} {address.mei}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>住所</TableCell>
                            <TableCell colSpan={3}>
                                {address.postalCode} {address.pref} {address.city}
                                {address.address} {address.building}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>電話番号</TableCell>
                            <TableCell>{address.tel}</TableCell>
                            <TableCell>携帯電話番号</TableCell>
                            <TableCell>{address.mobilePhone}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : (
                <Typography>住所の登録はありません</Typography>
            )}
            {EditDialog}
        </>
    )
}
