import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Container, Link, Stack, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { AdminLoginUserDtoSchema, adminLoginUserDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

const useLoginIndexPage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()
    const { setToken, setMe, isAuth } = useAuthState()

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<AdminLoginUserDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminLoginUserDtoSchema),
    })

    useEffect(() => {
        if (isAuth(false)) navigate('/')
        setValue('email', '')
        setValue('password', '')
    }, [])

    const loginHandler = async (dto: Schemas.AdminLoginUserDto) => {
        try {
            const res = await apiClient.adminAuthAdminLogin({ requestBody: dto })
            setToken(res)
            const me = await apiClient.adminAuthAdminMe()
            await setMe(me)
            navigate(`/`)
        } catch (e) {
            let message = 'ログインに失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        register,
        errors,
        handleSubmit,
        loginHandler,
        isSubmitting,
        isValid,
    }
}

export const LoginIndexPage: FC = () => {
    const { register, errors, handleSubmit, loginHandler, isSubmitting, isValid } = useLoginIndexPage()

    return (
        <>
            <Container maxWidth={'sm'} sx={{ my: 8 }}>
                <Stack spacing={3}>
                    <TextField
                        {...register('email')}
                        label={'メールアドレス'}
                        type={'email'}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />

                    <TextField
                        {...register('password')}
                        label={'パスワード'}
                        type={'password'}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                    />

                    <LoadingButton
                        onClick={handleSubmit(loginHandler)}
                        variant={'contained'}
                        disabled={!isValid}
                        loading={isSubmitting}>
                        ログイン
                    </LoadingButton>

                    <Link href="/reminder" sx={{ mt: 4, textAlign: 'center' }}>
                        パスワードを忘れた方はこちら
                    </Link>
                </Stack>
            </Container>
        </>
    )
}

export default LoginIndexPage
