import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    IconButton,
    Link,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    offeringOrderUuid: string
}

const useList = (offeringOrderUuid: string) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()

    const [params, setParams] = useState<Parameters>({ page: 1, limit: 10, offeringOrderUuid: offeringOrderUuid })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params, offeringOrderUuid],
        async () => {
            return await apiClient.adminOfferingOrderDetailIndex({ parameter: params })
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = (page: number) => {
        setParams((prevState) => ({
            ...prevState,
            page: page,
        }))
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
    }
}

type CAdminOfferingOrderDetailListProps = {
    offeringOrderUuid: string
}

export const CAdminOfferingOrderDetailList: FC<CAdminOfferingOrderDetailListProps> = ({ offeringOrderUuid }) => {
    const { list, count, params, handlePageChange, refetchList } = useList(offeringOrderUuid)

    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>お供え物一覧</Typography>
                <Box>
                    <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Box>

            <TableContainer sx={{ maxWidth: 'calc(100vw - 200px - 24px - 24px)' }}>
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>お供え物</TableCell>
                            <TableCell align="right">価格</TableCell>
                            <TableCell>横位置</TableCell>
                            <TableCell>縦位置</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((offeringOrderDetail: Schemas.OfferingOrderDetailEntities) => (
                            <TableRow key={offeringOrderDetail.uuid} hover>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    <Link href={`/master/offering/${offeringOrderDetail.offeringMaster.uuid}`}>
                                        {offeringOrderDetail.offeringMaster.name}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                                    {offeringOrderDetail.price.toLocaleString()}円
                                </TableCell>
                                <TableCell>{offeringOrderDetail.positionX}</TableCell>
                                <TableCell>{offeringOrderDetail.positionY}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {count === 0 ? (
                <Typography sx={{ mt: 2 }}>お供え物はありません</Typography>
            ) : (
                <Pagination
                    count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                    page={params.page}
                    onChange={(_, value) => handlePageChange(value)}
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{ mt: 2 }}
                />
            )}
        </>
    )
}
