import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminConnectTagUpdateDtoSchema, adminConnectTagUpdateDtoSchema } from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity?: Schemas.ConnectTagEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminConnectTagUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminConnectTagUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '',
            sort: entity?.sort || 0,
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

export type CAdminConnectTagUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminConnectTagUpdateDto, uuid?: string) => void
    entity?: Schemas.ConnectTagEntities
}

export const CAdminConnectUpdateDialog: FC<CAdminConnectTagUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>つながりを編集</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'名前'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />

                        <TextField
                            id={'sort'}
                            required={true}
                            {...register('sort', {
                                valueAsNumber: true,
                            })}
                            label={'表示順'}
                            error={!!errors.sort}
                            helperText={errors.sort?.message}
                            type={'number'}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
