import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CAdminImageMasterUpdateDialog } from '~/components/functional/master/CAdminImageMasterUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['/master/image'],
        async () => {
            return await apiClient.adminImageMasterIndex()
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.ImageMasterEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.ImageMasterEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminImageMasterUpdateDto, code?: string) => {
        if (!code) return
        try {
            await apiClient.adminImageMasterUpdate({ requestBody: dto, parameter: { code } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminImageMasterUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: listResponse || [],
        refetchList,
        editButtonHandler,
        EditDialog,
    }
}

export const MasterImageIndexPage: FC = () => {
    const { list, refetchList, editButtonHandler, EditDialog } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `画像マスタ一覧`, link: `/master/image` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                            <RefreshIcon />
                        </IconButton>
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>コード</TableCell>
                                <TableCell>画像</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((master: Schemas.ImageMasterEntities) => (
                                <TableRow key={master.code}>
                                    <TableCell>{master.name}</TableCell>
                                    <TableCell>{master.code}</TableCell>
                                    <TableCell>
                                        {master.file && (
                                            <Link href={mediaUrl(master.file)} target="_blank">
                                                <img
                                                    src={mediaUrl(master.file)}
                                                    style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                                    alt={master.file.filename}
                                                />
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(master)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {list.length === 0 && <Typography sx={{ px: 2, py: 2 }}>initDevを実行してください</Typography>}
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
