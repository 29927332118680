import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CAdminGiftDetailShippingStatusUpdateDialog } from '~/components/functional/gift/CAdminGiftDetailShippingStatusUpdateDialog'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { dateFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    giftUuid: string
}

const useList = (giftUuid: string) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [editTarget, setEditTarget] = useState<Schemas.GiftDetailEntities>()
    const [params, setParams] = useState<Parameters>({ page: 1, limit: 10, giftUuid: giftUuid })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params, giftUuid],
        async () => {
            return await apiClient.adminGiftDetailIndex({ parameter: params })
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = (page: number) => {
        setParams((prevState) => ({
            ...prevState,
            page: page,
        }))
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.GiftDetailEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminGiftDetailShippingStatusUpdateDto, commentUuid: string) => {
        try {
            await apiClient.adminGiftDetailUpdateShippingStatus({
                requestBody: dto,
                parameter: { uuid: commentUuid },
            })

            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminGiftDetailShippingStatusUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const downloadButtonHandler = async () => {
        const res = await apiClient.adminGiftDetailExportExcel({ parameter: { giftUuid: giftUuid } }, { responseType: 'blob' })
        const fileName = `ギフト配送情報_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
        saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), fileName)
    }

    const shippingStatusList = [
        { value: 0, label: '未出荷' },
        { value: 1, label: '配送準備中' },
        { value: 2, label: '配送済み' },
        { value: 3, label: '配送キャンセル' },
        { value: 4, label: '配送出戻り' },
        { value: 9, label: '注文キャンセル' },
    ]

    const shippingStatusFormat = (value: number) => {
        const shippingStatus = shippingStatusList.find((p) => p.value === Number(value))
        return shippingStatus ? shippingStatus.label : 'エラー'
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        editButtonHandler,
        EditDialog,
        refetchList,
        handlePageChange,
        shippingStatusFormat,
        downloadButtonHandler,
    }
}

export type CAdminGiftDetailListProps = {
    giftUuid: string
}

export const CAdminGiftDetailList: FC<CAdminGiftDetailListProps> = ({ giftUuid }) => {
    const {
        list,
        editButtonHandler,
        EditDialog,
        refetchList,
        count,
        params,
        handlePageChange,
        shippingStatusFormat,
        downloadButtonHandler,
    } = useList(giftUuid)

    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>ギフト一覧</Typography>
                <Box>
                    <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                    <Button color={'secondary'} sx={{ ml: 2 }} onClick={downloadButtonHandler} variant={'contained'}>
                        配送情報ダウンロード
                    </Button>
                </Box>
            </Box>

            <TableContainer sx={{ maxWidth: 'calc(100vw - 200px - 24px - 24px)' }}>
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>つながり名</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>つながり先ユーザ</TableCell>
                            <TableCell>ギフト</TableCell>
                            <TableCell align="right">価格</TableCell>
                            <TableCell align="right">送料</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>配送日</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>配送状況</TableCell>
                            <TableCell>操作</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>つながり住所</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>つながり先ユーザ住所</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((giftDetail: Schemas.GiftDetailEntities) => (
                            <TableRow key={giftDetail.uuid} hover>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{giftDetail.connect.name}</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{giftDetail.sendUser?.name}</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{giftDetail.giftMaster.name}</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                                    {giftDetail.price.toLocaleString()}円
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                                    {giftDetail.shippingCost.toLocaleString()}円
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{dateFormat(giftDetail.deliveredAt)}</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    {shippingStatusFormat(giftDetail.shippingStatus)}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    <Button
                                        disabled={giftDetail.shippingStatus === 9}
                                        onClick={() => editButtonHandler(giftDetail)}
                                        color={'primary'}
                                        size={'small'}>
                                        配送状況変更
                                    </Button>
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    {giftDetail.connect.postalCode && '〒'}
                                    {giftDetail.connect.postalCode}
                                    {giftDetail.connect.pref}
                                    {giftDetail.connect.city}
                                    {giftDetail.connect.address}
                                    {giftDetail.connect.building}
                                    {giftDetail.connect.sei}
                                    {giftDetail.connect.mei}
                                    {giftDetail.connect.mei && '様'}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    {giftDetail.sendUser?.address && (
                                        <>
                                            〒{giftDetail.sendUser?.address.postalCode}
                                            {giftDetail.sendUser?.address.pref}
                                            {giftDetail.sendUser?.address.city}
                                            {giftDetail.sendUser?.address.address}
                                            {giftDetail.sendUser?.address.building}
                                            {giftDetail.sendUser?.address.sei}
                                            {giftDetail.sendUser?.address.mei} 様
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {count === 0 ? (
                <Typography sx={{ mt: 2 }}>ギフトはありません</Typography>
            ) : (
                <Pagination
                    count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                    page={params.page}
                    onChange={(_, value) => handlePageChange(value)}
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{ mt: 2 }}
                />
            )}

            {EditDialog}
        </>
    )
}
