import { useDisclosure } from '@chakra-ui/hooks'
import { Button, Grid, Link, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminOfferingMasterDescriptionUpdateList } from '~/components/functional/offering/CAdminOfferingMasterDescriptionUpdateList'
import { CAdminOfferingMasterUpdateDialog } from '~/components/functional/offering/CAdminOfferingMasterUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, displayTimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const masterId: string = params.masterId!

    // initial fetch
    const { data: master, refetch } = useQuerySuspense(
        [masterId],
        async () => {
            if (!masterId) throw new Error()

            return await apiClient.adminOfferingMasterShow({
                parameter: {
                    uuid: masterId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminOfferingMasterUpdateDto, uuid?: string): Promise<void> => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminOfferingMasterUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!masterId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminOfferingMasterRemove({
                    parameter: {
                        uuid: masterId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/master/offering`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `お供え物マスタ一覧`, link: `/master/offering` },
        { title: `${master!.name}`, link: `/master/offering/${master!.uuid}` },
    ]

    const EditDialog = (
        <CAdminOfferingMasterUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={master}
        />
    )

    return {
        breadcrumbList,
        masterId,
        master,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
        refetch,
    }
}

export const MasterOfferingDetailPage: FC = () => {
    const { breadcrumbList, master, editButtonHandler, deleteButtonHandler, EditDialog, refetch } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'お供え物マスタ詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>

                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell width={'120px'}>商品名</TableCell>
                                <TableCell>{master!.name}</TableCell>
                                <TableCell>商品コード</TableCell>
                                <TableCell>{master!.code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>価格</TableCell>
                                <TableCell>{master!.price.toLocaleString()}円</TableCell>
                                <TableCell>表示時間</TableCell>
                                <TableCell>
                                    {master!.displayTime.toLocaleString()}分（{displayTimeFormat(master!.displayTime)}）
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>画像</TableCell>
                                <TableCell colSpan={3}>
                                    <Grid container spacing={2} sx={{ mt: 0 }}>
                                        {master!.file && (
                                            <Grid item xs={3} sm={3} md={3} key={master!.file.uuid}>
                                                <Link href={mediaUrl(master!.file)} target="_blank">
                                                    <img
                                                        src={mediaUrl(master!.file)}
                                                        style={{
                                                            width: '100%',
                                                            maxWidth: '200px',
                                                            maxHeight: '150px',
                                                            objectFit: 'contain',
                                                        }}
                                                        alt={master!.file.filename}
                                                    />
                                                </Link>
                                            </Grid>
                                        )}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>内容</TableCell>
                                <TableCell colSpan={3} sx={{ whiteSpace: 'pre-line' }}>
                                    {master!.content}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>開始</TableCell>
                                <TableCell>{datetimeFormat(master!.startAt)}</TableCell>
                                <TableCell>終了</TableCell>
                                <TableCell>{datetimeFormat(master!.endAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>公開</TableCell>
                                <TableCell colSpan={3}>{master!.publish ? '公開' : '非公開'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <CAdminOfferingMasterDescriptionUpdateList refetch={refetch} entity={master} />
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
