import { useDisclosure } from '@chakra-ui/react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CInquiryReplyUpdateDialog } from '~/components/functional/inquiry/CInquiryReplyUpdateDialog'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useCInquiryReplyList = (inquiryUuid: string, refetchHead: () => void) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [editTarget, setEditTarget] = useState<Schemas.InquiryReplyEntities>()
    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            return await apiClient.adminInquiryIndexReply({ parameter: { uuid: inquiryUuid } })
        },
        {
            onError: () => {
                enqueueSnackbar('返信リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )
    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.InquiryReplyEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminInquiryReplyCreateDto, replyUuid?: string) => {
        try {
            if (!replyUuid) await apiClient.adminInquiryStoreReply({ parameter: { uuid: inquiryUuid }, requestBody: dto })
            else {
                await apiClient.adminInquiryUpdateReply({
                    requestBody: dto,
                    parameter: { uuid: inquiryUuid, replyUuid: replyUuid },
                })
            }
            await refetchList()
            updateModalOnClose()
            await refetchHead()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async (replyUuid: string) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `返信を削除します`,
        })

        if (res) {
            try {
                await apiClient.adminInquiryRemoveReply({
                    parameter: {
                        uuid: inquiryUuid,
                        replyUuid: replyUuid,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                await refetchList()
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const EditDialog = (
        <CInquiryReplyUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: listResponse || [],
        addButtonHandler,
        editButtonHandler,
        EditDialog,
        refetchList,
        deleteButtonHandler,
    }
}

export type CInquiryReplyListProps = {
    inquiryUuid: string
    refetchHead: () => void
}

export const CInquiryReplyList: FC<CInquiryReplyListProps> = ({ inquiryUuid, refetchHead }) => {
    const { list, addButtonHandler, editButtonHandler, EditDialog, refetchList, deleteButtonHandler } = useCInquiryReplyList(
        inquiryUuid,
        refetchHead,
    )
    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>返信一覧</Typography>
                <Box>
                    <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                    <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                        返信を追加
                    </Button>
                </Box>
            </Box>

            {list.map((reply: Schemas.InquiryReplyEntities, idx: number) => (
                <Card
                    key={reply.uuid + '1'}
                    sx={{
                        boxShadow: 'none',
                        pl: reply.admin ? 4 : 0,
                        pr: reply.admin ? 0 : 4,
                        borderRadius: 0,
                        borderTop: idx === 0 ? 'none' : '1px solid #e0e0e0',
                    }}>
                    <CardContent>
                        <Box color={'gray'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography component={'span'}>{datetimeFormat(reply.createdAt)}</Typography>
                                {reply.admin ? (
                                    <Typography sx={{ ml: 2 }} component={'span'}>
                                        {reply.admin.name}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ ml: 2 }} component={'span'}>
                                        {reply.user ? reply.user.name : ''}
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <IconButton onClick={() => editButtonHandler(reply)} size={'small'}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color={'error'} onClick={() => deleteButtonHandler(reply.uuid)} size={'small'}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                            {reply.content}
                        </Typography>
                    </CardContent>
                </Card>
            ))}

            {EditDialog}
        </>
    )
}
