import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const familyTreeId: string = params.familyTreeId!

    // initial fetch
    const { data: familyTree, refetch } = useQuerySuspense(
        [familyTreeId],
        async () => {
            if (!familyTreeId) throw new Error()

            return await apiClient.adminFamilyTreeShow({
                parameter: {
                    uuid: familyTreeId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!familyTreeId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminFamilyTreeRemove({
                    parameter: {
                        uuid: familyTreeId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/familyTree`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `家系図一覧`, link: `/familyTree` },
        { title: `${familyTree?.name || '家系図詳細'}`, link: `` },
    ]

    return {
        breadcrumbList,
        familyTree,
        deleteButtonHandler,
        refetch,
    }
}

export const FamilyTreeDetailPage: FC = () => {
    const { breadcrumbList, familyTree, deleteButtonHandler, refetch } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'家系図詳細'}>
                    <IconButton color="primary" onClick={() => refetch()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 2 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>家系図名</TableCell>
                                <TableCell>{familyTree!.name}</TableCell>
                                <TableCell>登録ユーザ</TableCell>
                                <TableCell>
                                    {familyTree!.user && (
                                        <Link href={`/user/${familyTree!.user.uuid}`} color={'primary'}>
                                            {familyTree!.user.name}
                                        </Link>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography sx={{ mt: 2 }}>ここに家系図が表示される予定</Typography>
            </DefaultLayout>
        </>
    )
}
