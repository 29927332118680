import { Flex } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { AdminMemoryUpdateDtoSchema, adminMemoryUpdateDtoSchema } from '~/types/zodScheme'
import { mediaUrl } from '~/utils/common'

export const useCMemoryUpdateDialog = (isOpen: boolean, entity?: Schemas.MemoryEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
        control,
        setValue,
    } = useForm<AdminMemoryUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminMemoryUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            name: entity?.name || '日常',
            content: entity?.content || '',
            year: entity?.year || '',
            month: entity?.month || '',
            fileUuid: entity?.file?.uuid || undefined,
        })
        setImage(entity?.file || undefined)
    }, [isOpen, entity])

    const [image, setImage] = useState<Schemas.FileEntities | undefined>(entity?.file || undefined)

    const handleUploadFile = (value?: Schemas.FileEntities) => {
        if (value?.uuid) {
            setValue('fileUuid', value.uuid)
            setImage(value)
        }
    }
    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        control,
        handleUploadFile,
        image,
    }
}

export type CMemoryUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminMemoryUpdateDto, uuid?: string) => void
    entity?: Schemas.MemoryEntities
}

export const CAdminMemoryUpdateDialog: FC<CMemoryUpdateDialogProps> = ({ isOpen, onClose, onSubmit, entity }) => {
    const { register, handleSubmit, errors, isSubmitting, isValid, handleUploadFile, image } = useCMemoryUpdateDialog(
        isOpen,
        entity,
    )

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>思い出を{entity?.uuid ? '編集' : '追加'}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'name'}
                            required={true}
                            {...register('name')}
                            label={'思い出名'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <Flex justifyContent={'space-between'} gap={12}>
                            <TextField
                                id={'year'}
                                sx={{ width: '100%' }}
                                required={true}
                                {...register('year')}
                                label={'年'}
                                error={!!errors.year}
                                helperText={errors.year?.message}
                            />
                            <TextField
                                id={'month'}
                                required={false}
                                {...register('month')}
                                sx={{ width: '100%' }}
                                label={'月'}
                                error={!!errors.month}
                                helperText={errors.month?.message}
                            />
                        </Flex>
                        <TextField
                            id={'content'}
                            {...register('content')}
                            label={'内容'}
                            required
                            error={!!errors.content}
                            helperText={errors.content?.message}
                            multiline={true}
                            rows={6}
                        />

                        <CFileUpload
                            completionHandler={handleUploadFile}
                            label={'画像アップロード'}
                            error={!!errors.fileUuid}
                            helperText={errors.fileUuid?.message}
                        />

                        {image?.path && (
                            <img
                                src={mediaUrl(image)}
                                style={{ width: '100%', maxWidth: '200px', maxHeight: '200px' }}
                                alt={image.filename}
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
