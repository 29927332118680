import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminOfferingMasterUpdateDialog } from '~/components/functional/offering/CAdminOfferingMasterUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { dateFormat, DefaultMaxListLimit, displayTimeFormat, mediaUrl, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)
    const params: Parameters = { page: page, limit: limit }
    const [editTarget, setEditTarget] = useState<Schemas.OfferingMasterEntities>()

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params],
        async () => {
            const r = await apiClient.adminOfferingMasterIndex({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        navigate({
            pathname: '/master/offering',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.OfferingMasterEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminOfferingMasterUpdateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.adminOfferingMasterStore({ requestBody: dto })
            else await apiClient.adminOfferingMasterUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminOfferingMasterUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (offeringMaster: Schemas.OfferingMasterEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminOfferingMasterRemove({
                parameter: {
                    uuid: offeringMaster.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
        addButtonHandler,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const MasterOfferingIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchList,
        addButtonHandler,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `お供え物マスタ一覧`, link: `/master/offering` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                                お供え物を追加
                            </Button>
                        </Box>
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>画像</TableCell>
                                <TableCell>商品名</TableCell>
                                <TableCell>価格</TableCell>
                                <TableCell>表示時間</TableCell>
                                <TableCell>開始日時</TableCell>
                                <TableCell>終了日時</TableCell>
                                <TableCell>公開</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((offering: Schemas.OfferingMasterEntities) => (
                                <TableRow key={offering.uuid}>
                                    <TableCell>
                                        {offering.file && (
                                            <img
                                                src={mediaUrl(offering.file)}
                                                style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                                alt={offering.file.filename}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/master/offering/${offering.uuid}`} color={'primary'}>
                                            {offering.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{offering.price.toLocaleString()}円</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayTimeFormat(offering.displayTime)}</TableCell>
                                    <TableCell>{dateFormat(offering.startAt)}</TableCell>
                                    <TableCell>{dateFormat(offering.endAt)}</TableCell>
                                    <TableCell>{offering.publish ? '公開' : '非公開'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(offering)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(offering)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
