import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { type FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import {
    AdminSponsorCategoryMasterCreateUpdateDtoSchema,
    adminSponsorCategoryMasterCreateUpdateDtoSchema,
} from '~/types/zodScheme'

const useDialog = (isOpen: boolean, entity?: Schemas.SponsorCategoryMasterEntities) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<AdminSponsorCategoryMasterCreateUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminSponsorCategoryMasterCreateUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            title: entity?.title || '',
            titleKana: entity?.titleKana || '',
        })
    }, [isOpen, entity])

    return {
        register,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
    }
}

export type CAdminSponsorCategoryCreateUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminCreateSponsorCategoryDto, uuid?: string) => void
    entity?: Schemas.SponsorCategoryMasterEntities
}

export const CAdminSponsorCategoryCreateUpdateDialog: FC<CAdminSponsorCategoryCreateUpdateDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const { register, handleSubmit, errors, isSubmitting, isValid } = useDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>カテゴリを追加</DialogTitle>
                <DialogContent>
                    <Stack gap={'0.5rem'}>
                        <TextField
                            id={'title'}
                            {...register('title')}
                            label={'カテゴリ名'}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                        />
                        <TextField
                            id={'titleKana'}
                            {...register('titleKana')}
                            label={'ふりがな(ひらがな)'}
                            error={!!errors.titleKana}
                            helperText={errors.titleKana?.message}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button onClick={onClose} sx={{ fontSize: '1rem' }}>
                        キャンセル
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        sx={{ fontSize: '1rem' }}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
