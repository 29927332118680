import { useDisclosure } from '@chakra-ui/hooks'
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CAdminGraveCommentList } from '~/components/functional/grave/CAdminGraveCommentList'
import { CAdminGraveUpdateDialog } from '~/components/functional/grave/CAdminGraveUpdateDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const usePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const graveId: string = params.graveId!

    // initial fetch
    const { data: grave, refetch } = useQuerySuspense(
        [graveId],
        async () => {
            if (!graveId) throw new Error()

            return await apiClient.adminGraveShow({
                parameter: {
                    uuid: graveId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminGraveUpdateDto, uuid?: string): Promise<void> => {
        if (!uuid) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '更新に失敗しました(uuid)',
            })
            return
        }
        try {
            await apiClient.adminGraveUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })

        if (!graveId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminGraveRemove({
                    parameter: {
                        uuid: graveId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/grave`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const breadcrumbList: BreadcrumbListType = [
        { title: `お墓一覧`, link: `/grave` },
        { title: `${grave!.name}`, link: `/grave/${grave!.uuid}` },
    ]

    const EditDialog = (
        <CAdminGraveUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={grave}
        />
    )

    return {
        breadcrumbList,
        graveId,
        grave,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const GraveDetailPage: FC = () => {
    const { breadcrumbList, grave, editButtonHandler, deleteButtonHandler, EditDialog, graveId } = usePage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'お墓詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>

                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>お墓名</TableCell>
                                <TableCell>{grave!.name}</TableCell>
                                <TableCell>画像</TableCell>
                                <TableCell>
                                    {grave!.file?.path && (
                                        <img
                                            src={mediaUrl(grave!.file)}
                                            style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                            alt={grave!.file.filename}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>更新日</TableCell>
                                <TableCell colSpan={3}>{datetimeFormat(grave!.updatedAt)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <CAdminGraveCommentList graveUuid={graveId} />
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
