import { useDisclosure } from '@chakra-ui/hooks'
import { Button, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CSectionHeader } from '~/components/common/cSectionHeader/CSectionHeader'
import { CInquiryReplyList } from '~/components/functional/inquiry/CInquiryReplyList'
import { CInquiryUpdateDialog } from '~/components/functional/inquiry/CInquiryUpdateDialog'
import { CInquiryUpdateStatusDialog } from '~/components/functional/inquiry/CInquiryUpdateStatusDialog'
import { BreadcrumbListType, DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, InquiryStatusFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useInquiryDetailPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const inquiryId = params.inquiryId

    // initial fetch
    const { data: inquiry, refetch } = useQuerySuspense(
        [inquiryId],
        async () => {
            if (!inquiryId) throw new Error()

            return await apiClient.adminInquiryShow({
                parameter: {
                    uuid: inquiryId,
                },
            })
        },
        {
            onError: () => {
                enqueueSnackbar('取得に失敗しました', { variant: 'error' })
            },
        },
    )

    // 通常変更
    const { isOpen: editDialogIsOpen, onOpen: editDialogOnOpen, onClose: editDialogOnClose } = useDisclosure()

    const editButtonHandler = async () => {
        editDialogOnOpen()
    }

    const editDialogSubmitHandler = async (dto: Schemas.AdminInquiryUpdateDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminInquiryUpdate({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`更新しました`, { variant: 'success' })
            await refetch()
            editDialogOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CInquiryUpdateDialog
            isOpen={editDialogIsOpen}
            onClose={editDialogOnClose}
            onSubmit={editDialogSubmitHandler}
            entity={inquiry!}
        />
    )

    const deleteButtonHandler = async () => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `「${inquiry!.title}」を削除します`,
        })

        if (!inquiryId) {
            enqueueSnackbar(`更新に失敗しました`, { variant: 'error' })
            return
        }

        if (res) {
            try {
                await apiClient.adminInquiryRemove({
                    parameter: {
                        uuid: inquiryId,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                navigate(`/inquiry`)
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    // 状態変更
    const { isOpen: editStatusDialogIsOpen, onOpen: editStatusDialogOnOpen, onClose: editStatusDialogOnClose } = useDisclosure()

    const editStatusButtonHandler = async () => {
        editStatusDialogOnOpen()
    }

    const editStatusDialogSubmitHandler = async (dto: Schemas.AdminInquiryUpdateStatusDto, uuid?: string) => {
        if (!uuid) {
            enqueueSnackbar(`状態更新に失敗しました`, { variant: 'error' })
            return
        }
        try {
            await apiClient.adminInquiryUpdateStatus({
                requestBody: dto,
                parameter: { uuid: uuid },
            })
            enqueueSnackbar(`状態更新しました`, { variant: 'success' })
            await refetch()
            editStatusDialogOnClose()
        } catch (e) {
            let message = '状態更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: '状態更新時にエラーが発生しました',
                text: message,
            })
        }
    }

    const EditStatusDialog = (
        <CInquiryUpdateStatusDialog
            isOpen={editStatusDialogIsOpen}
            onClose={editStatusDialogOnClose}
            onSubmit={editStatusDialogSubmitHandler}
            entity={inquiry}
        />
    )

    const ReplyList = <CInquiryReplyList inquiryUuid={inquiryId!} refetchHead={refetch} />

    const breadcrumbList: BreadcrumbListType = [
        { title: `問い合わせ一覧`, link: `/inquiry` },
        { title: `${inquiry!.title}`, link: `/inquiry/${inquiry!.uuid}` },
    ]

    return {
        breadcrumbList,
        inquiry,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
        editStatusButtonHandler,
        EditStatusDialog,
        ReplyList,
    }
}

export const InquiryDetailPage: FC = () => {
    const {
        breadcrumbList,
        inquiry,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
        editStatusButtonHandler,
        EditStatusDialog,
        ReplyList,
    } = useInquiryDetailPage()

    return (
        <>
            <DefaultLayout breadcrumbList={breadcrumbList} title={''}>
                <CSectionHeader title={'問い合わせ詳細'}>
                    <Button onClick={editButtonHandler} variant={'contained'}>
                        編集
                    </Button>
                    <Button onClick={deleteButtonHandler} color={'error'} variant={'contained'} sx={{ ml: 1 }}>
                        削除
                    </Button>
                </CSectionHeader>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>投稿日</TableCell>
                                <TableCell>{datetimeFormat(inquiry!.createdAt)}</TableCell>
                                <TableCell>カテゴリ</TableCell>
                                <TableCell>{inquiry!.category}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>タイトル</TableCell>
                                <TableCell colSpan={3}>{inquiry!.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>内容</TableCell>
                                <TableCell colSpan={3} sx={{ whiteSpace: 'pre-line' }}>
                                    {inquiry!.content}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>
                                    {inquiry!.user ? (
                                        <Link href={`/user/${inquiry!.user.uuid}`}>{inquiry!.name}</Link>
                                    ) : (
                                        <Typography>{inquiry!.name}</Typography>
                                    )}
                                </TableCell>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>{inquiry!.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>ステータス</TableCell>
                                <TableCell>
                                    {InquiryStatusFormat(inquiry!.status)}
                                    <Button sx={{ ml: 2 }} onClick={editStatusButtonHandler} variant={'contained'}>
                                        編集
                                    </Button>
                                </TableCell>
                                <TableCell>電話番号</TableCell>
                                <TableCell>{inquiry!.tel}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>メモ</TableCell>
                                <TableCell colSpan={3} sx={{ whiteSpace: 'pre-line' }}>
                                    {inquiry!.memo}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {ReplyList}
            </DefaultLayout>

            {EditDialog}
            {EditStatusDialog}
        </>
    )
}
