import * as z from 'zod'

// openapiからの自動生成に置き換え予定

export const adminLoginUserDtoSchema = z.object({
    email: z.string().email(),
    password: z.string(),
})
export type AdminLoginUserDtoSchema = z.infer<typeof adminLoginUserDtoSchema>

export const adminReminderCreateDtoSchema = z.object({
    email: z.string().email(),
})
export type AdminReminderCreateDtoSchema = z.infer<typeof adminReminderCreateDtoSchema>

export const adminReminderResetDtoSchema = z.object({
    token: z.string().uuid(),
    password: z.string().min(6),
})
export type AdminReminderResetDtoSchema = z.infer<typeof adminReminderResetDtoSchema>

export const adminNewsCreateDtoSchema = z.object({
    newsType: z.string(),
    title: z.string(),
    detail: z.string(),
    startAt: z.string().nullable(),
    endAt: z.string().nullable(),
    publish: z.boolean(),
})
export type AdminNewsCreateDtoSchema = z.infer<typeof adminNewsCreateDtoSchema>

export const adminUserUpdateDtoSchema = z.object({
    name: z.string(),
    email: z.string(),
    password: z.string().optional().nullable(),
    mobilePhone: z.string(),
    fileUuid: z.string().nullable(),
})
export type AdminUserUpdateDtoSchema = z.infer<typeof adminUserUpdateDtoSchema>

export const adminUserAddressUpdateDtoSchema = z.object({
    sei: z.string(),
    mei: z.string(),
    postalCode: z.string().max(7).nullable(),
    pref: z.string().nullable(),
    city: z.string().nullable(),
    address: z.string().nullable(),
    building: z.string().nullable(),
    tel: z.string().max(15).nullable(),
    mobilePhone: z.string().max(15).nullable(),
})
export type AdminUserAddressUpdateDtoSchema = z.infer<typeof adminUserAddressUpdateDtoSchema>

export const adminInquiryUpdateDtoSchema = z.object({
    category: z.string().optional().nullable(),
    title: z.string(),
    content: z.string(),
    name: z.string(),
    email: z.string().email().optional().nullable(),
    tel: z.string().optional().nullable(),
    memo: z.string().optional().nullable(),
})

export type AdminInquiryUpdateDtoSchema = z.infer<typeof adminInquiryUpdateDtoSchema>

export const adminInquiryCreateDtoSchema = z.object({
    userUuid: z.string().optional().nullable(),
    category: z.string().optional().nullable(),
    title: z.string().min(1),
    content: z.string().min(1),
    name: z.string().min(1),
    email: z.string().email().optional().nullable(),
    tel: z.string().optional().nullable(),
    memo: z.string().optional().nullable(),
})

export type AdminInquiryCreateDtoSchema = z.infer<typeof adminInquiryCreateDtoSchema>

export const adminInquiryUpdateStatusDtoSchema = z.object({
    status: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
})

export type AdminInquiryUpdateStatusDtoSchema = z.infer<typeof adminInquiryUpdateStatusDtoSchema>

export const adminInquiryReplyUpdateDtoSchema = z.object({
    content: z.string(),
})

export type AdminInquiryReplyUpdateDtoSchema = z.infer<typeof adminInquiryReplyUpdateDtoSchema>

export const adminAdminCreateDtoSchema = z.object({
    name: z.string(),
    email: z.string(),
    password: z.string(),
    systemAdmin: z.boolean(),
})
export type AdminAdminCreateDtoSchema = z.infer<typeof adminAdminCreateDtoSchema>

export const adminAdminUpdateDtoSchema = z.object({
    name: z.string(),
    email: z.string(),
    password: z.string().optional().nullable(),
    systemAdmin: z.boolean().optional(),
})
export type AdminAdminUpdateDtoSchema = z.infer<typeof adminAdminUpdateDtoSchema>

export const adminMailTemplateUpdateDtoSchema = z.object({
    title: z.string(),
    mailSubject: z.string(),
    mailBody: z.string(),
    smsBody: z.string(),
})
export type AdminMailTemplateUpdateDtoSchema = z.infer<typeof adminMailTemplateUpdateDtoSchema>

export const adminConnectUpdateDtoSchema = z.object({
    name: z.string(),
    ruby: z.string().nullable(),
    email: z.string().nullable(),
    sort: z.number(),
    uploadPermit: z.number().min(0).max(1),
    afterPermit: z.number().min(0).max(2),
    message: z.string().nullable(),
    birthday: z.string().nullable(),
    deathDay: z.string().nullable(),
    sei: z.string().nullable(),
    mei: z.string().nullable(),
    postalCode: z.string().max(7).nullable(),
    pref: z.string().nullable(),
    city: z.string().nullable(),
    address: z.string().nullable(),
    building: z.string().nullable(),
    tel: z.string().max(15).nullable(),
    mobilePhone: z.string().max(15).nullable(),
    tags: z.array(z.string().uuid()).optional(),
    memo: z.string().nullable(),
    fileUuid: z.string().nullable(),
})
export type AdminConnectUpdateDtoSchema = z.infer<typeof adminConnectUpdateDtoSchema>

export const adminConnectTagCreateDtoSchema = z.object({
    userUuid: z.string(),
    name: z.string(),
    sort: z.number(),
})
export type AdminConnectTagCreateDtoSchema = z.infer<typeof adminConnectTagCreateDtoSchema>

export const adminConnectTagUpdateDtoSchema = z.object({
    name: z.string(),
    sort: z.number(),
})
export type AdminConnectTagUpdateDtoSchema = z.infer<typeof adminConnectTagUpdateDtoSchema>

export const adminGraveUpdateDtoSchema = z.object({
    name: z.string(),
    fileUuid: z.string().optional().nullable(),
    posthumousName: z.string().optional().nullable(),
    denomination: z.string().optional().nullable(),
})
export type AdminGraveUpdateDtoSchema = z.infer<typeof adminGraveUpdateDtoSchema>

export const adminGraveCommentUpdateDtoSchema = z.object({
    comment: z.string(),
})
export type AdminGraveCommentUpdateDtoSchema = z.infer<typeof adminGraveCommentUpdateDtoSchema>

export const adminMemoryUpdateDtoSchema = z.object({
    name: z.string().min(1),
    content: z.string().min(1),
    year: z.string().min(4).max(4),
    month: z.string().max(2),
    fileUuid: z.string().optional().nullable(),
})
export type AdminMemoryUpdateDtoSchema = z.infer<typeof adminMemoryUpdateDtoSchema>

export const adminMemoryCommentUpdateDtoSchema = z.object({
    comment: z.string(),
})
export type AdminMemoryCommentUpdateDtoSchema = z.infer<typeof adminMemoryCommentUpdateDtoSchema>

export const adminGiftMasterDescriptionUpdateDtoSchema = z.object({
    type: z.string(),
    value: z.string().min(1),
})
export type AdminGiftMasterDescriptionUpdateDtoSchema = z.infer<typeof adminGiftMasterDescriptionUpdateDtoSchema>
export const adminGiftMasterUpdateDtoSchema = z.object({
    name: z.string().min(1),
    content: z.string().nullable(),
    code: z.string().nullable().optional(),
    price: z.number(),
    startAt: z.string().nullable(),
    endAt: z.string().nullable(),
    publish: z.boolean(),
    fileUuids: z.array(z.string().uuid()),
})
export type AdminGiftMasterUpdateDtoSchema = z.infer<typeof adminGiftMasterUpdateDtoSchema>

export const adminGiftPaymentStatusUpdateDtoSchema = z.object({
    paymentStatus: z.number().min(0).max(9),
})
export type AdminGiftPaymentStatusUpdateDtoSchema = z.infer<typeof adminGiftPaymentStatusUpdateDtoSchema>

export const adminGiftDetailShippingStatusUpdateDtoSchema = z.object({
    shippingStatus: z.number().min(0).max(9),
    deliveredAt: z.string().nullable(),
})
export type AdminGiftDetailShippingStatusUpdateDtoSchema = z.infer<typeof adminGiftDetailShippingStatusUpdateDtoSchema>
export const adminOfferingMasterUpdateDtoSchema = z.object({
    name: z.string().min(1),
    content: z.string().nullable(),
    code: z.string().nullable().optional(),
    price: z.number(),
    startAt: z.string().nullable(),
    endAt: z.string().nullable(),
    publish: z.boolean(),
    displayTime: z.number(),
    fileUuid: z.string().nullable().optional(),
})
export type AdminOfferingMasterUpdateDtoSchema = z.infer<typeof adminOfferingMasterUpdateDtoSchema>

export const adminOfferingOrderPaymentStatusUpdateDtoSchema = z.object({
    paymentStatus: z.number().min(0).max(9),
})
export type AdminOfferingOrderPaymentStatusUpdateDtoSchema = z.infer<typeof adminOfferingOrderPaymentStatusUpdateDtoSchema>

export const adminImageMasterUpdateDtoSchema = z.object({
    fileUuid: z.string().nullable().optional(),
})
export type AdminImageMasterUpdateDtoSchema = z.infer<typeof adminImageMasterUpdateDtoSchema>

export const adminOfferingMasterDescriptionUpdateDtoSchema = z.object({
    type: z.string(),
    value: z.string().min(1, '1文字以上入力してください'),
})
export type AdminOfferingMasterDescriptionUpdateDtoSchema = z.infer<typeof adminOfferingMasterDescriptionUpdateDtoSchema>

export const adminSponsorCreateUpdateDtoSchema = z.object({
    name: z.string().min(1, '必須項目です'),
    nameKana: z.string().min(1, '必須項目です'),
    email: z.string().email('メールアドレスの形式で入力してください。').min(1, '必須項目です'),
    address: z.string().min(1, '必須項目です'),
    phoneNumber: z.string().min(1, '必須項目です'),
    siteUrl: z.string().nullable().optional(),
    bannerImageUuid: z.string().nullish(),
    publish: z.boolean(),
})
export type AdminSponsorCreateUpdateDtoSchema = z.infer<typeof adminSponsorCreateUpdateDtoSchema>

export const adminSponsorCategoryMasterCreateUpdateDtoSchema = z.object({
    title: z.string().min(1, '1文字以上入力してください'),
    titleKana: z.string().min(1, '1文字以上入力してください'),
})
export type AdminSponsorCategoryMasterCreateUpdateDtoSchema = z.infer<typeof adminSponsorCategoryMasterCreateUpdateDtoSchema>

export const adminSponsorClickExportSchema = z.object({
    startDate: z.string().min(1, '必須項目です'),
    endDate: z.string().min(1, '必須項目です'),
})

export type AdminSponsorClickExportSchema = z.infer<typeof adminSponsorClickExportSchema>
