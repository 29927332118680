import { useDisclosure } from '@chakra-ui/react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Link,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { uniq } from 'lodash-es'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CNewsCreateDialog } from '~/components/functional/news/CNewsCreateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import {
    datetimeFormat,
    DefaultMaxListLimit,
    NewsCategoryType,
    NewsCategoryTypeArray,
    NewsCategoryTypeList,
    NewsTypeFormat,
    useQueryString,
    useQuerySuspense,
} from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    title: string
    newsType: NewsCategoryType[]
}

type NavigateParameter = {
    page: string
    limit: string
    title?: string
    newsType?: NewsCategoryType[]
}

export type NewsPageProps = {}
const useNewsPage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()

    // query から ニュースタイプを取得する
    let queryNewsTypeList: NewsCategoryType[] = []
    query.getAll('newsType').forEach((tempStatus) => {
        if (NewsCategoryTypeArray.find((t) => t === tempStatus)) queryNewsTypeList.push(tempStatus as NewsCategoryType)
        queryNewsTypeList = uniq(queryNewsTypeList)
    })

    const [params, setParams] = useState<Parameters>({
        page: Number(query.get('page') || 1),
        limit: Number(query.get('limit') || DefaultMaxListLimit),
        title: query.get('title') || '',
        newsType: queryNewsTypeList,
    })

    // initial fetch
    const { data: newsList, refetch: refetch } = useQuerySuspense(
        ['newsList'],
        async () => {
            const r = await apiClient.adminNewsGetNewsList({
                parameter: {
                    ...params,
                    title: params.title || undefined,
                },
            })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (
        page: number,
        options?: {
            newsType?: NewsCategoryType[]
        },
    ) => {
        params.page = page
        const queryParams: NavigateParameter = {
            page: params.page.toString(),
            limit: params.limit.toString(),
            newsType: options?.newsType || params.newsType,
        }
        if (params.title) queryParams.title = params.title
        navigate({ pathname: '/news', search: `?${createSearchParams(queryParams)}` })

        await refetch()
    }

    const handleParamsChange = async (options: { title?: string }) => {
        await setParams((prevState) => ({
            ...prevState,
            ...options,
        }))
    }

    const handleNewsTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as NewsCategoryType
        let list = params.newsType
        if (NewsCategoryTypeArray.includes(value)) {
            if (list.includes(value)) list = list.filter((v) => v !== value)
            else list = [...list, value]
        }
        await setParams((prevState) => ({
            ...prevState,
            newsType: list,
        }))
        await handlePageChange(1, { newsType: list })
    }

    // 編集モーダルの表示状態
    const [editTarget, setEditTarget] = useState<Schemas.NewsEntities>()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.NewsEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminNewsCreateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.adminNewsPostNews({ requestBody: dto })
            else await apiClient.adminNewsPutNews({ requestBody: dto, parameter: { uuid } })
            await refetch()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CNewsCreateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const disabledButtonNews = (news: Schemas.NewsEntities): boolean => {
        // 非公開 または 開始日が未来
        if (!news.publish) return false
        else if (news.startAt) return dayjs().isAfter(news.startAt)
        return true
    }

    const deleteButtonHandler = async (news: Schemas.NewsEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `「${news.title}」を削除します`,
        })

        if (res) {
            try {
                await apiClient.adminNewsRemoveNews({
                    parameter: {
                        uuid: news.uuid,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                await refetch()
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    return {
        newsList: newsList?.list || [],
        newsCount: newsList?.count || 0,
        params,
        handleParamsChange,
        handleNewsTypeChange,
        handlePageChange,
        addButtonHandler,
        editButtonHandler,
        EditDialog,
        refetch,
        deleteButtonHandler,
        disabledButtonNews,
    }
}

export const NewsPage: FC<NewsPageProps> = () => {
    const {
        newsList,
        newsCount,
        params,
        handleParamsChange,
        handlePageChange,
        handleNewsTypeChange,
        addButtonHandler,
        editButtonHandler,
        EditDialog,
        refetch,
        deleteButtonHandler,
        disabledButtonNews,
    } = useNewsPage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `ニュース一覧`, link: `/news` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetch()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                                ニュースを追加
                            </Button>
                        </Box>
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <FormGroup sx={{ flexDirection: 'row' }}>
                            {NewsCategoryTypeList.map((newsType) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={newsType.value}
                                            onChange={handleNewsTypeChange}
                                            checked={params.newsType.includes(newsType.value)}
                                        />
                                    }
                                    label={newsType.label}
                                    key={newsType.value}
                                />
                            ))}
                        </FormGroup>

                        <TextField
                            id={'title'}
                            label={'タイトル'}
                            value={params.title}
                            onChange={(e) => handleParamsChange({ title: e.target.value })}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                    </Stack>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>カテゴリ</TableCell>
                                <TableCell>タイトル</TableCell>
                                <TableCell>公開開始日</TableCell>
                                <TableCell>公開終了日</TableCell>
                                <TableCell>公開フラグ</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newsList.map((news: Schemas.NewsEntities) => (
                                <TableRow key={news.uuid}>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{NewsTypeFormat(news.newsType)}</TableCell>
                                    <TableCell>
                                        <Link href={`/news/${news.uuid}`} color={'primary'}>
                                            {news.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{datetimeFormat(news.startAt)}</TableCell>
                                    <TableCell>{datetimeFormat(news.endAt)}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{news.publish ? '公開' : '非公開'}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                        <IconButton onClick={() => editButtonHandler(news)} color={'primary'} size={'small'}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => deleteButtonHandler(news)}
                                            disabled={disabledButtonNews(news)}
                                            color={'error'}
                                            size={'small'}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(newsCount / params.limit) + 1}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
