import { useDisclosure } from '@chakra-ui/react'
import { Box, Button, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { type FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminSponsorCategoryCreateUpdateDialog } from '~/components/functional/sponsorCategory/CAdminSponsorCategoryCreateUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    searchWord?: string
}

type NavigateParameters = {
    page: string
    limit: string
    searchWord?: string
}

type MasterSponsorCategoryIndexPageViewProps = {}

const useMasterSponsorCategoryIndexPage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const query = useQueryString()

    const [categoryName, setCategoryName] = useState<string | undefined>(query.get('categoryName') || undefined)

    const params: Parameters = {
        page: Number(query.get('page') || 1),
        limit: Number(query.get('limit') || DefaultMaxListLimit),
        searchWord: categoryName,
    }
    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse'],
        async () => {
            const r = await apiClient.adminSponsorGetAllSponsorCategories({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.SponsorCategoryMasterEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const modalSubmitHandler = async (dto: Schemas.AdminCreateSponsorCategoryDto, uuid?: string) => {
        try {
            uuid
                ? await apiClient.adminSponsorUpdateCategory({ requestBody: dto, parameter: { uuid } })
                : await apiClient.adminSponsorCreateCategory({ requestBody: dto })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const [editTarget, setEditTarget] = useState<Schemas.SponsorCategoryMasterEntities>()

    const EditDialog = (
        <CAdminSponsorCategoryCreateUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={modalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (category: Schemas.SponsorCategoryMasterEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminSponsorDeleteCategory({
                parameter: {
                    uuid: category.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const handlePageChange = async (page: number) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        if (params.searchWord) navigateSearchParams.searchWord = params.searchWord

        navigate({
            pathname: '/master/sponsorCategory',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
        await refetchList()
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        EditDialog,
        editButtonHandler,
        addButtonHandler,
        deleteButtonHandler,
        categoryName,
        setCategoryName,
        handlePageChange,
    }
}

export const MasterSponsorCategoryIndexPageView: FC<MasterSponsorCategoryIndexPageViewProps> = () => {
    const {
        list,
        count,
        params,
        editButtonHandler,
        EditDialog,
        addButtonHandler,
        deleteButtonHandler,
        categoryName,
        setCategoryName,
        handlePageChange,
    } = useMasterSponsorCategoryIndexPage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: '事業者カテゴリマスタ', link: '/master/sponsorCategory' }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button sx={{ ml: 2 }} variant={'contained'} onClick={() => addButtonHandler()}>
                            カテゴリを追加
                        </Button>
                    </Box>
                    <Box>
                        <TextField
                            id={'categoryName'}
                            label={'カテゴリ名'}
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') await handlePageChange(1)
                            }}
                        />
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '50%' }}>カテゴリ名</TableCell>
                                <TableCell>ふりがな</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((category: Schemas.SponsorCategoryMasterEntities) => (
                                <TableRow key={category.uuid}>
                                    <TableCell>{category.title}</TableCell>
                                    <TableCell>{category.titleKana} </TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(category)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(category)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}

export const MasterSponsorCategoryIndexPage: FC = () => {
    return <MasterSponsorCategoryIndexPageView />
}

export default MasterSponsorCategoryIndexPage
