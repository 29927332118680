import { useDisclosure } from '@chakra-ui/react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Card, CardContent, IconButton, Pagination, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'

import { Schemas } from '~/apis/types'
import { CAdminMemoryCommentUpdateDialog } from '~/components/functional/memory/CAdminMemoryCommentUpdateDialog'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    memoryUuid: string
}

const useList = (memoryUuid: string) => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const [editTarget, setEditTarget] = useState<Schemas.MemoryCommentEntities>()
    const [params, setParams] = useState<Parameters>({ page: 1, limit: 10, memoryUuid: memoryUuid })

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params, memoryUuid],
        async () => {
            return await apiClient.adminMemoryCommentIndex({ parameter: params })
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = (page: number) => {
        setParams((prevState) => ({
            ...prevState,
            page: page,
        }))
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const editButtonHandler = (value: Schemas.MemoryCommentEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminMemoryCommentUpdateDto, commentUuid: string) => {
        try {
            await apiClient.adminMemoryCommentUpdate({
                requestBody: dto,
                parameter: { uuid: commentUuid },
            })

            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const deleteButtonHandler = async (commentUuid: string) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `返信を削除します`,
        })

        if (res) {
            try {
                await apiClient.adminMemoryCommentRemove({
                    parameter: {
                        uuid: commentUuid,
                    },
                })
                enqueueSnackbar(`削除しました`, { variant: 'success' })
                await refetchList()
            } catch (e) {
                let message = '削除に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            }
        }
    }

    const EditDialog = (
        <CAdminMemoryCommentUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        editButtonHandler,
        EditDialog,
        refetchList,
        deleteButtonHandler,
        handlePageChange,
    }
}

export type CAdminMemoryCommentListProps = {
    memoryUuid: string
}

export const CAdminMemoryCommentList: FC<CAdminMemoryCommentListProps> = ({ memoryUuid }) => {
    const { list, editButtonHandler, EditDialog, refetchList, deleteButtonHandler, count, params, handlePageChange } =
        useList(memoryUuid)
    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h6'}>コメント一覧</Typography>
                <Box>
                    <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Box>

            {list.map((comment: Schemas.MemoryCommentEntities, idx: number) => (
                <Card
                    key={comment.uuid}
                    sx={{
                        boxShadow: 'none',
                        borderRadius: 0,
                        borderTop: idx === 0 ? 'none' : '1px solid #e0e0e0',
                    }}>
                    <CardContent>
                        <Box color={'gray'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography component={'span'}>{datetimeFormat(comment.createdAt)}</Typography>

                                <Typography sx={{ ml: 2 }} component={'span'}>
                                    {comment.user.name}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={() => editButtonHandler(comment)} size={'small'}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color={'error'} onClick={() => deleteButtonHandler(comment.uuid)} size={'small'}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                            {comment.comment}
                        </Typography>
                    </CardContent>
                </Card>
            ))}

            {count === 0 ? (
                <Typography sx={{ mt: 2 }}>コメントはありません</Typography>
            ) : (
                <Pagination
                    count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                    page={params.page}
                    onChange={(_, value) => handlePageChange(value)}
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{ mt: 2 }}
                />
            )}

            {EditDialog}
        </>
    )
}
