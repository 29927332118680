import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { AdminGiftPaymentStatusUpdateDtoSchema, adminGiftPaymentStatusUpdateDtoSchema } from '~/types/zodScheme'
import { paymentStatusList } from '~/utils/common'

export const useCAdminGiftPaymentStatusUpdateDialog = (isOpen: boolean, entity?: Schemas.GiftEntities) => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid },
        setValue,
        reset,
    } = useForm<AdminGiftPaymentStatusUpdateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(adminGiftPaymentStatusUpdateDtoSchema),
    })

    // 初期化
    useEffect(() => {
        reset({
            paymentStatus: entity?.paymentStatus || 0,
        })
    }, [isOpen, entity])

    const onChangePaymentStatus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const paymentStatus = paymentStatusList.find((p) => p.value === Number(event.target.value))
        if (paymentStatus) setValue('paymentStatus', paymentStatus.value)
    }

    const paymentStatusFilterList = (paymentStatus: number | undefined) => {
        if (typeof paymentStatus === 'undefined') return paymentStatusList

        if (paymentStatus === 0) {
            return [
                { value: 0, label: '未決済' },
                { value: 1, label: '仮売上' },
                { value: 9, label: 'キャンセル' },
            ]
        }
        if (paymentStatus === 1) {
            return [
                { value: 1, label: '仮売上' },
                { value: 2, label: '本売上' },
                { value: 9, label: 'キャンセル' },
            ]
        }
        if (paymentStatus === 2) {
            return [
                { value: 2, label: '本売上' },
                { value: 9, label: 'キャンセル' },
            ]
        }
        if (paymentStatus === 9) return [{ value: 9, label: 'キャンセル' }]

        return paymentStatusList
    }

    return {
        register,
        handleSubmit,
        isSubmitting,
        isValid,
        paymentStatusFilterList,
        onChangePaymentStatus,
    }
}

export type CAdminGiftPaymentStatusUpdateDialogProps = {
    isOpen: boolean
    onClose: () => void
    onSubmit: (dto: Schemas.AdminGiftUpdateDto, uuid?: string) => void
    entity?: Schemas.GiftEntities
}

export const CAdminGiftPaymentStatusUpdateDialog: FC<CAdminGiftPaymentStatusUpdateDialogProps> = ({
    isOpen,
    onClose,
    onSubmit,
    entity,
}) => {
    const { register, handleSubmit, isSubmitting, isValid, paymentStatusFilterList, onChangePaymentStatus } =
        useCAdminGiftPaymentStatusUpdateDialog(isOpen, entity)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>決済状態を変更する</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            id={'paymentStatus'}
                            required={true}
                            {...register('paymentStatus')}
                            label={'決済状態'}
                            select={true}
                            onChange={(val) => {
                                onChangePaymentStatus(val)
                            }}
                            defaultValue={entity?.paymentStatus || 0}
                            helperText="キャンセルで保存すると変更出来なくなります。ご注意ください">
                            {paymentStatusFilterList(entity?.paymentStatus).map((nt) => (
                                <MenuItem key={nt.value} value={nt.value}>
                                    {nt.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>キャンセル</Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={isSubmitting}
                        disabled={!isValid}
                        onClick={handleSubmit((dto) => onSubmit(dto, entity?.uuid))}>
                        保存
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
