import { TextField } from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import dayjs from 'dayjs'
import { Control, Controller, Path } from 'react-hook-form'

export type CDatePickerProps<T> = {
    type?: 'date' | 'datetime'
    label: string
    name: Path<T>
    control: Control<T>
    error?: boolean
    helperText?: string
}

export const CDatePicker = <T,>({ type = 'datetime', label, name, control, error, helperText }: CDatePickerProps<T>) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                defaultValue={undefined}
                render={({ field }) => {
                    if (type === 'date') {
                        return (
                            <DatePicker
                                {...field}
                                inputFormat={'yyyy/MM/dd'}
                                label={label}
                                renderInput={(props) => <TextField {...props} error={error} helperText={helperText} />}
                                onChange={(val) => {
                                    let _val: string | null = null
                                    try {
                                        if (val !== null) {
                                            const __val = val as Date
                                            _val = dayjs(__val).toDate().toISOString()
                                        }
                                    } catch (e) {
                                        _val = null
                                    }
                                    field.onChange(_val)
                                }}
                            />
                        )
                    } else {
                        return (
                            <DateTimePicker
                                {...field}
                                inputFormat={'yyyy/MM/dd hh:mm:ss'}
                                label={label}
                                renderInput={(props) => <TextField {...props} error={error} helperText={helperText} />}
                                onChange={(val) => {
                                    let _val: string | null = null
                                    try {
                                        if (val !== null) {
                                            const __val = val as Date
                                            _val = dayjs(__val).toDate().toISOString()
                                        }
                                    } catch (e) {
                                        _val = null
                                    }
                                    field.onChange(_val)
                                }}
                            />
                        )
                    }
                }}
            />
        </LocalizationProvider>
    )
}
