import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import { SnackbarProvider } from 'notistack'
import React, { type ReactNode, StrictMode } from 'react'
import { HelmetProvider } from 'react-helmet-async'

import { muiTheme } from '~/utils/mui-theme'

const queryClient = new QueryClient()
export const PageShell = ({ children }: { children: ReactNode }) => {
    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <ThemeProvider theme={muiTheme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <JotaiProvider>
                                <SnackbarProvider maxSnack={5} autoHideDuration={6000}>
                                    {children}
                                </SnackbarProvider>
                            </JotaiProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </HelmetProvider>
            </QueryClientProvider>
        </StrictMode>
    )
}
