import { Box, Toolbar } from '@mui/material'
import React, { type FC, Component, ErrorInfo, ReactNode, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { CLoadingView } from '~/components/common/cLoadingView/CLoadingView'
import { GConfirmationDialog } from '~/components/global/gConfirmationDialog/GConfirmationDialog'
import { GDrawerMenu } from '~/components/global/GDrawerMenu'
import { GHeader } from '~/components/global/gHeader/GHeader'
import { AdminIndexPage } from '~/pages/admin'
import { AdminDetailPage } from '~/pages/admin/[adminId]'
import { ConnectIndexPage } from '~/pages/connect'
import { ConnectDetailPage } from '~/pages/connect/[connectId]'
import { ConnectTagIndexPage } from '~/pages/connectTag'
import { FamilyTreeIndexPage } from '~/pages/familyTree'
import { FamilyTreeDetailPage } from '~/pages/familyTree/[familyTreeId]'
import { GiftIndexPage } from '~/pages/gift'
import { GiftDetailPage } from '~/pages/gift/[giftId]'
import { GraveIndexPage } from '~/pages/grave'
import { GraveDetailPage } from '~/pages/grave/[graveId]'
import { GraveCommentIndexPage } from '~/pages/graveComment'
import { TopPage } from '~/pages/index'
import { InquiryPage } from '~/pages/inquiry'
import { InquiryDetailPage } from '~/pages/inquiry/[inquiryId]'
import LoginIndexPage from '~/pages/login'
import { MailTemplatePage } from '~/pages/mailTemplate'
import { MasterIndexPage } from '~/pages/master'
import { MasterGiftIndexPage } from '~/pages/master/gift'
import { MasterGiftDetailPage } from '~/pages/master/gift/[masterId]'
import { MasterImageIndexPage } from '~/pages/master/image'
import { MasterOfferingIndexPage } from '~/pages/master/offering'
import { MasterOfferingDetailPage } from '~/pages/master/offering/[masterId]'
import { MasterSponsorCategoryIndexPage } from '~/pages/master/sponsorCategory'
import { MemoryIndexPage } from '~/pages/memory'
import { MemoryDetailPage } from '~/pages/memory/[memoryId]'
import { MemoryAssetIndexPage } from '~/pages/memoryAsset'
import { MemoryCommentIndexPage } from '~/pages/memoryComment'
import { NewsPage } from '~/pages/news'
import { NewsDetailPage } from '~/pages/news/[newsId]'
import { NotFoundPage } from '~/pages/notFound'
import { OfferingOrderIndexPage } from '~/pages/offeringOrder'
import { OfferingOrderDetailPage } from '~/pages/offeringOrder/[orderId]'
import { ProfilePage } from '~/pages/profile'
import { ReminderIndexPage } from '~/pages/reminder'
import { ReminderDetailPage } from '~/pages/reminder/[token]'
import { UserPage } from '~/pages/user'
import { UserDetailPage } from '~/pages/user/[userId]'

import SponsorIndexPage from './sponsor'

export const PageRoutes: FC = () => {
    return (
        <BrowserRouter>
            <Box sx={{ display: 'flex' }}>
                <GHeader />
                <GDrawerMenu />
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Toolbar />
                    <ErrorBoundary>
                        <Suspense fallback={<CLoadingView />}>
                            <Routes>
                                <Route path={'/'} element={<TopPage />} />

                                <Route path={'login'} element={<LoginIndexPage />} />
                                <Route path="reminder" element={<ReminderIndexPage />} />
                                <Route path="reminder/:token" element={<ReminderDetailPage />} />
                                <Route path={'profile'} element={<ProfilePage />} />

                                <Route path="inquiry">
                                    <Route index element={<InquiryPage />} />
                                    <Route path=":inquiryId">
                                        <Route index element={<InquiryDetailPage />} />
                                    </Route>
                                </Route>

                                <Route path="news">
                                    <Route index element={<NewsPage />} />
                                    <Route path=":newsId">
                                        <Route index element={<NewsDetailPage />} />
                                    </Route>
                                </Route>

                                <Route path="sponsor">
                                    <Route index element={<SponsorIndexPage />} />
                                </Route>

                                <Route path="user">
                                    <Route index element={<UserPage />} />
                                    <Route path=":userId">
                                        <Route index element={<UserDetailPage />} />
                                    </Route>
                                </Route>

                                <Route path="connect">
                                    <Route index element={<ConnectIndexPage />} />
                                    <Route path=":connectId" element={<ConnectDetailPage />} />
                                </Route>

                                <Route path="connectTag">
                                    <Route index element={<ConnectTagIndexPage />} />
                                </Route>

                                <Route path="familyTree">
                                    <Route index element={<FamilyTreeIndexPage />} />
                                    <Route path=":familyTreeId" element={<FamilyTreeDetailPage />} />
                                </Route>

                                <Route path="grave">
                                    <Route index element={<GraveIndexPage />} />
                                    <Route path=":graveId" element={<GraveDetailPage />} />
                                </Route>
                                <Route path="graveComment">
                                    <Route index element={<GraveCommentIndexPage />} />
                                </Route>

                                <Route path="memory">
                                    <Route index element={<MemoryIndexPage />} />
                                    <Route path=":memoryId" element={<MemoryDetailPage />} />
                                </Route>
                                <Route path="memoryComment">
                                    <Route index element={<MemoryCommentIndexPage />} />
                                </Route>
                                <Route path="memoryAsset">
                                    <Route index element={<MemoryAssetIndexPage />} />
                                </Route>

                                <Route path="gift">
                                    <Route index element={<GiftIndexPage />} />
                                    <Route path=":giftId" element={<GiftDetailPage />} />
                                </Route>

                                <Route path="offeringOrder">
                                    <Route index element={<OfferingOrderIndexPage />} />
                                    <Route path=":orderId" element={<OfferingOrderDetailPage />} />
                                </Route>

                                <Route path="master">
                                    <Route index element={<MasterIndexPage />} />
                                    <Route path="gift">
                                        <Route index element={<MasterGiftIndexPage />} />
                                        <Route path=":masterId" element={<MasterGiftDetailPage />} />
                                    </Route>
                                    <Route path="offering">
                                        <Route index element={<MasterOfferingIndexPage />} />
                                        <Route path=":masterId" element={<MasterOfferingDetailPage />} />
                                    </Route>
                                    <Route path="image">
                                        <Route index element={<MasterImageIndexPage />} />
                                    </Route>
                                    <Route path="sponsorCategory">
                                        <Route index element={<MasterSponsorCategoryIndexPage />} />
                                    </Route>
                                </Route>

                                <Route path="mailTemplate" element={<MailTemplatePage />} />
                                <Route path="admin">
                                    <Route index element={<AdminIndexPage />} />
                                    <Route path=":adminId">
                                        <Route index element={<AdminDetailPage />} />
                                    </Route>
                                </Route>

                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </Suspense>
                    </ErrorBoundary>
                    <GConfirmationDialog />
                </Box>
            </Box>
        </BrowserRouter>
    )
}

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) {
            // TODO
            return <p>エラーが発生しました</p>
        }

        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children
    }
}
