import { Typography } from '@mui/material'
import type { FC } from 'react'

export const CRequiredText: FC = () => {
    return (
        <Typography
            data-testid={'c-required-text'}
            sx={{
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '130%',
                color: '#FF453A',
                my: 'auto',
                ml: '8px',
            }}>
            必須
        </Typography>
    )
}
