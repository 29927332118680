import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminGiftMasterUpdateDialog } from '~/components/functional/gift/CAdminGiftMasterUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { dateFormat, DefaultMaxListLimit, mediaUrl, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
    userUuid?: string
}
type NavigateParameters = {
    page: string
    limit: string
    userUuid?: string
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)
    const params: Parameters = { page: page, limit: limit }
    const [editTarget, setEditTarget] = useState<Schemas.GiftMasterEntities>()

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params],
        async () => {
            const r = await apiClient.adminGiftMasterIndex({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        params.page = page
        const navigateSearchParams: NavigateParameters = { page: params.page.toString(), limit: params.limit.toString() }
        navigate({
            pathname: '/master/gift',
            search: `?${createSearchParams(navigateSearchParams)}`,
        })
    }

    // 編集モーダルの表示状態
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        updateModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.GiftMasterEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminGiftMasterUpdateDto, uuid?: string) => {
        try {
            if (!uuid) await apiClient.adminGiftMasterStore({ requestBody: dto })
            else await apiClient.adminGiftMasterUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const EditDialog = (
        <CAdminGiftMasterUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget}
        />
    )

    const deleteButtonHandler = async (giftMaster: Schemas.GiftMasterEntities) => {
        const res = await queueDialog({
            type: 'confirm',
            title: '確認',
            text: `削除します`,
        })
        if (!res) return

        try {
            await apiClient.adminGiftMasterRemove({
                parameter: {
                    uuid: giftMaster.uuid,
                },
            })
            enqueueSnackbar(`削除しました`, { variant: 'success' })
            await refetchList()
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        handlePageChange,
        refetchList,
        addButtonHandler,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    }
}

export const MasterGiftIndexPage: FC = () => {
    const {
        list,
        count,
        params,
        handlePageChange,
        refetchList,
        addButtonHandler,
        editButtonHandler,
        deleteButtonHandler,
        EditDialog,
    } = usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `ギフト商品マスタ一覧`, link: `/master/gift` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                                ギフト商品を追加
                            </Button>
                        </Box>
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>画像</TableCell>
                                <TableCell>商品名</TableCell>
                                <TableCell>価格</TableCell>
                                <TableCell>開始日時</TableCell>
                                <TableCell>終了日時</TableCell>
                                <TableCell>公開</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((gift: Schemas.GiftMasterEntities) => (
                                <TableRow key={gift.uuid}>
                                    <TableCell>
                                        {gift.files.length > 0 && (
                                            <img
                                                src={mediaUrl(gift.files[0])}
                                                style={{ width: '100%', maxWidth: '64px', maxHeight: '64px' }}
                                                alt={gift.files[0].filename}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/master/gift/${gift.uuid}`} color={'primary'}>
                                            {gift.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{gift.price.toLocaleString()}円</TableCell>
                                    <TableCell>{dateFormat(gift.startAt)}</TableCell>
                                    <TableCell>{dateFormat(gift.endAt)}</TableCell>
                                    <TableCell>{gift.publish ? '公開する' : '非公開'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(gift)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                        <Button
                                            onClick={() => deleteButtonHandler(gift)}
                                            color={'error'}
                                            size={'small'}
                                            sx={{ ml: 1 }}>
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {EditDialog}
        </>
    )
}
