import { useDisclosure } from '@chakra-ui/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CAdminCreateDialog } from '~/components/functional/admin/CAdminCreateDialog'
import { CAdminUpdateDialog } from '~/components/functional/admin/CAdminUpdateDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { datetimeFormat, DefaultMaxListLimit, useQueryString, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

type Parameters = {
    page: number
    limit: number
}

const usePage = () => {
    const apiClient = createApiClient()
    const { enqueueSnackbar } = useSnackbar()
    const { queueDialog } = useConfirmationDialog()

    const query = useQueryString()
    const navigate = useNavigate()
    const page = Number(query.get('page') || 1)
    const limit = Number(query.get('limit') || DefaultMaxListLimit)
    const params: Parameters = { page: page, limit: limit }

    const [editTarget, setEditTarget] = useState<Schemas.AdminEntities>()

    // initial fetch
    const { data: listResponse, refetch: refetchList } = useQuerySuspense(
        ['listResponse', params],
        async () => {
            const r = await apiClient.adminAdminIndex({ parameter: params })
            return {
                list: r.list || [],
                count: r.count || 0,
            }
        },
        {
            onError: () => {
                enqueueSnackbar('リストの取得に失敗しました', { variant: 'error' })
            },
        },
    )

    const handlePageChange = async (page: number) => {
        params.page = page
        navigate({
            pathname: '/admin',
            search: `?${createSearchParams({
                page: params.page.toString(),
            })}`,
        })
    }

    // 編集モーダルの表示状態
    const { isOpen: createModalIsOpen, onOpen: createModalOnOpen, onClose: createModalOnClose } = useDisclosure()
    const { isOpen: updateModalIsOpen, onOpen: updateModalOnOpen, onClose: updateModalOnClose } = useDisclosure()

    const addButtonHandler = () => {
        setEditTarget(undefined)
        createModalOnOpen()
    }

    const editButtonHandler = (value: Schemas.AdminEntities) => {
        setEditTarget(value)
        updateModalOnOpen()
    }

    const createModalSubmitHandler = async (dto: Schemas.AdminAdminCreateDto) => {
        try {
            await apiClient.adminAdminStore({ requestBody: dto as Schemas.AdminAdminCreateDto })
            await refetchList()
            createModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const updateModalSubmitHandler = async (dto: Schemas.AdminAdminUpdateDto, uuid: string) => {
        try {
            await apiClient.adminAdminUpdate({ requestBody: dto, parameter: { uuid } })
            await refetchList()
            updateModalOnClose()
        } catch (e) {
            let message = '更新に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const AddDialog = (
        <CAdminCreateDialog isOpen={createModalIsOpen} onClose={createModalOnClose} onSubmit={createModalSubmitHandler} />
    )

    const EditDialog = (
        <CAdminUpdateDialog
            isOpen={updateModalIsOpen}
            onClose={updateModalOnClose}
            onSubmit={updateModalSubmitHandler}
            entity={editTarget!}
        />
    )

    return {
        list: listResponse?.list || [],
        count: listResponse?.count || 0,
        params,
        addButtonHandler,
        editButtonHandler,
        AddDialog,
        EditDialog,
        handlePageChange,
        refetchList,
    }
}

export const AdminIndexPage: FC = () => {
    const { list, count, params, addButtonHandler, editButtonHandler, AddDialog, EditDialog, handlePageChange, refetchList } =
        usePage()
    return (
        <>
            <DefaultLayout breadcrumbList={[{ title: `管理者一覧`, link: `/admin` }]}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Box>
                            <IconButton color="primary" onClick={() => refetchList()} size={'small'}>
                                <RefreshIcon />
                            </IconButton>
                            <Button sx={{ ml: 2 }} onClick={addButtonHandler} variant={'contained'}>
                                管理者を追加
                            </Button>
                        </Box>
                    </Box>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>メールアドレス</TableCell>
                                <TableCell>システム管理者</TableCell>
                                <TableCell>登録日</TableCell>
                                <TableCell>更新日</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((admin: Schemas.AdminEntities) => (
                                <TableRow key={admin.uuid}>
                                    <TableCell>
                                        <Link href={`/admin/${admin.uuid}`} color={'primary'}>
                                            {admin.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{admin.email}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{admin.systemAdmin && '○'}</TableCell>
                                    <TableCell>{datetimeFormat(admin.createdAt)}</TableCell>
                                    <TableCell>{datetimeFormat(admin.updatedAt)}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => editButtonHandler(admin)} color={'primary'} size={'small'}>
                                            編集
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.floor(count / params.limit) + (count % params.limit === 0 ? 0 : 1)}
                        page={params.page}
                        onChange={(_, value) => handlePageChange(value)}
                        showFirstButton={true}
                        showLastButton={true}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DefaultLayout>

            {AddDialog}
            {EditDialog}
        </>
    )
}
